import React, { useEffect, useState } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import showroom from "../../../assets/img/layout/showroom.png";
import axios from "axios";
import toast from "react-hot-toast";
import Branchdelete from "./Branchdelete";
import Editbranch from "./Editbranch";
import bike from "../../../assets/img/bike.png";
import { IoSearchOutline } from "react-icons/io5";
import BikeDetailsPage from "../BikeDetailsPage";
import Am from "../../../assets/svg/AM.svg";
import { IoFilterOutline } from "react-icons/io5";

const Branchdetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [branchData, setBranchData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const [editModalopen, setEditmodalopen] = useState(false);
  const [buttonloader, setbuttonloader] = useState(false);
  const [bikeData, setBikeData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [bikeDataLoader, setBikeDataLoader] = useState(false);
  const [bikeDetailsPage, setBikeDetailsPage] = useState(false);
  const [filterBYFilter, setFilterByFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(filterData.length / itemsPerPage);

  const currentItems = filterData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const selectedBranchDetails = () => {
    setloader(true);
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: ` https://archita-motors.onrender.com/archita-motors-api/branch/get-branche-by-id/${id}`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setBranchData(response?.data);
        setloader(false);
      })
      .catch((error) => {
        console.log(error);
        setloader(false);
      });
  };

  const handelDelete = () => {
    let data = "";
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://archita-motors.onrender.com/archita-motors-api/branch/delete-branch/${id}`,
      headers: {},
      data: data,
    };
    setbuttonloader(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast.success("Branch delete succefully");
        navigate("/admin/branches");
        setbuttonloader(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Some error occure try again!");
        setbuttonloader(false);
      });

    setIsModalOpen(false);
  };

  const getBikeByBranch = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://archita-motors.onrender.com/archita-motors-api/bikes/get-bikes-by-branch/${id}`,
      headers: {},
      data: data,
    };
    setBikeDataLoader(true);
    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setBikeData(response?.data);
        setBikeDataLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setBikeDataLoader(false);
      });
  };

  useEffect(() => {
    selectedBranchDetails();
    getBikeByBranch();
  }, []);
  useEffect(() => {
    const filtered = bikeData.filter((bike) => {
      const matchesSearchQuery = bike?.primaryDetails?.makeAndModel
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      const matchesFilter =
        filterBYFilter === "all" ||
        bike?.primaryDetails?.bikeStatus === filterBYFilter;

      return matchesSearchQuery && matchesFilter;
    });

    setFilteredData(filtered);
  }, [searchQuery, bikeData, filterBYFilter]);

  return (
    <React.Fragment>
      {isModalOpen && (
        <Branchdelete
          onClose={() => setIsModalOpen(false)}
          onDelete={handelDelete}
        />
      )}

      {editModalopen && (
        <Editbranch
          closeModal={() => setEditmodalopen(false)}
          id={id}
          buttonloader={buttonloader}
          getBikeByBranch={selectedBranchDetails}
        />
      )}

      <div className="mt-6 flex items-center">
        <MdOutlineArrowBackIos
          onClick={() => navigate(-1)}
          className="cursor-pointer"
          size={22}
          color="#070619"
        />
        <div className="ml-2 text-xl font-extrabold tracking-wide text-[#640708] md:text-2xl">
          Branch Details
        </div>
      </div>
      {loader ? (
        <div className="flex flex-col items-start gap-4 md:flex-row lg:gap-12">
          <div className="mt-1 flex-1 md:mt-10">
            <div className="">
              <div className="flex items-center gap-4">
                <div className="h-8 w-1/3 animate-pulse rounded bg-gray-200"></div>
              </div>
              <div className="mt-4 flex items-center gap-4 md:mt-8">
                <div className="h-6 w-16 animate-pulse rounded bg-gray-200"></div>
                <div className="h-6 w-1/2 animate-pulse rounded bg-gray-200"></div>
              </div>
              <div className="mt-4 flex items-center gap-4 md:mt-5">
                <div className="h-6 w-16 animate-pulse rounded bg-gray-200"></div>
                <div className="h-6 w-1/2 animate-pulse rounded bg-gray-200"></div>
              </div>
              <div className="mt-4 flex items-center gap-4 md:mt-5">
                <div className="h-6 w-16 animate-pulse rounded bg-gray-200"></div>
                <div className="h-6 w-1/2 animate-pulse rounded bg-gray-200"></div>
              </div>
              <div className="mt-4 flex items-center gap-4 md:mt-5">
                <div className="h-6 w-16 animate-pulse rounded bg-gray-200"></div>
                <div className="h-6 w-1/2 animate-pulse rounded bg-gray-200"></div>
              </div>
              <div className="mt-4 flex items-start gap-4 md:mt-5">
                <div className="h-6 w-16 animate-pulse rounded bg-gray-200"></div>
                <div className="h-6 w-full animate-pulse rounded bg-gray-200"></div>
              </div>
            </div>
          </div>
          <div className="flex-none">
            <div className="h-72 w-[25rem] animate-pulse bg-gray-200 lg:h-[25rem]"></div>
          </div>
        </div>
      ) : (
        <div className="mt-4 flex flex-col items-center gap-4 rounded-lg bg-white p-4 shadow-lg md:flex-row lg:gap-6">
          <div className="mt-1 flex-1 md:mx-4 md:my-2">
            <div className="">
              <div className="flex items-center gap-4">
                <span className="text-[1.45rem] font-semibold capitalize tracking-wide text-[black] md:text-2xl">
                  {branchData.name}
                </span>
              </div>
              <div className="mt-2 flex items-center gap-4 md:mt-2">
                <label className="flex-none text-base font-semibold text-gray-800 md:text-lg lg:text-[1rem]">
                  Phone:
                </label>
                <span className="text-base capitalize text-gray-700 md:text-lg lg:text-[.99rem]">
                  {branchData.phone}
                </span>
              </div>
              <div className="mt-2 flex items-center gap-4 ">
                <label className="flex-none text-base font-semibold text-gray-800 md:text-lg lg:text-[1rem]">
                  City:
                </label>
                <span className="text-base capitalize text-gray-700 md:text-lg lg:text-[.99rem]">
                  {branchData.city}
                </span>
              </div>
              <div className="mt-2 flex items-center gap-4 ">
                <label className="flex-none text-base font-semibold text-gray-800 md:text-lg lg:text-[1rem]">
                  State:
                </label>
                <span className="text-base capitalize text-gray-700 md:text-lg lg:text-[.99rem]">
                  {branchData.state}
                </span>
              </div>
              <div className="mt-2 flex items-center gap-4 ">
                <label className="flex-none text-base font-semibold text-gray-800 md:text-lg lg:text-[1rem]">
                  PIN:
                </label>
                <span className="text-base capitalize text-gray-700 md:text-lg lg:text-[.99rem]">
                  {branchData.pin}
                </span>
              </div>
              <div className="mt-2 flex items-center gap-4 ">
                <label className="flex-none text-base font-semibold text-gray-800 md:text-lg lg:text-[1rem]">
                  Email Id:
                </label>
                <span className="text-base text-gray-700 md:text-lg lg:text-[.99rem]">
                  {branchData.email}
                </span>
              </div>
              <div className="mt-2 flex items-start gap-4 ">
                <label className="flex-none text-base font-semibold text-gray-800 md:text-lg lg:text-[1rem]">
                  Full Address:
                </label>
                <span className="flex-1 text-base capitalize text-gray-700 md:text-lg lg:text-[.99rem]">
                  {branchData.fullAddress}
                </span>
              </div>
            </div>
            <div className="mt-3 flex gap-6">
              <button
                onClick={() => setEditmodalopen(true)}
                className="rounded-md border-none bg-white px-8 py-2 font-semibold text-[#640708] duration-200 ease-in hover:border-2 hover:border-white hover:bg-[#640708] hover:text-white"
              >
                Edit
              </button>
              <button
                onClick={() => setIsModalOpen(true)}
                className="rounded-md border-none bg-[#640708] px-6 py-2 font-semibold text-white duration-200 ease-in hover:border-2 hover:border-[#640708] hover:bg-white hover:text-[#640708]"
              >
                Delete
              </button>
            </div>
          </div>
          <div className="flex-none">
            <img src={Am} alt="img" className="h-72 w-auto object-contain" />
          </div>
        </div>
      )}

      <div className="justify-between md:flex">
        <div className="mt-4 text-[1.45rem] font-semibold capitalize tracking-wide text-[black] md:my-4 md:text-[1.7rem]">
          Bikes Available in this Branch
        </div>
        <div className="flex w-full items-center gap-2 md:w-[40%]">
          <div className="w-[80%]">
            <div className="relative my-4 w-full rounded-lg bg-white md:w-full">
              <input
                type="search"
                placeholder="Search..."
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                className="w-full rounded-lg border border-gray-300 py-2 pl-10 pr-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <IoSearchOutline
                size={20}
                className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-500"
                aria-hidden="true"
              />
            </div>
          </div>
          <div className="relative my-4 w-[30%]  md:my-0 ">
            <select
              onChange={(e) => setFilterByFilter(e.target.value)}
              className="w-full appearance-none rounded-lg border border-gray-300 py-2 pl-10 pr-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="all">All</option>
              <option value="Sold">Sold</option>
              <option value="InShowroom">In Showroom</option>
            </select>
            <IoFilterOutline
              size={20}
              className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-500"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start gap-4 md:flex-row lg:gap-12">
        <div
          id="bikelist"
          className="container mx-auto overflow-x-auto rounded-lg shadow-lg"
        >
          <div className="inline-block min-w-full rounded-lg">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  {[
                    "Model Name",
                    "Year",
                    "Color",
                    "Fuel",
                    "Emission",
                    "Price",
                    "KMs",
                    "Status",
                  ].map((header) => (
                    <th
                      key={header}
                      className="border-b-2 border-[#640708] bg-[#640708] px-5 py-3 text-left text-xs font-bold uppercase tracking-wider text-white"
                      style={{
                        minWidth: header === "Model Name" ? "190px" : "auto",
                      }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {!bikeDataLoader ? (
                  currentItems.length > 0 ? (
                    currentItems.map((item) => (
                      <tr key={item.id} className="hover:bg-gray-100">
                        <td className="flex items-center border-b border-gray-200 bg-white py-5 text-sm">
                          <img
                            src={item?.primaryDetails?.bikeImages[0]}
                            alt="No-Img"
                            onClick={() =>
                              navigate(
                                `/admin/purchased-bikes-from-owners/bike-details/${item._id}`
                              )
                            }
                            className="m-2 h-12 w-12 rounded-full border-[1px] border-gray-300"
                          />
                          <div>
                            <p className="text-md font-semibold text-gray-900">
                              {item?.primaryDetails?.makeAndModel}
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              {item?.primaryDetails?.registrationNumber}
                            </p>
                          </div>
                        </td>
                        <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                          <p className="text-gray-900">
                            {item?.primaryDetails?.yearOfManufacture}
                          </p>
                        </td>
                        <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                          <p className="capitalize text-gray-900">
                            {item?.primaryDetails?.color}
                          </p>
                        </td>
                        <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                          <p className="capitalize text-gray-900">
                            {item?.primaryDetails?.fuelType}
                          </p>
                        </td>
                        <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                          <p className="text-gray-900">
                            {item.primaryDetails?.emissionType}
                          </p>
                        </td>
                        <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                          <p className="capitalize text-gray-900">
                            ₹{item?.primaryDetails?.secondSellingPrice}
                          </p>
                        </td>
                        <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                          <p className="capitalize text-gray-900">
                            {item?.primaryDetails?.odometerReading} kms
                          </p>
                        </td>

                        <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                          <p
                            className={`rounded-md  px-2 py-1 text-center font-semibold capitalize text-gray-900 ${
                              item?.primaryDetails?.bikeStatus === "Sold" &&
                              "bg-[#fddb42]"
                            }  ${
                              item?.primaryDetails?.bikeStatus ===
                                "InShowroom" && "bg-[#b2f732] "
                            }`}
                          >
                            {item?.primaryDetails?.bikeStatus}
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="py-5 text-center">
                        No bikes available at this branch.
                      </td>
                    </tr>
                  )
                ) : (
                  Array.from({ length: 5 }).map((_, index) => (
                    <tr key={index} className="animate-pulse">
                      <td className="w-1/12 whitespace-nowrap px-6 py-4">
                        <div className="flex items-center space-x-3">
                          <div className="h-12 w-12 rounded-full bg-gray-300" />
                          <div className="flex-1 space-y-4">
                            <div className="h-4 w-3/4 rounded bg-gray-300" />
                            <div className="h-4 w-3/4 rounded bg-gray-300" />
                          </div>
                        </div>
                      </td>
                      {Array.from({ length: 7 }).map((_, index) => (
                        <td
                          key={index}
                          className="w-1/12 whitespace-nowrap px-6 py-4"
                        >
                          <div className="h-6 w-3/4 rounded bg-gray-300" />
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="my-6 flex justify-center md:justify-end">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`mx-1 rounded px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-200 ${
                currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              Previous
            </button>

            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`mx-1 rounded-md border px-3 py-2 text-sm ${
                  currentPage === index + 1
                    ? "bg-[#640708] text-white"
                    : "text-gray-800 hover:bg-gray-200"
                }`}
              >
                {index + 1}
              </button>
            ))}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`mx-1 rounded px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-200 ${
                currentPage === totalPages
                  ? "cursor-not-allowed opacity-50"
                  : ""
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Branchdetails;
