import React, { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS for date picker
import SoldBikeInvoice from "Components/Doc/SoldBikeInvoice";
import { IoMdDownload } from "react-icons/io";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import toast from "react-hot-toast";
const Ledger = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterBYFilter, setFilterByFilter] = useState("all");
  const [branchData, setBranchData] = useState([]);
  const [startDate, setStartDate] = useState(null); // New state for start date
  const [endDate, setEndDate] = useState(null); // New state for end date
  const [soldBikeInvoiceModal, setSoldBikeInvoiceModal] = useState(false);
  const [currentBikeData, setCurrentBikeData] = useState({});
  const [loadSoldBike, setLoadSoldBike] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Profit");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDay, setSelectedDay] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState("");

  const navigate = useNavigate();

  const calculateProfit = (purchasedPrice, maintenanceFee, soldPrice) => {
    if (activeTab === "Profit") {
      return soldPrice - (purchasedPrice + maintenanceFee);
    } else {
      return purchasedPrice + maintenanceFee - soldPrice;
    }
  };

  // Function to filter data by month and profit
  const filterProfitBikeData = () => {
    return data.filter((bike) => {
      const soldDate = new Date(bike.primaryDetails.soldDate);
      const isYearMatch = soldDate.getFullYear() === selectedYear;
      const isMonthMatch = soldDate.getMonth() + 1 === selectedMonth;
      const isBranchMatch = selectedBranch
        ? bike.branch === selectedBranch
        : true; // Check for branch selection

      // For "Monthly" tab, only filter by year, month, and branch if selected
      if (activeTab === "Monthly") {
        return isYearMatch && isMonthMatch && isBranchMatch;
      }
      // For "Profit" tab, filter by year, month, and ensure profit is positive and branch if selected
      else if (activeTab === "Profit") {
        const profit = calculateProfit(
          bike.primaryDetails.purchasedPrice,
          bike.primaryDetails.maintainanceFee,
          bike.primaryDetails.soldPrice
        );
        return isYearMatch && isMonthMatch && isBranchMatch && profit > 0;
      } else {
        const profit = calculateProfit(
          bike.primaryDetails.purchasedPrice,
          bike.primaryDetails.maintainanceFee,
          bike.primaryDetails.soldPrice
        );
        return isYearMatch && isMonthMatch && isBranchMatch && profit > 0; // Assuming loss condition
      }
    });
  };
  const filteredProfitData = filterProfitBikeData();
  console.log("I am prfit sold data for this month----->", filteredProfitData);

  //Total monthly sales....
  let totalBikesSold = 0;
  let totalPurchasePrice = 0;
  let totalMaintenanceFee = 0;
  let totalSoldPrice = 0;

  filteredProfitData?.forEach((item) => {
    const details = item.primaryDetails;

    if (details.bikeStatus === "Sold") {
      totalBikesSold++;
      totalPurchasePrice += details.purchasedPrice;
      totalMaintenanceFee += details.maintainanceFee;
      totalSoldPrice += details.soldPrice;
    }
  });

  const totalProfit =
    totalSoldPrice - (totalPurchasePrice + totalMaintenanceFee);

  // .........

  const getBikeData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://archita-motors.onrender.com/archita-motors-api/bikes/get-all-bikes"
      );
      const showroomBikes = response.data.filter(
        (bike) => bike.primaryDetails.bikeStatus === "Sold"
      );
      setData(showroomBikes);
    } catch (error) {
      console.error("Error fetching bike data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const GetBranch = () => {
    axios
      .get(
        "https://archita-motors.onrender.com/archita-motors-api/branch/all-branches"
      )
      .then((response) => {
        setBranchData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching branch data:", error);
      });
  };

  useEffect(() => {
    getBikeData();
    GetBranch();
  }, []);

  useEffect(() => {
    const filtered = data.filter((bike) => {
      const matchesSearchQuery = bike?.primaryDetails?.makeAndModel
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      const matchesFilter =
        filterBYFilter === "all" || bike?.branch === filterBYFilter;

      const soldDate = new Date(bike?.primaryDetails?.soldDate);
      const matchesDateRange =
        (!startDate || soldDate >= startDate) &&
        (!endDate || soldDate <= endDate);

      return matchesSearchQuery && matchesFilter && matchesDateRange;
    });

    setFilteredData(filtered);
  }, [searchQuery, data, filterBYFilter, startDate, endDate]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentItems = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const deleteData = async (id) => {
    if (window.confirm("Are you sure you want to delete this bike?")) {
      try {
        await axios.delete(
          `https://archita-motors.onrender.com/archita-motors-api/bikes/delete-bike/${id}`
        );
        getBikeData();
      } catch (error) {
        console.error("Error deleting bike:", error);
      }
    }
  };

  //Handle PDF generation....
  const handleGeneratePDF = async () => {
    console.log("i am running");
    toast("Downloading Please wait...", { duration: 4000 });

    // Select the invoice element
    const invoiceElement = document.getElementById("invoice");

    // Temporarily remove scroll and size limitations to capture all content
    invoiceElement.style.maxHeight = "none";
    invoiceElement.style.overflow = "visible";

    const canvas = await html2canvas(invoiceElement, {
      scale: 2, // Higher scale for better resolution
      useCORS: true,
      allowTaint: true,
      scrollX: 0,
      scrollY: -window.scrollY, // To capture full content
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4", // A4 size for invoices
    });

    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // If the content exceeds one page, add page breaks
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pdf.internal.pageSize.getHeight();

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();
    }

    pdf.save("monthly-sales-invoice.pdf");

    // Restore original styles
    invoiceElement.style.maxHeight = "30rem";
    invoiceElement.style.overflow = "auto";
  };

  return (
    <div className="">
      {loadSoldBike && (
        <SoldBikeInvoice
          currentSoldBike={currentBikeData}
          loadSoldBike={setLoadSoldBike}
          setLoadSoldBike={setLoadSoldBike}
        />
      )}

      <div className="my-4 justify-between lg:flex">
        <div className="text-[1.45rem] font-semibold capitalize tracking-wide tracking-wide text-[black] md:text-[1.7rem] ">
          Ledger
        </div>
      </div>
      <div className="mb-3 flex flex-col items-center justify-between gap-4 lg:flex-row">
        {/* Date Pickers */}
        <div className="flex flex-col gap-2 sm:flex-row">
          <div className="flex items-center">
            <label className="mr-2">From:</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy/MM/dd"
              placeholderText="dd/mm/yyyy"
              className="w-full rounded-lg border border-gray-300 p-2 sm:w-auto"
            />
          </div>
          <div className="flex items-center">
            <label className="mr-2">To:</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy/MM/dd"
              placeholderText="dd/mm/yyyy"
              className="w-full rounded-lg border border-gray-300 p-2 sm:w-auto"
            />
          </div>
        </div>

        {/* Search Bar and Dropdown */}
        <div className="flex w-full  flex-row items-center gap-4 lg:w-auto">
          {/* Search Input */}
          <div className="w-full sm:w-auto lg:w-[60%]">
            <div className="relative my-2 w-full rounded-lg bg-white sm:my-0">
              <input
                type="search"
                placeholder="Search..."
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                className="w-full rounded-lg border border-gray-300 py-2 pl-10 pr-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <IoSearchOutline
                size={20}
                className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-500"
                aria-hidden="true"
              />
            </div>
          </div>

          {/* Dropdown Menu */}
          <div className="relative w-full sm:w-auto lg:w-[40%]">
            <select
              onChange={(e) => {
                setFilterByFilter(e.target.value);
                setIsDropdownOpen(false);
              }}
              onFocus={() => setIsDropdownOpen(!isDropdownOpen)}
              className="w-full appearance-none rounded-lg border border-gray-300 py-2 pr-10 text-center text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option
                value="all"
                className="py-2 text-center text-[1rem] text-gray-600"
              >
                All
              </option>
              {branchData?.map((e, index) => (
                <option
                  key={index}
                  value={e._id}
                  className="ml-8 cursor-pointer text-center text-gray-700 hover:bg-gray-200"
                >
                  {e.name}
                </option>
              ))}
            </select>
            {/* Dropdown Arrow */}
            <span
              className={`pointer-events-none absolute right-3 top-1/2 -translate-y-1/2 transform text-sm transition-transform duration-200 ${
                isDropdownOpen ? "rotate-180" : ""
              }`}
            >
              ▼
            </span>
          </div>
        </div>
      </div>

      <div
        id="bikelist"
        className="container mx-auto overflow-x-auto rounded-lg shadow-lg"
      >
        <div className="inline-block min-w-full rounded-lg">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                {[
                  "Model Name",
                  "Purchases Price",
                  "Maintenance Fee",
                  "Sold Price",
                  "Profit/Loss",
                ].map((header) => (
                  <th
                    key={header}
                    className="border-b-2 border-[#640708] bg-[#640708] px-5 py-3 text-left text-xs font-bold uppercase tracking-wider text-white"
                    style={{
                      minWidth: header === "Model Name" ? "180px" : "auto",
                    }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            {!isLoading ? (
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((item) => {
                    const profitOrLoss =
                      item.primaryDetails.soldPrice -
                      (item.primaryDetails.purchasedPrice +
                        item.primaryDetails.maintainanceFee);

                    return (
                      <tr key={item._id} className="hover:bg-gray-100">
                        <td className="flex items-center border-b border-gray-200 bg-white py-5 text-sm">
                          <img
                            src={item.primaryDetails.bikeImages[0]}
                            onClick={() =>
                              navigate(
                                `/admin/purchased-bikes-from-owners/bike-details/${item._id}`
                              )
                            }
                            alt="No-Img"
                            className="m-2 h-12 w-12 cursor-pointer rounded-full border-[1px] border-gray-300"
                          />
                          <div>
                            <p className="text-md font-semibold text-gray-900">
                              {item.primaryDetails.makeAndModel}
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              {item.primaryDetails.registrationNumber}
                            </p>
                          </div>
                        </td>
                        <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                          <p className="text-gray-900">
                            {item.primaryDetails.purchasedPrice}
                          </p>
                        </td>
                        <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                          <p className="text-gray-900">
                            {item.primaryDetails.maintainanceFee}
                          </p>
                        </td>
                        <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                          <p className="text-gray-900">
                            {item?.primaryDetails?.soldPrice}
                          </p>
                        </td>
                        <td
                          className={`border-b border-gray-200 bg-white px-5 py-5 text-sm`}
                        >
                          <p
                            className={`flex flex-col items-center justify-center gap-1 rounded-full px-2 py-1 text-center font-semibold tracking-wide `}
                          >
                            <span className="">Rs.{profitOrLoss}</span>
                            <span
                              className={`w-fit rounded-md px-3 py-1 ${
                                profitOrLoss > 0
                                  ? "bg-green-100 text-green-700"
                                  : "bg-red-100 text-red-700"
                              }`}
                            >
                              {profitOrLoss > 0 ? "Profit" : "Loss"}
                            </span>
                          </p>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="border-b border-gray-200 bg-white px-5 py-5 text-center text-sm text-gray-900"
                    >
                      No results found
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              Array.from({ length: 5 }).map((_, index) => (
                <tr key={index} className="animate-pulse">
                  <td className="w-1/12 whitespace-nowrap px-6 py-4">
                    <div className="flex items-center space-x-3">
                      <div className="h-12 w-12 rounded-full bg-gray-300" />
                      <div className="flex-1 space-y-4">
                        <div className="h-4 w-3/4 rounded bg-gray-300" />
                        <div className="h-4 w-3/4 rounded bg-gray-300" />
                      </div>
                    </div>
                  </td>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <td
                      key={index}
                      className="w-1/12 whitespace-nowrap px-6 py-4"
                    >
                      <div className="h-6 w-3/4 rounded bg-gray-300" />
                    </td>
                  ))}
                </tr>
              ))
            )}
          </table>
        </div>
        <div className="mb-2 mt-6 flex justify-center md:justify-end">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`mx-1 rounded px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-200 ${
              currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            Previous
          </button>

          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`mx-1 rounded-md border px-3 py-2 text-sm ${
                currentPage === index + 1
                  ? "bg-[#640708] text-white"
                  : "text-gray-800 hover:bg-gray-200"
              }`}
            >
              {index + 1}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`mx-1 rounded px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-200 ${
              currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            Next
          </button>
        </div>
      </div>

      <div className="mt-10">
        <h2 className={`text-[1.45rem]  font-bold tracking-wide text-[black]`}>
          Bike Sales Details
        </h2>
        <div className=" my-4 flex items-center justify-center gap-2 text-[1.2rem] font-medium tracking-wide">
          <button
            onClick={() => {
              setActiveTab("Profit");
            }}
            className={`relative h-[3rem] w-fit rounded-md border px-2 text-[0.9rem] lg:text-[1.2rem] ${
              activeTab === "Profit"
                ? "border-green-400 bg-green-600 text-white after:absolute after:bottom-[-8px] after:left-0 after:right-0 after:h-[3px] after:bg-green-600"
                : "border-green-400 bg-green-100 text-green-600"
            }`}
          >
            Profit Sales
          </button>

          <button
            onClick={() => {
              setActiveTab("Loss");
            }}
            className={`relative h-[3rem] w-fit rounded-md border px-2 text-[0.9rem] lg:text-[1.2rem]  ${
              activeTab === "Loss"
                ? "border-red-400 bg-red-600 text-white after:absolute after:bottom-[-8px] after:left-0 after:right-0 after:h-[3px] after:bg-red-600"
                : "border-red-400 bg-red-100 text-red-600"
            }`}
          >
            Loss Sales
          </button>
          <button
            onClick={() => {
              setActiveTab("Monthly");
            }}
            className={`relative h-[3rem] w-fit rounded-md border px-2 text-[0.9rem] lg:text-[1.2rem] ${
              activeTab === "Monthly"
                ? "border-blue-400 bg-blue-600 text-white after:absolute after:bottom-[-8px] after:left-0 after:right-0 after:h-[3px] after:bg-blue-600"
                : "border-blue-400 bg-blue-100 text-blue-600"
            }`}
          >
            Monthly Sales
          </button>
        </div>

        <div className="flex items-center justify-between">
          {/* <div>
           
            <label
              htmlFor="month-select "
              className="font-semibold tracking-wide"
            >
              Select Month :{" "}
            </label>
            <select
              id="month-select"
              className="rounded-lg px-3 py-2 font-medium tracking-wide"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(Number(e.target.value))}
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i} value={i + 1}>
                  {new Date(0, i).toLocaleString("default", { month: "long" })}
                </option>
              ))}
            </select>
          </div> */}
          <div>
            {/* Branch Selector */}
            <label
              htmlFor="branch-select"
              className="font-semibold tracking-wide"
            >
              Select Branch:{" "}
            </label>
            <select
              id="branch-select"
              className="rounded-lg px-3 py-2 font-medium tracking-wide"
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
            >
              <option value="">All Branches</option>
              {branchData?.map((branch) => (
                <option key={branch._id} value={branch._id}>
                  {branch.name}
                </option>
              ))}
            </select>

            {/* Year Selector */}
            <label
              htmlFor="year-select"
              className="font-semibold tracking-wide"
            >
              Select Year:{" "}
            </label>
            <select
              id="year-select"
              className="rounded-lg px-3 py-2 font-medium tracking-wide"
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(Number(e.target.value));
              }}
            >
              {Array.from({ length: 10 }, (_, i) => {
                const year = new Date().getFullYear() - i;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>

            {/* Month Selector */}
            <label
              htmlFor="month-select"
              className="ml-4 font-semibold tracking-wide"
            >
              Select Month:{" "}
            </label>
            <select
              id="month-select"
              className="rounded-lg px-3 py-2 font-medium tracking-wide"
              value={selectedMonth}
              onChange={(e) => {
                const month = Number(e.target.value);
                setSelectedMonth(month);
                setSelectedDay(1); // Reset day when month changes
              }}
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i} value={i + 1}>
                  {new Date(0, i).toLocaleString("default", { month: "long" })}
                </option>
              ))}
            </select>
          </div>
          <button
            onClick={() => {
              handleGeneratePDF();
            }}
            className="clear-right my-3 flex gap-2 rounded-md bg-red-900 p-2 text-sm tracking-wide text-white "
          >
            <IoMdDownload size={20} />
            Download
          </button>
        </div>

        {activeTab == "Monthly" ? (
          <div
            id="invoice"
            className="mx-auto mt-3 w-full rounded-lg bg-white px-6 py-2 shadow-md"
          >
            <h1 id="Month" className="my-3 font-medium tracking-wide">
              Sales for the month :{" "}
              {[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ].filter((each, index) => {
                return selectedMonth == index + 1;
              })}
              {selectedYear}
            </h1>
            <h2 className="mb-6 text-center text-3xl font-semibold text-green-600">
              Total Sales Summary
            </h2>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              {/* Total Bikes Sold */}
              <div className="rounded-md border-t-4 border-green-400 bg-green-100 p-4 shadow-sm">
                <h3 className="text-2xl font-bold text-green-600">
                  Bikes Sold
                </h3>
                <p className="text-3xl font-semibold tracking-wide text-gray-700">
                  {totalBikesSold}
                </p>
              </div>

              {/* Total Purchase Price */}
              <div className="rounded-md border-t-4 border-blue-400 bg-blue-100 p-4 shadow-sm">
                <h3 className="text-2xl font-bold text-blue-600">
                  Total Purchase Price
                </h3>
                <p className="text-3xl font-semibold tracking-wide text-gray-700 ">
                  ₹ {totalPurchasePrice.toLocaleString()}
                </p>
              </div>

              {/* Total Maintenance Fee */}
              <div className="rounded-md border-t-4 border-yellow-400 bg-yellow-100 p-4 shadow-sm">
                <h3 className="text-2xl font-bold text-yellow-600">
                  Total Maintenance Fee
                </h3>
                <p className="text-3xl font-semibold tracking-wide text-gray-700">
                  ₹ {totalMaintenanceFee.toLocaleString()}
                </p>
              </div>

              {/* Total Profit */}
              <div className="rounded-md border-t-4 border-red-400 bg-red-100 p-4 shadow-sm">
                <h3 className="text-2xl font-bold text-red-600">
                  {totalProfit >= 0 ? "Total Profit" : "Total Loss"}
                </h3>
                <p
                  className={`text-3xl font-semibold tracking-wide ${
                    totalProfit >= 0 ? "text-green-700" : "text-red-700"
                  }`}
                >
                  ₹ {totalProfit.toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div
            id="invoice"
            className="mt-4 max-h-[20rem]  min-w-full overflow-auto  rounded-lg bg-white px-6 py-2"
          >
            <h1 id="Month" className="my-3 font-medium tracking-wide">
              Sales for the month :{" "}
              {[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ].filter((each, index) => {
                return selectedMonth == index + 1;
              })}{" "}
              {selectedYear}
            </h1>
            <table className="min-w-full leading-normal">
              <thead className="">
                <tr>
                  {[
                    "Model Name",
                    "Purchases Price",
                    "Maintenance Fee",
                    "Sold Price",
                    "Profit/Loss",
                  ].map((header) => (
                    <th
                      key={header}
                      className="border-b-2 border-[#640708] bg-[#640708] px-5 py-3 text-left text-xs font-bold uppercase tracking-wider text-white"
                      style={{
                        minWidth: header === "Model Name" ? "180px" : "auto",
                      }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              {!isLoading ? (
                <tbody>
                  {filteredProfitData.length > 0 ? (
                    filteredProfitData.map((item) => {
                      const profitOrLoss =
                        item.primaryDetails.soldPrice -
                        (item.primaryDetails.purchasedPrice +
                          item.primaryDetails.maintainanceFee);

                      return (
                        <tr key={item._id} className="hover:bg-gray-100">
                          <td className="flex items-center border-b border-gray-200 bg-white py-5 text-sm">
                            <img
                              src={item.primaryDetails.bikeImages[0]}
                              onClick={() =>
                                navigate(
                                  `/admin/purchased-bikes-from-owners/bike-details/${item._id}`
                                )
                              }
                              alt="No-Img"
                              className="m-2 h-12 w-12 cursor-pointer rounded-full border-[1px] border-gray-300"
                            />
                            <div>
                              <p className="text-md font-semibold text-gray-900">
                                {item.primaryDetails.makeAndModel}
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                {item.primaryDetails.registrationNumber}
                              </p>
                            </div>
                          </td>
                          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                            <p className="text-gray-900">
                              {item.primaryDetails.purchasedPrice}
                            </p>
                          </td>
                          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                            <p className="text-gray-900">
                              {item.primaryDetails.maintainanceFee}
                            </p>
                          </td>
                          <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                            <p className="text-gray-900">
                              {item?.primaryDetails?.soldPrice}
                            </p>
                          </td>
                          <td
                            className={`border-b border-gray-200 bg-white px-5 py-5 text-sm`}
                          >
                            <p
                              className={`flex flex-col items-center justify-center gap-1 rounded-full px-2 py-1 text-center font-semibold tracking-wide `}
                            >
                              <span className="">Rs.{profitOrLoss}</span>
                              <span
                                className={`w-fit rounded-md px-3 py-1 ${
                                  profitOrLoss > 0
                                    ? "bg-green-100 text-green-700"
                                    : "bg-red-100 text-red-700"
                                }`}
                              >
                                {profitOrLoss > 0 ? "Profit" : "Loss"}
                              </span>
                            </p>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={5}
                        className="border-b border-gray-200 bg-white px-5 py-5 text-center text-sm text-gray-900"
                      >
                        No results found
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index} className="animate-pulse">
                    <td className="w-1/12 whitespace-nowrap px-6 py-4">
                      <div className="flex items-center space-x-3">
                        <div className="h-12 w-12 rounded-full bg-gray-300" />
                        <div className="flex-1 space-y-4">
                          <div className="h-4 w-3/4 rounded bg-gray-300" />
                          <div className="h-4 w-3/4 rounded bg-gray-300" />
                        </div>
                      </div>
                    </td>
                    {Array.from({ length: 4 }).map((_, index) => (
                      <td
                        key={index}
                        className="w-1/12 whitespace-nowrap px-6 py-4"
                      >
                        <div className="h-6 w-3/4 rounded bg-gray-300" />
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ledger;
