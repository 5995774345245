import React from "react";
import MainDashboard from "./Screens/Admin/Dashboard/Dashboard";
import SignIn from "./Screens/Auth/SignIn";
import { MdHome, MdLock } from "react-icons/md";
import { AddNewBike } from "Screens/Admin/RawBikes/AddNewBike";
import BikeDetailsPage from "Screens/Admin/BikeDetailsPage";
import Inquiry from "Screens/Admin/Inquiry/Inquiry";
import InquiryDetails from "Screens/Admin/Inquiry/InquiryDetails";
import ForgotPassword from "Screens/Auth/ForgotPassword";
import { LuLayoutDashboard } from "react-icons/lu";
import { RiMotorbikeLine } from "react-icons/ri";
import { RiSearchEyeLine } from "react-icons/ri";
import { LuStore } from "react-icons/lu";
import ShowroomBikes from "Screens/Admin/ShowroomBikes";
import Banch from "Screens/Admin/branches/Banch";
import Branchdetails from "Screens/Admin/branches/Branchdetails";
import Staffs from "Screens/Admin/Staffs";
import { TbUsersGroup } from "react-icons/tb";
import { MdOutlineMapsHomeWork } from "react-icons/md";
import StaffDetails from "Screens/Admin/StaffDetails";
import BikeList from "Screens/Admin/RawBikes/BikeList";
import UserProfile from "Screens/Admin/UserProfile";
import SoldBikesList from "Screens/Admin/SoldBikesList";
import { LuBike } from "react-icons/lu";
import BranchBikeList from "Screens/Admin/branches/BranchBikeList";
import BranchSoldBikesList from "Screens/Admin/branches/BranchSoldBikesList";
import Ledger from "Screens/Admin/Ledger";
import { BsFillCalculatorFill } from "react-icons/bs";

const routes = [
  {
    name: "Dashboard",
    showInSidebar: true,
    layout: "/admin",
    path: "dashboard",
    icon: <LuLayoutDashboard className="h-6 w-6" />,
    component: <MainDashboard />,
    clickable: true,
  },
  {
    name: "Branches",
    showInSidebar: true,
    layout: "/admin",
    path: "branches",
    icon: <MdOutlineMapsHomeWork className="h-6 w-6" />,
    component: <Banch />,
    clickable: true,
  },
  {
    name: "All Staffs",
    showInSidebar: true,
    layout: "/admin",
    path: "all-staffs",
    icon: <TbUsersGroup className="h-6 w-6" />,
    component: <Staffs />,
    clickable: true,
  },
  {
    name: "Staff Details",
    showInSidebar: false,
    layout: "/admin",
    path: "all-staffs/staff-details/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <StaffDetails />,
    clickable: true,
  },
  {
    name: "Bikes Inquiries",
    showInSidebar: true,
    layout: "/admin",
    path: "inquiries/all-inquiries",
    icon: <RiSearchEyeLine className="h-6 w-6" />,
    component: <Inquiry />,
    clickable: true,
  },
  {
    name: "Raw Bikes",
    showInSidebar: true,
    layout: "/admin",
    icon: <RiMotorbikeLine className="h-6 w-6" />,
    clickable: false,
    children: [
      {
        name: "Bikes List",
        layout: "/admin",
        path: "purchased-bikes-from-owners/bike-list",
        component: <BikeList />,
      },
      {
        name: "Add New Bike",
        layout: "/admin",
        path: "purchased-bikes-from-owners/add-new-bike",
        component: <AddNewBike />,
      },
    ],
  },
  {
    name: "Bike Details",
    showInSidebar: false,
    layout: "/admin",
    path: "purchased-bikes-from-owners/bike-details/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <BikeDetailsPage />,
    clickable: true,
  },
  {
    name: "Branch Details",
    showInSidebar: false,
    layout: "/admin",
    path: "branches/branch-details/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <Branchdetails />,
    clickable: true,
  },
  {
    name: "Branch Bike",
    showInSidebar: false,
    layout: "/admin",
    path: "branches/bikes",
    icon: <MdHome className="h-6 w-6" />,
    component: <BranchBikeList />,
    clickable: true,
  },
  {
    name: "Branch Sold Bikes",
    showInSidebar: false,
    layout: "/admin",
    path: "branches/bikes_sold",
    icon: <MdHome className="h-6 w-6" />,
    component: <BranchSoldBikesList />,
    clickable: true,
  },
  {
    name: "Inquiry Details",
    showInSidebar: false,
    layout: "/admin",
    path: "inquiries/inquiry-details/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <InquiryDetails />,
    clickable: true,
  },
  {
    name: "Bikes In Showroom",
    showInSidebar: true,
    layout: "/admin",
    path: "bikes-in-showroom",
    icon: <LuStore className="h-6 w-6" />,
    component: <ShowroomBikes />,
    clickable: true,
  },
  {
    name: "User Profile",
    showInSidebar: false,
    layout: "/admin",
    path: "user-profile",
    icon: <MdHome className="h-6 w-6" />,
    component: <UserProfile />,
    clickable: true,
  },
  {
    name: "Sold Bikes",
    showInSidebar: true,
    layout: "/admin",
    path: "sold-bikes",
    icon: <LuBike className="h-6 w-6" />,
    component: <SoldBikesList />,
    clickable: true,
  },
  {
    name: "Ledger",
    showInSidebar: true,
    layout: "/admin",
    path: "ledger",
    icon: <BsFillCalculatorFill className="h-6 w-6" />,
    component: <Ledger />,
    clickable: true,
  },

  // Auth Routes Below
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgotPassword />,
  },
];

export default routes;
