import React, { useEffect, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import architaLogo from "../../assets/img/logos/logo.png";
import toast from "react-hot-toast";
const SoldBikeInvoice = ({
  loadSoldBike,
  setLoadSoldBike,
  currentSoldBike,
}) => {
  const pdfRef = useRef();

  // Function to generate PDF
  const handleGeneratePDF = async () => {
    toast("Downloading Please wait...", { duration: 4000 });

    // Select the invoice element
    const invoiceElement = document.getElementById("invoice");
    const buttonElm = document.getElementById("close");

    // Temporarily remove scroll and size limitations to capture all content
    invoiceElement.style.maxHeight = "none";
    invoiceElement.style.overflow = "visible";
    buttonElm.style.display = "none";

    const canvas = await html2canvas(invoiceElement, {
      scale: 2, // Higher scale for better resolution
      useCORS: true,
      allowTaint: true,
      scrollX: window.scrollX,
      scrollY: window.scrollY, // To capture full content
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4", // A4 size for invoices
    });

    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // If the content exceeds one page, add page breaks
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pdf.internal.pageSize.getHeight();

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();
    }

    pdf.save("sold-bike-invoice.pdf");

    // Restore original styles
    invoiceElement.style.maxHeight = "30rem";
    invoiceElement.style.overflow = "auto";
    buttonElm.style.display = "block";
  };

  useEffect(() => {
    // Automatically generate the PDF after a brief delay to ensure everything renders
    setTimeout(() => {
      handleGeneratePDF();
    }, 1000);
  }, []);

  const todayDate = new Date().toISOString().slice(0, 10);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#00000055]">
      <div
        id="invoice"
        className="mx-auto max-h-[30rem] max-w-[80rem] overflow-auto rounded-lg bg-white p-6 shadow-md"
        ref={pdfRef}
      >
        <div className="flex justify-between">
          <div className="text-lg tracking-wide">
            <img
              src={architaLogo}
              alt="No Image"
              className="h-[5rem] w-[6rem]"
            />
            <p>Archita Motors Pvt Ltd</p>
            <p>Plo No 523, Bank Of Baroda Side,</p>
            <p>Bhubaneswar, Samantarapur,</p>
            <p>751002</p>
          </div>
          <div className="text-end tracking-wide">
            <h1 className="text-3xl font-bold tracking-wide text-[black]">
              INVOICE
            </h1>
            <h1 className="text-sm text-[black]">Date: {todayDate}</h1>
          </div>
        </div>

        <h2 className="mb-2 text-center text-2xl font-semibold text-gray-800">
          Sold Details
        </h2>

        {/* Primary Details Section */}
        <div className="mb-8">
          <h3 className="mb-4 text-lg font-medium text-gray-700">
            Bike Details
          </h3>
          <table className="min-w-full border border-gray-200 bg-white text-left">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-3">Make & Model</th>
                <th className="px-4 py-3">Registration Number</th>
                <th className="px-4 py-3">Color</th>
                <th className="px-4 py-3">Emission Type</th>
                <th className="px-4 py-3">Odometer Reading</th>
                <th className="px-4 py-3">Bike Age</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails?.makeAndModel}
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails?.registrationNumber}
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails?.color}
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails?.emissionType}
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails?.odometerReading} KM
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails?.bikeAge} Years
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Purchaser Details Section */}
        <div className="mb-8">
          <h3 className="mb-4 text-lg font-medium text-gray-700">
            Bike Sold To
          </h3>
          <table className="min-w-full border border-gray-200 bg-white text-left">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-3">Purchaser Name</th>
                <th className="px-4 py-3">Phone</th>
                <th className="px-4 py-3">Email</th>
                <th className="px-4 py-3">Gender</th>
                <th className="px-4 py-3">Aadhar Number</th>
                <th className="px-4 py-3">Address</th>
                <th className="px-4 py-3">Postal Pin</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails.purchaserDetails.name}
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails.purchaserDetails.phone}
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails.purchaserDetails.email}
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails.purchaserDetails.gender}
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails.purchaserDetails.adharNo}
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails.purchaserDetails.address}
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails.purchaserDetails.pin}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Sales Details Section */}
        <div className="mb-8">
          <h3 className="mb-4 text-lg font-medium text-gray-700">
            Sales Details
          </h3>
          <table className="min-w-full border border-gray-200 bg-white text-left">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-3">Maintainance Fee</th>
                <th className="px-4 py-3">Sold Price</th>
                <th className="px-4 py-3">Sold Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-b px-4 py-3">
                  ₹{currentSoldBike?.primaryDetails?.maintainanceFee}
                </td>
                <td className="border-b px-4 py-3">
                  ₹{currentSoldBike?.primaryDetails?.soldPrice}
                </td>
                <td className="border-b px-4 py-3">
                  {currentSoldBike?.primaryDetails?.soldDate?.slice(0, 10)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <button
          id="close"
          onClick={() => setLoadSoldBike(!loadSoldBike)}
          className="float-right mx-3 my-3 rounded-md bg-red-500 p-2 px-4 text-sm font-semibold tracking-wide text-white"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SoldBikeInvoice;
