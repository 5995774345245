import ChartData from "Components/Chart/ChartData";
import ColumnChart from "Components/Chart/ColumnChart";
import Widget from "Components/Common/Widget/Widget";
import WelcomeAdmin from "Components/WelcomeAdmin/WelcomeAdmin";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart } from "react-icons/md";
import axios from 'axios';
import { useEffect, useState } from "react";

const Dashboard = () => {
  const userRole = JSON.parse(localStorage.getItem('Archita-Users-Data'))?.role;
  const [bikeList, setBikeList] = useState([]);

  useEffect(() => {
    getAllBikes();
  }, [])

  const getAllBikes = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://archita-motors.onrender.com/archita-motors-api/bikes/get-all-bikes',
      headers: {},
      data: ''
    };

    axios.request(config)
      .then((response) => {
        // console.log('bikes', response.data);
        setBikeList(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const inShowroomCount = bikeList?.filter(bike => bike?.primaryDetails?.bikeStatus === "InShowroom")?.length;
  const soldCount = bikeList?.filter(bike => bike?.primaryDetails?.bikeStatus === "Sold")?.length;

  return (
    <div>
      <WelcomeAdmin />

      {
        userRole === "branch-admin"
          ?
          <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Bikes Available In Branch"}
              subtitle='50'
            />
            <Widget
              icon={<IoDocuments className="h-6 w-6" />}
              title={"Bikes Already Sold Out"}
              subtitle={soldCount}
            />
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Our Happy Customers"}
              subtitle='109'
            />
          </div>
          :
          <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Our total No. of Bikes"}
              subtitle={bikeList?.length}
            />
            <Widget
              icon={<IoDocuments className="h-6 w-6" />}
              title={"Bikes Already Sold Out"}
              subtitle={soldCount}
            />
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Bikes Available In Showrooms"}
              subtitle={inShowroomCount}
            />
          </div>
      }

      <div className="mt-6 flex flex-col gap-6  xl:flex-row ">
        <div
          id="columnChart"
          className=" h-[24rem] w-[100%] overflow-y-hidden overflow-x-scroll rounded-2xl bg-[#ffffff] shadow-lg xl:w-[70%]"
        >
          <ChartData />
        </div>
        <div
          id="columnChart"
          className="h-[24rem] w-[100%] overflow-y-hidden overflow-x-scroll rounded-2xl bg-[#ffffff] p-3 shadow-lg xl:w-[30%]"
        >
          <ColumnChart />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
