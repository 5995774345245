import { data } from "autoprefixer";
import React, { useEffect, useState } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DocumentModal from "Components/DocumentsModal/DocumentModal";
import EditBikeDetails from "./RawBikes/EditBikeDetails";
import toast from "react-hot-toast";
data = {
  primaryDetails: {
    makeAndModel: "Hero Splendor Plus",
    yearOfManufacture: 2018,
    bikeAge: 6,
    registrationNumber: "DL8SAM1234",
    engineNumber: "HSP1234567890",
    engineDisplay: 97.2,
    chassisNumber: "HSPCH1234567890",
    odometerReading: 18000,
    color: "Black with Red Stripes",
    fuelType: "Petrol",
    transmissionType: "Manual",
    wheelsType: "Alloy",
    groundClearance: 165,
    emissionType: "BS4",
    abs: false,
    tyreType: "Tubeless",
    insuranceDetails: {
      policyNumber: "INSHSP1234567890",
      validity: "2024-10-30",
    },
    originalPurchaseDate: "2018-08-20",
    bikeImages: [
      "https://imgd.aeplcdn.com/1280x720/n/cw/ec/123615/hero-splendor-plus-left-side-view0.jpeg?isig=0&wm=0",
      "https://imgd.aeplcdn.com/1280x720/n/cw/ec/123615/hero-splendor-plus-left-side-view0.jpeg?isig=0&wm=0",
      "https://imgd.aeplcdn.com/1280x720/n/cw/ec/123615/hero-splendor-plus-left-side-view0.jpeg?isig=0&wm=0",
      "https://imgd.aeplcdn.com/1280x720/n/cw/ec/123615/hero-splendor-plus-left-side-view0.jpeg?isig=0&wm=0",
    ],
    secondSellingPrice: 45000,
    bikeStatus: "Unsold",
    extraDescription:
      "Single owner, excellent fuel efficiency, and regularly serviced.",
  },
  condition: {
    currentCondition: "Excellent",
    anyAccidentsOrDamages: false,
    modificationsOrAccessories: "Seat cover, leg guard, side mirrors",
    tyreCondition: "Excellent",
    batteryCondition: "Excellent",
    brakeCondition: "Good",
    chainCondition: "Good",
  },
  documentation: {
    registrationCertificate: "RCHSP1234567890",
    insurancePapers: "INSHSP1234567890",
    pucCertificate: "PUCHSP1234567890",
    serviceBook: "SB1234567890",
    ownersManual: "OM1234567890",
  },
  ownerDetails: {
    fullName: "Vijay Sharma",
    address: "Rohini, New Delhi",
    postalPin: "110085",
    age: 35,
    gender: "Male",
    dob: "1989-07-12",
    ownerImage: "https://example.com/images/vijay.jpg",
    nationality: "Indian",
    contactNumber: "+91-9876543210",
    emailAddress: "vijay.sharma@example.com",
    idProofType: "Aadhar",
    idProofNumber: "1234-5678-9101",
  },
};

const BikeDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [bikedata, setbikedata] = useState(null);
  const [loader, setloader] = useState(false);
  const [modal, setModal] = useState(false);
  const [CurrentImgUrl, setCurrentImgUrl] = useState("");
  const [imgName, setImgName] = useState("");
  const [editBike, setEditbike] = useState(false);
  const [Id, setId] = useState("");
  const [branchname, setBranchName] = useState("");

  const getbikebyid = () => {
    setloader(true);
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://archita-motors.onrender.com/archita-motors-api/bikes/get-bike-by-id/${id}`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("---->", response.data);
        setbikedata(response.data);
        setId(response?.data?.branch);
        setloader(false);
        console.log(Id);
      })
      .catch((error) => {
        console.log(error);
        setloader(false);
      });
  };

  const GetBranch = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://archita-motors.onrender.com/archita-motors-api/branch/all-branches",
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data);
        const branchi = response?.data.find((e) => {
          return e?._id === Id;
        });
        console.log(branchi);
        setBranchName(branchi.name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteBike = (id) => {
    if (window.confirm("Are you sure you want to delete this Item ?")) {
      setDeleteLoad(true);
      let data = "";

      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `https://archita-motors.onrender.com/archita-motors-api/bikes/delete-bike/${id}`,
        headers: {},
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          toast.success("Bike Deleted Successfully.");
          setDeleteLoad(false);
          navigate("/admin/purchased-bikes-from-owners/bike-list");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Couldn't delete Bike . Something went wrong ! ");
        });
    }
  };

  useEffect(() => {
    getbikebyid();
  }, [editBike]);

  useEffect(() => {
    if (Id) {
      GetBranch();
    }
  }, [Id]);
  console.log("---->", Id);

  return (
    <div className="relative">
      {modal ? (
        <DocumentModal
          modal={modal}
          imgName={imgName}
          CurrentImgUrl={CurrentImgUrl}
          setModal={setModal}
        />
      ) : (
        ""
      )}

      <div className="mt-6 flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="flex items-center ">
          <MdOutlineArrowBackIos
            onClick={() => navigate(-1)}
            className="cursor-pointer"
            size={22}
            color="#070619"
          />
          <div className="ml-2 text-xl font-extrabold tracking-wide text-[#640708] md:text-2xl">
            {editBike ? "Edit Bike Details" : "Bike Details"}
          </div>
        </div>
        <div className="mt-3 flex items-center  md:mt-0">
          <button
            onClick={() => {
              if (editBike == false) {
                setEditbike(true);
              } else {
                setEditbike(false);
              }
            }}
            className="m-2 h-[2.5rem] w-[6rem] rounded-lg bg-[#640708] font-semibold tracking-wide text-white"
          >
            {editBike ? "Go Back" : "Edit"}
          </button>
          <button
            onClick={() => {
              deleteBike(bikedata?._id);
            }}
            className="m-2 flex h-[2.5rem] w-[6rem] items-center justify-center rounded-lg bg-red-500 font-semibold tracking-wide text-white"
          >
            {deleteLoad ? <div className="loaderButton"></div> : "Delete"}
          </button>
        </div>
      </div>
      {editBike ? (
        <EditBikeDetails bikedata={bikedata} setEditbike={setEditbike} />
      ) : (
        <div>
          {!loader ? (
            <div className="m-auto mt-4 flex flex-col space-y-6 lg:flex-row lg:space-x-6 lg:space-y-0">
              <div className="w-full space-y-6 lg:w-[50%]">
                {/* Primary Details */}
                <div className="mx-auto max-w-full rounded-lg bg-white p-4 shadow-lg sm:p-6">
                  <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
                    <h2 className="text-lg font-semibold tracking-wide sm:text-xl">
                      Primary Details
                    </h2>
                  </div>
                  <div className="mt-4 space-y-2">
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Make and Model
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.primaryDetails.makeAndModel}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Year of Manufacture
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.primaryDetails.yearOfManufacture}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Bike Age
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.primaryDetails.bikeAge}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Purchased Price
                      </span>
                      <span className="text-gray-500">
                        Rs.{bikedata?.primaryDetails.purchasedPrice}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Maintainance Fee
                      </span>
                      <span className="text-gray-500">
                        Rs.{bikedata?.primaryDetails.maintainanceFee}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Registration Number
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.primaryDetails.registrationNumber}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Engine Number
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.primaryDetails.engineNumber}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide ">
                        Engine Display
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.primaryDetails.engineDisplay}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide ">Branch</span>
                      <span className="text-gray-500">{branchname}</span>
                    </div>
                  </div>
                </div>

                {/* Condition */}
                <div className="mx-auto max-w-full rounded-lg bg-white p-4 shadow-lg sm:p-6">
                  <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
                    <h2 className="text-lg font-semibold tracking-wide sm:text-xl">
                      Condition
                    </h2>
                  </div>
                  <div className="mt-4 space-y-2">
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Current Condition
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.condition.currentCondition}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Accidents or Damages
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.condition.anyAccidentsOrDamages
                          ? "Yes"
                          : "No"}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Modifications or Accessories
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.condition.modificationsOrAccessories}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Tyre Condition
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.condition.tyreCondition}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Battery Condition
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.condition.batteryCondition}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Brake Condition
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.condition.brakeCondition}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Chain Condition
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.condition.chainCondition}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Owner Details */}
                <div className="mx-auto max-w-full rounded-lg bg-white p-4 shadow-lg sm:p-6 lg:pb-12">
                  <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
                    <h2 className="text-lg font-semibold tracking-wide sm:text-xl">
                      Owner Details
                    </h2>
                  </div>
                  <div className="mt-4 space-y-2">
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Full Name
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.ownerDetails.fullName}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">Address</span>
                      <span className="text-gray-500">
                        {bikedata?.ownerDetails.address}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Postal Pin
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.ownerDetails.postalPin}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">Age</span>
                      <span className="text-gray-500">
                        {bikedata?.ownerDetails.age}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">Gender</span>
                      <span className="text-gray-500">
                        {bikedata?.ownerDetails.gender}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Date of Birth
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.ownerDetails?.dob?.slice(0, 10)}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Nationality
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.ownerDetails.nationality}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Contact Number
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.ownerDetails.contactNumber}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        Email Address
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.ownerDetails.emailAddress}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        ID Proof Type
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.ownerDetails.idProofType}
                      </span>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <span className="font-medium tracking-wide">
                        ID Proof Number
                      </span>
                      <span className="text-gray-500">
                        {bikedata?.ownerDetails.idProofNumber}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Documentation */}
                <div className="mx-auto h-[67rem] max-w-full rounded-lg bg-white  p-4 shadow-lg sm:h-auto sm:p-6">
                  <div className="">
                    <h2 className="text-lg font-semibold tracking-wide sm:text-xl">
                      Owner Documentation
                    </h2>
                  </div>
                  <div className="mt-4 flex h-[62rem]  flex-wrap justify-center gap-3 sm:h-auto lg:gap-6 ">
                    <div className="m-1 h-[10rem] w-[100%] cursor-pointer  rounded-lg sm:w-[17rem] lg:w-[13rem]">
                      <img
                        onClick={() => {
                          setModal(true);
                          setCurrentImgUrl(
                            bikedata?.documentation.registrationCertificate
                          );
                          setImgName("Registration Certificate");
                        }}
                        src={bikedata?.documentation.registrationCertificate}
                        className="h-full w-full rounded-lg object-cover hover:opacity-90"
                        alt="No Image"
                      />

                      <p className="my-1 text-center font-medium">
                        Registration Certificate
                      </p>
                    </div>
                    <div className="m-1 h-[10rem] w-[100%] cursor-pointer  rounded-lg sm:w-[17rem]  lg:w-[13rem]">
                      <img
                        onClick={() => {
                          setModal(true);
                          setCurrentImgUrl(
                            bikedata?.documentation.insurancePapers
                          );
                          setImgName("Insurance Papers");
                        }}
                        src={bikedata?.documentation.insurancePapers}
                        className="h-full w-full rounded-lg object-cover hover:opacity-90"
                        alt="No Image"
                      />
                      <p className="my-1 text-center font-medium">
                        Insurance Papers
                      </p>
                    </div>
                    <div className="m-1 h-[10rem] w-[100%] cursor-pointer  rounded-lg sm:w-[17rem] lg:w-[13rem]">
                      <img
                        onClick={() => {
                          setModal(true);
                          setCurrentImgUrl(
                            bikedata?.documentation.pucCertificate
                          );
                          setImgName("Puc Certificate");
                        }}
                        src={bikedata?.documentation.pucCertificate}
                        className="h-full w-full rounded-lg object-cover hover:opacity-90"
                        alt="No Image"
                      />

                      <h1 className="my-1 text-center font-medium">
                        Puc Certificate
                      </h1>
                    </div>
                    <div className="m-1 h-[10rem] w-[100%] cursor-pointer  rounded-lg sm:w-[17rem]  lg:w-[13rem]">
                      <img
                        onClick={() => {
                          setModal(true);
                          setCurrentImgUrl(bikedata?.documentation.serviceBook);
                          setImgName("Service Book");
                        }}
                        src={bikedata?.documentation.serviceBook}
                        className="h-full w-full rounded-lg object-cover hover:opacity-90"
                        alt="No Image"
                      />
                      <p className="my-1 text-center font-medium">
                        Service Book
                      </p>
                    </div>
                    <div className="m-1 h-[10rem] w-[100%] cursor-pointer  rounded-lg sm:w-[17rem]  lg:w-[13rem]">
                      <img
                        onClick={() => {
                          setModal(true);
                          setCurrentImgUrl(
                            bikedata?.documentation.ownersManual
                          );
                          setImgName("Owner's Manual");
                        }}
                        src={bikedata?.documentation.ownersManual}
                        className="h-full w-full rounded-lg object-cover hover:opacity-90"
                        alt="No Image"
                      />
                      <p className="my-1 text-center font-medium">
                        Owner's Manual
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="max-w-full mx-auto bg-white shadow-lg rounded-lg p-4 sm:p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <h2 className="text-lg sm:text-xl font-semibold tracking-wide">Owner Documentation</h2>
            </div>
            <div className="mt-4 space-y-2">
              <div className="flex flex-col sm:flex-row justify-between">
                <span className="font-medium tracking-wide">Registration Certificate</span>
                <span className="text-gray-500">
                  {bikedata?.documentation.registrationCertificate}
                </span>
              </div>
              <div className="flex flex-col sm:flex-row justify-between">
                <span className="font-medium tracking-wide">Insurance Papers</span>
                <span className="text-gray-500">
                  {bikedata?.documentation.insurancePapers}
                </span>
              </div>
              <div className="flex flex-col sm:flex-row justify-between">
                <span className="font-medium tracking-wide">PUC Certificate</span>
                <span className="text-gray-500">
                  {bikedata?.documentation.pucCertificate}
                </span>
              </div>
              <div className="flex flex-col sm:flex-row justify-between">
                <span className="font-medium tracking-wide">Service Book</span>
                <span className="text-gray-500">
                  {bikedata?.documentation.serviceBook}
                </span>
              </div>
              <div className="flex flex-col sm:flex-row justify-between">
                <span className="font-medium tracking-wide">Owner's Manual</span>
                <span className="text-gray-500">
                  {bikedata?.documentation.ownersManual}
                </span>
              </div>
            </div>
          </div> */}
              </div>

              <div className="bike-images-container w-full space-y-6 lg:w-[50%]">
                {bikedata?.primaryDetails.bikeImages.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`Bike-img-${index + 1}`}
                      className="bike-image rounded-lg shadow-lg"
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="m-auto mt-4 flex flex-col space-y-6 lg:flex-row lg:space-x-6 lg:space-y-0">
              <div className="w-full space-y-6 lg:w-[50%]">
                {/* Primary Details Skeleton */}
                <div className="mx-auto max-w-full rounded-lg bg-white p-4 shadow-lg sm:p-6">
                  <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
                    <div className="h-6 w-1/3 rounded bg-gray-300"></div>
                  </div>
                  <div className="mt-4 animate-pulse space-y-2">
                    {/* Repeat this for each field */}
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded    bg-gray-300 lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5 rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    {/* Add more fields as needed */}
                  </div>
                </div>

                {/* Condition Skeleton */}
                <div className="mx-auto max-w-full animate-pulse rounded-lg bg-white p-4 shadow-lg sm:p-6">
                  <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
                    <div className="h-6 w-1/3 rounded bg-gray-300"></div>
                  </div>
                  <div className="mt-4 space-y-2">
                    {/* Repeat this for each field */}
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded    bg-gray-300 lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    {/* Add more fields as needed */}
                  </div>
                </div>

                {/* Documentation Skeleton */}
                <div className="mx-auto max-w-full animate-pulse rounded-lg bg-white p-4 shadow-lg sm:p-6">
                  <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
                    <div className="h-6 w-1/3 rounded bg-gray-300"></div>
                  </div>
                  <div className="mt-4 space-y-2">
                    {/* Repeat this for each field */}
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded    bg-gray-300 lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    {/* Add more fields as needed */}
                  </div>
                </div>

                {/* Owner Details Skeleton */}
                <div className="mx-auto max-w-full animate-pulse rounded-lg bg-white p-4 shadow-lg sm:p-6 lg:pb-12">
                  <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
                    <div className="h-6 w-1/3 rounded bg-gray-300"></div>
                  </div>
                  <div className="mt-4 space-y-2">
                    {/* Repeat this for each field */}
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded    bg-gray-300 lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row">
                      <div className="h-4 w-2/3 rounded  bg-gray-300  lg:h-5"></div>
                      <div className="mt-2 h-4 w-1/5  rounded bg-gray-300  md:mt-0  lg:h-5"></div>
                    </div>
                    {/* Add more fields as needed */}
                  </div>
                </div>
              </div>

              {/* Images Skeleton */}
              <div className="bike-images-container w-full space-y-6 lg:w-[50%]">
                <div className="h-[300px] animate-pulse rounded-lg bg-gray-100 shadow-lg"></div>
                <div className="h-[200px] animate-pulse rounded-lg bg-gray-100 shadow-lg"></div>
                <div className="h-[300px] animate-pulse rounded-lg bg-gray-100 shadow-lg"></div>
                <div className="h-[200px] animate-pulse rounded-lg bg-gray-100 shadow-lg"></div>
                {/* Add more skeletons for images as needed */}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BikeDetailsPage;
