import React, { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { updateBikeStatusModal } from "../../redux/Slice/architaMotorsSilceOne";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export const BikeStatusModal = ({
  setLoadModal,
  currentBike,
  refresh,
  setRefresh,
}) => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [secondPrice, setSecondPrice] = useState("");
  const [maintainanceFee, setMaintainanceFee] = useState("");
  const [check, setCheck] = useState(false);
  const [branchData, setBranchData] = useState([]);
  const [branchId, setBranchId] = useState([]);
  // console.log(check);

  const getAllBranch = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://archita-motors.onrender.com/archita-motors-api/branch/all-branches`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setBranchData(response.data);
        if (response.data.length > 0) {
          setBranchId(response.data[0]._id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(branchId);
  const handleBranchSelect = (value) => {
    const filterItem = branchData.filter((eachItem) => {
      return eachItem.name == value;
    });

    console.log("I am filter branch-------------->", filterItem[0]);
    setBranchId(filterItem[0]._id);
  };

  const updateBikeInfo = (id) => {
    if (!secondPrice) {
      toast.error("Please enter asking price !");
      return;
    }

    if (!maintainanceFee) {
      toast.error("Please enter maintainance fee !");
      return;
    }

    setLoad(true);
    let data = {
      bikeStatus: "InShowroom",
      maintainanceFee: maintainanceFee,
      secondSellingPrice: secondPrice,
      branchId: branchId,
    };

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `https://archita-motors.onrender.com/archita-motors-api/bikes/status-price/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        toast.success("Bike Info Updated Successfully.");
        setLoadModal(false);
        setLoad(false);
        if (refresh == false) {
          setRefresh(true);
        } else {
          setRefresh(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Bike Info Couldn,t be Updated !");

        setLoad(false);
        setLoadModal(false);
        if (refresh == false) {
          setRefresh(true);
        } else {
          setRefresh(false);
        }
      });
  };

  useEffect(() => {
    getAllBranch();
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex h-screen w-[100%] items-center justify-center bg-[#00000073] ">
      <div className="relative mt-4  w-[30rem] rounded-lg bg-white p-4">
        <IoMdCloseCircle
          onClick={() => {
            setLoadModal(false);
          }}
          className="absolute right-1 top-1 cursor-pointer"
          size={25}
        />

        <h1 className="mt-4 text-center text-lg font-bold tracking-wide">
          Update Bike Info
        </h1>
        <div className="mt-6 rounded-lg bg-gray-100 p-3">
          <div className="mb-3">
            <h1 className="mb-2 flex justify-between font-semibold tracking-wide">
              <span>Hello There...</span>
              <span>{currentBike?.primaryDetails?.makeAndModel}</span>
            </h1>

            <div className="flex items-center gap-3">
              <p
                value="Unsold"
                className={`rounded-full  bg-orange-200 px-5 py-1 font-semibold text-orange-900 hover:bg-orange-300 
               
                `}
              ></p>
              <p
                value="Unsold"
                className={`rounded-full  bg-green-200 px-5 py-1 font-semibold text-green-900 hover:bg-green-300 
               
                `}
              ></p>
            </div>
          </div>

          <div className="mt-3">
            <label
              htmlFor="sellingPrice"
              className=" font-semibold tracking-wide text-[black]"
            >
              {" "}
              Asking Price :
            </label>
            <br></br>
            <input
              type="text"
              name="sellingPrice"
              onChange={(e) => {
                setSecondPrice(e.target.value);
              }}
              className="mt-2 h-[3rem] w-full rounded-md border border-blue-300 p-2 tracking-wide"
              id=""
            />
          </div>
          <div className="mt-3">
            <label
              htmlFor="sellingPrice"
              className=" font-semibold tracking-wide text-[black]"
            >
              {" "}
              Maintainance Fee :
            </label>
            <br></br>
            <input
              type="text"
              name="maintainanceFee"
              onChange={(e) => {
                setMaintainanceFee(e.target.value);
              }}
              className="mt-2 h-[3rem] w-full rounded-md border border-blue-300 p-2 tracking-wide"
              id=""
            />
          </div>

          <div class=" my-3 flex w-full flex-col">
            <label for="branch" class="mb-2  font-semibold tracking-wide ">
              Please assign a branch from below:
            </label>
            <select
              onChange={(e) => {
                handleBranchSelect(e.target.value);
              }}
              id="branch"
              name="branch"
              class="block h-[3rem] w-full rounded-md border border-blue-300 px-4 py-2 text-sm tracking-wide shadow-sm focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {branchData?.map((eachItem, index) => {
                return (
                  <option key={index} value={eachItem.name}>
                    {eachItem.name}
                  </option>
                );
              })}
            </select>
          </div>

          {/* -------------------------------------- */}
          <div className="my-6">
            <h1 className="  font-semibold tracking-wide">
              {" "}
              Updating Bike Status To :
              <span
                value="Unsold"
                className={`mx-2 rounded-full bg-green-200 px-5 py-1 font-semibold text-green-900 hover:bg-green-300 
               
                `}
              >
                InShowroom
              </span>
            </h1>

            <div className="mt-1 flex items-center gap-[0.2rem]"></div>
          </div>

          <div className="mt-3 flex justify-center">
            <button
              onClick={() => {
                updateBikeInfo(currentBike._id);
              }}
              className="mx-auto flex h-[2.5rem] w-[6rem] items-center justify-center rounded-md bg-[#640708] font-semibold tracking-wide text-white"
            >
              {load ? <div className="loaderButton"></div> : "Update"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
