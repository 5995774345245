import React from "react";
import bikeGif from "../../../assets/bikeGif.gif";

const Loader = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <img
        className="object-contain h-40 md:h-72 w-auto"
        src={bikeGif}
        alt="loader_gif"
      />
      {/* <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div> */}
    </div>
  );
};
export default Loader;

