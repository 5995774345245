import React, { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { TbPhoneCall } from "react-icons/tb";
import { MdOutlineLocationOn } from "react-icons/md";
import axios from "axios";
import CreateStaffModal from "Components/StaffModal/CreateStaffModal";
import { useNavigate } from "react-router-dom";

const Staffs = () => {
  const navigate = useNavigate();
  const [staffData, setStaffData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [loader, setloader] = useState(false);
  const [openmodal, setopenmodal] = useState(false);
 

  const getAllStaff = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://archita-motors.onrender.com/archita-motors-api/users/all-users",
      headers: {},
    };
    setloader(true);
    axios
      .request(config)
      .then((response) => {
        //   console.log(JSON.stringify(response.data));
        const filterdata = response?.data?.filter((e) => {
          return e.role === "staff";
        });
        setStaffData(filterdata);
        setloader(false);
      })
      .catch((error) => {
        console.log(error);
        setloader(false);
      });
  };
  useEffect(() => {
    getAllStaff();
  }, []);
  const handelsearch = (e) => {
    setSearchQuery(e.target.value);
  };
  useEffect(() => {
    const filteredData = staffData.filter((item) =>
      item?.userName?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilterData(filteredData);
  }, [searchQuery, staffData]);
  //   console.log(searchQuery);
  const openingmodal = () => {
    setopenmodal(true);
  };
  const closemodal = () => {
    setopenmodal(false);
  };

  return (
    <React.Fragment>
      <>
        <div className="mt-6 text-xl font-extrabold tracking-wide text-[#640708] md:text-2xl">
          Staff List
        </div>
        <div className="items-center  justify-between md:flex">
          <div className="relative my-4 w-full rounded-lg border border-none bg-white md:w-[40%]">
            <input
              type="search"
              placeholder="Search..."
              onChange={handelsearch}
              value={searchQuery}
              className="w-full rounded-lg border border-blue-400 py-2 pl-10 pr-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 md:py-2.5"
            />
            <IoSearchOutline
              size={20}
              className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-500"
              aria-hidden="true"
            />
          </div>
          <button
            onClick={openingmodal}
            className="rounded-xl bg-[#640708] px-5 py-2.5 font-semibold tracking-normal text-white"
          >
            {" "}
            Add Staff
          </button>
        </div>

        {staffData.length > 0 && filterData.length <= 0 ? (
          <div className="mt-8 h-[50%] w-full md:mt-20">
            <h2 className="text-center text-[1.5rem] text-gray-700">
              No Staffs Found.
            </h2>
          </div>
        ) : (
          <div>
            {loader ? (
              <div className="mt-2.5 grid w-full grid-cols-1 gap-5 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
                {[...Array(6)].map((_, index) => (
                  <div
                    key={index}
                    className="animate-pulse rounded-2xl border-[1px] bg-white p-4"
                  >
                    <div className="flex items-center gap-4 border-b-[1px] border-gray-400 py-3">
                      <div className="h-[3.3rem] w-[3.3rem] rounded-full bg-gray-300"></div>
                      <div className="flex flex-col gap-2">
                        <div className="h-4 w-24 rounded bg-gray-300"></div>
                        <div className="h-3 w-16 rounded bg-gray-300"></div>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 border-b-[1px] py-3">
                      <div className="h-6 w-6 rounded bg-gray-300"></div>
                      <div className="h-3 w-full rounded bg-gray-300"></div>
                    </div>
                    <div className="flex items-center gap-4 border-b-[1px] py-3">
                      <div className="h-6 w-6 rounded bg-gray-300"></div>
                      <div className="h-3 w-full rounded bg-gray-300"></div>
                    </div>
                    <div className="flex items-center gap-4 py-3">
                      <div className="h-6 w-6 rounded bg-gray-300"></div>
                      <div className="h-3 w-full rounded bg-gray-300"></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mt-2.5 grid w-full grid-cols-1 gap-5 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
                {filterData?.map((e, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => navigate(`/admin/all-staffs/staff-details/${e?._id}`)}
                      className="rounded-2xl border-[1px] bg-white p-4 cursor-pointer"
                    >
                      <div className="flex items-center gap-4 border-b-[1px] border-gray-400 py-3">
                        <img
                          src={e.profilePic}
                          alt="Profile pic"
                          className="w-[3rem] h-[3rem] rounded-full"
                        />
                        <div>
                          <h2 className="text-[1rem] font-semibold capitalize tracking-normal text-gray-800">
                            {e.userName}
                          </h2>
                          <h3 className="text-[.97rem] font-normal tracking-normal text-gray-700">
                            {e.gender}
                          </h3>
                        </div>
                      </div>
                      <div className="flex items-center  gap-4 border-b-[1px]  py-3">
                        <MdOutlineMailOutline className="text-[1.3rem] tracking-normal  text-gray-700" />
                        <h2 className="text-[.97rem] font-normal tracking-normal text-gray-700">
                          {e.email}
                        </h2>
                      </div>
                      <div className="flex items-center  gap-4 border-b-[1px] py-3">
                        <TbPhoneCall className="text-[1.3rem] tracking-normal  text-gray-700" />
                        <h2 className="text-[.97rem] font-normal tracking-normal text-gray-700">
                          {e.phone}
                        </h2>
                      </div>
                      <div className="bitems-center flex  gap-4 py-3">
                        <MdOutlineLocationOn className="text-[1.3rem] tracking-normal  text-gray-700" />
                        <h2 className="text-[.97rem] font-normal tracking-normal text-gray-700">
                          {e.address}
                        </h2>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )

        }
        {staffData.length <=0 && (
        <div className="mt-8 h-[50%] w-full md:mt-20">
        <h2 className="text-center text-[1.5rem] text-gray-700">
          No Staffs Present.
        </h2>
      </div>     
        )
     
        }
        <CreateStaffModal
          open={openmodal}
          close={closemodal}
          getAllStaff={getAllStaff}
        // getBranchData={getBranchData}
        />
      </>
    </React.Fragment>
  );
};

export default Staffs;
