import React from "react";
import { HiX } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import logo from "../../assets/img/logos/logo.png";
import "./Sidebar.css";

const BranchSidebar = ({ open, onClose }) => {
  return (
    <div
      className={`fixed left-0 top-0 z-50 h-full w-72 transform rounded-r-xl bg-white py-4 pr-4 font-medium tracking-wide text-gray-900 shadow-lg transition-transform ${
        open ? "translate-x-0" : "-translate-x-full"
      } xl:translate-x-0`}
    >
      <div className="flex items-center justify-between gap-4 rounded-full bg-white px-4">
        <div className="flex w-full items-center justify-center">
          <img src={logo} alt="logo" />
        </div>
        <button
          className="text-gray-300 hover:text-white focus:outline-none xl:hidden"
          onClick={onClose}
        >
          <HiX className="h-6 w-6 text-navy-700" />
        </button>
      </div>

      <nav className="hide-scrollbar mt-4 flex h-[calc(100%-120px)] flex-col space-y-2 overflow-y-auto">
        <NavLink
          to="/admin/dashboard"
          className={({ isActive }) =>
            `flex items-center rounded-r-2xl px-6 py-3 text-lg tracking-wide transition-all duration-200 ${
              isActive
                ? "bg-[#640708] text-white"
                : "text-[#640708] hover:bg-[#302f42] hover:text-white"
            }`
          }
        >
          <span className="ml-3">Dashboard</span>
        </NavLink>
        <NavLink
          to="/admin/branches/bikes"
          className={({ isActive }) =>
            `flex items-center rounded-r-2xl px-6 py-3 text-lg tracking-wide transition-all duration-200 ${
              isActive
                ? "bg-[#640708] text-white"
                : "text-[#640708] hover:bg-[#302f42] hover:text-white"
            }`
          }
        >
          <span className="ml-3">Bikes Available</span>
        </NavLink>
        <NavLink
          to="/admin/branches/bikes_sold"
          className={({ isActive }) =>
            `flex items-center rounded-r-2xl px-6 py-3 text-lg tracking-wide transition-all duration-200 ${
              isActive
                ? "bg-[#640708] text-white"
                : "text-[#640708] hover:bg-[#302f42] hover:text-white"
            }`
          }
        >
          <span className="ml-3">Sold Bikes</span>
        </NavLink>
      </nav>
    </div>
  );
};

export default BranchSidebar;
