import React from "react";
import AdminSidebar from "./AdminSidebar";
import BranchSidebar from "./BranchSidebar";

const Sidebar = ({ open, onClose }) => {
  const userRole = JSON.parse(localStorage.getItem('Archita-Users-Data'))?.role;

  return (
    <React.Fragment>
      {
        userRole === "branch-admin"
          ?
          <BranchSidebar
            open={open}
            onClose={onClose}
          />
          :
          <AdminSidebar
            open={open}
            onClose={onClose}
          />
      }
    </React.Fragment>
  );
};

export default Sidebar;