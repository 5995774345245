import React from 'react'
import { IoMdCloseCircle } from "react-icons/io";
const DocumentModal = ({setModal,CurrentImgUrl,imgName}) => {
console.log(imgName);
  return (
    <div className='fixed inset-0 h-screen w-full z-50 bg-[#0000006a] flex justify-center items-center '>
        <div className='relative sm:h-[80vh]  lg:w-[50rem] w-[90%] rounded-lg bg-white p-4'>
        <IoMdCloseCircle onClick={()=>{
            setModal(false);
        }} className="absolute top-2 right-2 " size={25}/>

        <h1 className="text-center font-semibold text-md  sm:text-2xl mb-5">
  {imgName}
        </h1>
        <div className=" sm:h-[90%]  lg:my-4   rounded-lg">

        <img src={CurrentImgUrl} className='cursor-pointer sm:h-full w-full object-contain rounded-lg' alt="No Image" />
        </div>
        </div>
         </div>
  )
}

export default DocumentModal;