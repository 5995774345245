import React from "react";

const InqueryPopUp = ({close,onchanged,buttonLoader,names}) => {
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-[black] bg-opacity-50">
        <div className="relative w-[90%] rounded-lg bg-white p-6 shadow-lg md:w-[60%] lg:w-[45%] xl:w-[42%]">
          <h2 className="text-center text-lg font-semibold">
           {` Confirm Change To ${names}`}
          </h2>
          <p className="mt-4 text-center">{`Are you sure you want to  Change ${names}`}</p>
          <div className="mt-6 flex justify-center gap-4">
            <button
              onClick={onchanged}
              className="rounded-md bg-[#640708] px-6 py-2 font-medium text-white"
            >
              {buttonLoader ? (
                <div className="loaderButton "></div>
              ) : (
                " Yes, Change"
              )}
            </button>
            <button
              onClick={close}
              className="rounded-md bg-gray-200 px-6 py-2 font-medium text-gray-700"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InqueryPopUp;
