import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";

const Createbranch = ({ open, close, getBranchData }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorvalue, setErrorvalue] = useState("");
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    city: "",
    state: "",
    pin: "",
    fullAddress: "",
  });
  const closemodal = () => {
    close();
    setFormData({
      name: "",
      phone: "",
      email: "",
      password: "",
      city: "",
      state: "",
      pin: "",
      fullAddress: "",
    });
    setErrorvalue("");
    setErrors({
      name: false,
      phone: false,
      email: false,
      password: false,
      city: false,
      state: false,
      pin: false,
      fullAddress: false,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = true;
    }
    if (!formData.phone) {
      newErrors.phone = true;
    }
    if (!formData.email) {
      newErrors.email = true;
    }
    if (!formData.password) {
      newErrors.password = true;
    }
    if (!formData.city) {
      newErrors.city = true;
    }
    if (!formData.state) {
      newErrors.state = true;
    }
    if (!formData.pin) {
      newErrors.pin = true;
    }
    // else if (formData.pin.length !== 6 || formData.pin <= 0) {
    //   newErrors.pin = true;
    //   alert("Please enter a valid 6-digit PIN.");
    //   return false;
    // }
    if (!formData.fullAddress) {
      newErrors.fullAddress = true;
    }
    if (
      formData.phone &&
      (formData.phone.length !== 10 || formData.phone <= 0)
    ) {
      newErrors.phone = true;
      setErrorvalue("Invalid phone number");
      toast.error("Please enter a valid 10-digit phone number.");
    }
    if (formData.pin && (formData.pin.length !== 6 || formData.phone <= 0)) {
      newErrors.pin = true;
      setErrorvalue("Invalid pin number");
      toast.error("Please enter a valid 6-digit pin number.");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorvalue("");
    if (!validateForm()) {
      setButtonLoading(false);
      return;
    }
    setButtonLoading(true);
    let data = JSON.stringify({
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      password: formData.password,
      city: formData.city,
      state: formData.state,
      pin: formData.pin,
      fullAddress: formData.fullAddress,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://archita-motors.onrender.com/archita-motors-api/branch/add-branch",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setButtonLoading(false);
        toast.success("Branch created succesfully");
        getBranchData();
        closemodal();
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
        toast.error("Something went wrong !");
      });

    console.log(getBranchData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: false,
    });
    setErrorvalue("");
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-[black] bg-opacity-50">
      <div className="relative w-[90%] rounded-lg bg-white p-6 shadow-lg md:w-[60%] lg:w-[45%] xl:w-[42%]">
        <RxCross2 className="absolute right-3 top-2" onClick={closemodal} />
        <h2 className="mb-6 text-center text-2xl font-semibold tracking-normal text-gray-800">
          Create Branch
        </h2>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 gap-y-2 md:grid-cols-2 md:gap-x-6 md:gap-y-4"
        >
          <div>
            <label
              className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.name ? "text-red-500" : "text-[#161616]"
              }`}
            >
              Branch Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              placeholder="Enter your Branch Name"
              onChange={handleChange}
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.name ? "border-red-500" : ""
              }`}
            />
          </div>
          {/* <Toaster /> */}

          <div>
            <label
              className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.phone ? "text-red-500" : "text-[#161616]"
              }`}
            >
              Phone
            </label>
            <input
              type="number"
              name="phone"
              value={formData.phone}
              placeholder="Enter your phone number"
              onChange={handleChange}
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.phone ? "border-red-500" : ""
              }`}
            />
          </div>

          <div>
            <label
              className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.email ? "text-red-500" : "text-[#161616]"
              }`}
            >
              Email
            </label>
            <input
              type="text"
              name="email"
              value={formData.email}
              placeholder="Enter your email id"
              onChange={handleChange}
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.email ? "border-red-500" : ""
              }`}
            />
          </div>
          <div>
            <label
              className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.password ? "text-red-500" : "text-[#161616]"
              }`}
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              placeholder="Enter your Password"
              onChange={handleChange}
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.password ? "border-red-500" : ""
              }`}
            />
          </div>
          <div>
            <label
              className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.city ? "text-red-500" : "text-[#161616]"
              }`}
            >
              City
            </label>
            <input
              type="text"
              name="city"
              value={formData.city}
              placeholder="Enter your city"
              onChange={handleChange}
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.city ? "border-red-500" : ""
              }`}
            />
          </div>

          <div>
            <label
              className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.state ? "text-red-500" : "text-[#161616]"
              }`}
            >
              State
            </label>
            <input
              type="text"
              name="state"
              value={formData.state}
              placeholder="Enter your state"
              onChange={handleChange}
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.state ? "border-red-500" : ""
              }`}
            />
          </div>

          <div>
            <label
              className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.pin ? "text-red-500" : "text-[#161616]"
              }`}
            >
              Pin
            </label>
            <input
              type="text"
              name="pin"
              value={formData.pin}
              placeholder="Enter your PIN"
              onChange={handleChange}
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.pin ? "border-red-500" : ""
              }`}
            />
          </div>

          <div>
            <label
              className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.fullAddress ? "text-red-500" : "text-[#161616]"
              }`}
            >
              Full Address
            </label>
            <input
              type="text"
              name="fullAddress"
              value={formData.fullAddress}
              placeholder="Enter your full address"
              onChange={handleChange}
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.fullAddress ? "border-red-500" : ""
              }`}
            />
          </div>
          <div className="flex w-full flex-col justify-center md:col-span-2">
            <div className="flex w-full justify-center md:col-span-2">
              <h2 className="text-center text-[.9rem] text-red-600">
                {errorvalue}
              </h2>
            </div>
            <div className="flex w-full justify-center md:col-span-2">
              <button
                type="submit"
                className="mt-2 flex w-[7rem] items-center justify-center rounded-lg bg-[#640708] px-8 py-2 text-center text-[.93rem] font-semibold tracking-wide text-white"
                disabled={buttonLoading}
              >
                {buttonLoading ? (
                  <div className="loaderButton"></div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <Toaster />
    </div>
  );
};

export default Createbranch;
