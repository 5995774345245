import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logos/logo.png";
import { FaEyeSlash, FaRegEyeSlash, FaEye } from "react-icons/fa";
import axios from "axios";
import toast from "react-hot-toast";
import forgotBg from "../../assets/forPassBg.jpg";
import { FaUser, FaUsers } from "react-icons/fa";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const [showPassword, setshowforget] = useState(false);
  const [showPasswordconfirm, setshowforgetconfirm] = useState(false);
  const [buttonloader, setbuttonloader] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [passwordconfirm, setconfirmpassword] = useState("");
  const [phone, setphone] = useState("");
  const [userType, setUserType] = useState("adminStaff");

  const toggleeye = () => {
    setshowforget(!showPassword);
  };

  const toggleeyeconfirm = () => {
    setshowforgetconfirm(!showPasswordconfirm);
  };

  const forgotpassword = (e) => {
    e.preventDefault();
    setbuttonloader(true);

    if (phone.length !== 10) {
      toast.error("Enter a valid 10 digit number");
      setbuttonloader(false);
      return;
    }

    if (password !== passwordconfirm) {
      toast.error("Password mismatch");
      setbuttonloader(false);
      return;
    }

    const data = JSON.stringify({
      phone: phone,
      email: email,
      newPassword: password,
    });

    const config = {
      method: userType === "adminStaff" ? "post" : "patch",
      maxBodyLength: Infinity,
      // url: "https://archita-motors.onrender.com/archita-motors-api/users/user-forgot-password",
      url:
        userType === "adminStaff"
          ? "https://archita-motors.onrender.com/archita-motors-api/users/user-forgot-password"
          : "https://archita-motors.onrender.com/archita-motors-api/branch/branch-forgotpassword",

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast.success("Password changed successfully");
        setbuttonloader(false);
        navigate("/auth/sign-in");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
        setbuttonloader(false);
      })
      .finally(() => {
        setbuttonloader(false);
      });
  };

  const d = new Date();
  let year = d.getFullYear();

  return (
    <React.Fragment>
      <section
        style={{
          backgroundImage: `url(${forgotBg})`,
        }}
        className="flex h-screen items-center justify-center bg-cover bg-center bg-no-repeat"
      >
        {/* Image Section (Hidden on small screens) */}

        {/* Sign-In Form Section */}
        <div className="flex w-[90%] items-center justify-center rounded-xl bg-white px-6 py-6 shadow-2xl  md:mx-auto md:w-1/2 md:max-w-md lg:max-w-full lg:px-16 xl:w-1/3 xl:px-12">
          <div className="h-100 w-full">
            <div className=" flex items-center justify-center">
              <img src={logo} alt="logo" className="h-20 w-auto" />
            </div>
            <h1 className="mt-4 text-xl font-bold leading-tight tracking-wide text-[#070619] md:text-2xl">
              Forgot Your Password Here!
            </h1>

            {/* user type selection */}
            <div className="mt-5 flex justify-between space-x-4">
              <button
                type="button"
                className={`flex h-10 w-44 items-center justify-center space-x-2 rounded-lg border ${
                  userType === "adminStaff"
                    ? "bg-[#640708] text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => setUserType("adminStaff")}
              >
                <FaUsers size={22} />
                <span className="text-sm font-semibold tracking-wider">
                  Admin / Staff
                </span>
              </button>

              <button
                type="button"
                className={`flex h-10 w-44 items-center justify-center space-x-2 rounded-lg border ${
                  userType === "branchAdmin"
                    ? "bg-[#640708] text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => setUserType("branchAdmin")}
              >
                <FaUser size={18} />
                <span className="text-sm font-semibold tracking-wider">
                  Branch - Admin
                </span>
              </button>
            </div>
            {/* ------------- */}

            <form className="mt-6" onSubmit={forgotpassword}>
              <div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                  placeholder="Enter Your Email"
                  className="mt-2 w-full rounded-lg border bg-[#f5f2f2] px-4 py-3 focus:border-[#640708] focus:bg-white focus:outline-none"
                  autoFocus
                  autoComplete="email"
                  required
                />
              </div>
              <div className="relative my-4">
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={(e) => {
                    setphone(e.target.value);
                  }}
                  placeholder="Enter Your Phone Number"
                  className="mt-2 w-full rounded-lg border bg-[#f5f2f2] px-4 py-3 focus:border-[#640708] focus:bg-white focus:outline-none"
                  autoFocus
                  autoComplete="phone"
                  required
                />
              </div>
              <div className="relative my-4">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => {
                    setpassword(e.target.value);
                  }}
                  placeholder="Enter Password"
                  className="mt-2 w-full rounded-lg border bg-[#f5f2f2] px-4 py-3 focus:border-[#640708] focus:bg-white focus:outline-none"
                  required
                />
                {showPassword ? (
                  <FaEye
                    size={20}
                    onClick={toggleeye}
                    className="text-black absolute right-3 top-[40%]"
                  />
                ) : (
                  <FaEyeSlash
                    size={20}
                    onClick={toggleeye}
                    className="text-black absolute right-3 top-[40%]"
                  />
                )}
              </div>
              <div className="relative my-4">
                <input
                  type={showPasswordconfirm ? "text" : "password"}
                  name="confirmpassword"
                  id="confirmpassword"
                  placeholder="Confirm Password"
                  value={passwordconfirm}
                  onChange={(e) => {
                    setconfirmpassword(e.target.value);
                  }}
                  className="mt-2 w-full rounded-lg border bg-[#f5f2f2] px-4 py-3 focus:border-[#640708] focus:bg-white focus:outline-none"
                  required
                />
                {showPasswordconfirm ? (
                  <FaEye
                    size={20}
                    onClick={toggleeyeconfirm}
                    className="text-black absolute right-3 top-[40%]"
                  />
                ) : (
                  <FaEyeSlash
                    size={20}
                    onClick={toggleeyeconfirm}
                    className="text-black absolute right-3 top-[40%]"
                  />
                )}
              </div>

              <div className="my-4 text-right">
                <div
                  onClick={() => {
                    navigate("/auth/sign-in");
                  }}
                  className="cursor-pointer text-sm font-semibold tracking-wide text-[#070619] hover:text-[#640708]"
                >
                  Go to Login?
                </div>
              </div>

              <button
                type="submit"
                className="mx-auto flex w-full items-center justify-center rounded-lg bg-[#640708] px-4 py-3 font-semibold tracking-wide text-white hover:bg-[#070619]"
              >
                {buttonloader ? (
                  <div className="loaderButton "></div>
                ) : (
                  " Forgot Password"
                )}
              </button>
            </form>

            <p className="mt-5 text-center text-sm tracking-wider text-gray-500">
              &copy; {year} AM CRM - All Rights Reserved.
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ForgotPassword;
