import React from "react";
import Chart from "react-apexcharts";

const ColumnChart = () => {
  const options = {
    chart: {
      type: "bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Quaterly Purchased Bikes",
      align: "left",
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
    xaxis: {
      categories: ["Quater 1", "Quater 2", "Quater 3", "Quater 4"],
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Bikes",
        style: {
          fontSize: "12px",
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#259efa"],
  };

  const series = [
    {
      name: "Purchased Bikes",
      data: [120, 140, 130, 150],
    },
  ];

  return (
    <div className="p-4">
      <Chart
        options={options}
        series={series}
        type="bar"
        height={350}
        className="tracking-wider"
      />
    </div>
  );
};

export default ColumnChart;
