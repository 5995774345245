import React from "react";
import { Routes, Route } from "react-router-dom";

import AdminLayout from "Layouts/Admin";
import AuthLayout from "Layouts/Auth";
import { InitialRenderPage } from "Screens/Admin/Common/InitialRender";
import PageNotFound from "Screens/Admin/Common/NotFound";
import { Toaster } from "react-hot-toast";
const App = () => {
  return (
    <div>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="/" element={<InitialRenderPage />} />
        <Route path="/404" element={<PageNotFound />} />
      </Routes>
      <Toaster></Toaster>
    </div>
  );
};

export default App;
