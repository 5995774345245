import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logos/logo.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import { IoEye, IoEyeOff } from "react-icons/io5";
import loginBg from "../../assets/signInBg.jpg";
import { FaUser, FaUsers } from "react-icons/fa";

const SignIn = () => {
  let navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [load, setLoad] = useState(false);
  const [passType, setPassType] = useState(true);
  const [userType, setUserType] = useState("adminStaff");

  const login = (data) => {
    let myData = {
      email: data.email,
      password: data.password,
      userType: userType,
    };
    setLoad(true);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: userType === 'adminStaff'
        ?
        "https://archita-motors.onrender.com/archita-motors-api/users/user-login"
        :
        'https://archita-motors.onrender.com/archita-motors-api/branch/branch-login',

      headers: {
        "Content-Type": "application/json",
      },
      data: myData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log('object', response.data.branch)
        localStorage.setItem("ArchitaMotors-authToken", "khjjkhfdskhkjhkd");

        if (userType === 'adminStaff') {
          localStorage.setItem("Archita-Users-Data", JSON.stringify(response.data.user));
        } else {
          localStorage.setItem("Archita-Users-Data", JSON.stringify(response.data.branch));
        }

        setLoad(false);
        navigate("/admin/dashboard");
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        setLoad(false);
        toast.error(error?.response?.data?.error);
      });
  };

  const d = new Date();
  let year = d.getFullYear();

  return (
    <React.Fragment>
      <section
        style={{
          backgroundImage: `url(${loginBg})`,
        }}
        className="flex h-screen items-center justify-center bg-cover bg-center bg-no-repeat"
      >
        {/* Sign-In Form Section */}
        <div className="flex h-auto w-[90%] items-center justify-center rounded-xl bg-white px-6 pb-4 sm:h-[70%] sm:w-[80%] md:h-auto md:w-2/3 lg:w-1/2 xl:w-1/3">
          <div className="h-auto w-full">
            <div className="mt-4 flex items-center justify-center">
              <img src={logo} alt="logo" className="h-16 w-auto sm:h-20" />
            </div>
            <h1 className="text-center mt-4 text-lg font-bold leading-tight tracking-wide text-[#070619] sm:text-xl md:text-2xl">
              Log In to the CRM
            </h1>

            {/* User Type Selection */}
            <div className="mt-5 flex justify-between space-x-4">
              <button
                type="button"
                className={`flex justify-center items-center space-x-2 rounded-lg w-44 h-10 border ${userType === "adminStaff" ? "bg-[#640708] text-white" : "bg-gray-200"}`}
                onClick={() => setUserType("adminStaff")}
              >

                <FaUsers size={22} />
                <span className="text-sm font-semibold tracking-wider">
                  Admin / Staff
                </span>
              </button>

              <button
                type="button"
                className={`flex justify-center items-center space-x-2 rounded-lg w-44 h-10 border ${userType === "branchAdmin" ? "bg-[#640708] text-white" : "bg-gray-200"}`}
                onClick={() => setUserType("branchAdmin")}
              >

                <FaUser size={18} />
                <span className="text-sm font-semibold tracking-wider">
                  Branch - Admin
                </span>
              </button>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit(login)} className="mt-3 space-y-4">
              <div>
                <label className="font-medium tracking-wide">Email</label> <br />
                <input
                  type="email"
                  name="email"
                  id="email"
                  {...register("email")}
                  placeholder="Enter Your Email"
                  className="mt-2 w-full rounded-lg border bg-[#f5f2f2] px-4 py-3 focus:border-[#640708] focus:bg-white focus:outline-none"
                  autoFocus
                  autoComplete="email"
                  required
                />
              </div>

              <div className="relative mt-4">
                <label className="font-medium tracking-wide">Password</label> <br />
                <input
                  type={!passType ? "text" : "password"}
                  name="password"
                  id="password"
                  {...register("password")}
                  placeholder="Enter Your Password"
                  className="mt-2 w-full rounded-lg border bg-[#f5f2f2] px-4 py-3 focus:border-[#640708] focus:bg-white focus:outline-none"
                  required
                />
                {passType ? (
                  <IoEyeOff
                    onClick={() => {
                      setPassType(false);
                    }}
                    size="20"
                    className="absolute right-2 top-[2.8rem] cursor-pointer"
                  />
                ) : (
                  <IoEye
                    onClick={() => {
                      setPassType(true);
                    }}
                    size="20"
                    className="absolute right-2 top-[2.8rem] cursor-pointer"
                  />
                )}
              </div>

              <div className="my-4 text-right">
                <div
                  onClick={() => {
                    navigate("/auth/forgot-password");
                  }}
                  className="cursor-pointer text-sm font-semibold tracking-wide text-[#070619] hover:text-[#640708]"
                >
                  Forgot Password?
                </div>
              </div>

              <button
                type="submit"
                className="flex w-full items-center justify-center rounded-lg bg-[#640708] px-4 py-3 font-semibold tracking-wide text-white hover:bg-[#070619]"
              >
                {load ? <div className="loaderButton"></div> : "Log In"}
              </button>
            </form>

            <p className="tracking-wider text-center mt-4 text-sm text-gray-500">
              &copy; {year} AM CRM - All Rights Reserved.
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SignIn;

















// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import logo from "../../assets/img/logos/logo.png";
// import { useForm } from "react-hook-form";
// import axios from "axios";
// import toast from "react-hot-toast";
// import { IoEye, IoEyeOff } from "react-icons/io5";
// import loginBg from "../../assets/signInBg.jpg";

// const SignIn = () => {
//   let navigate = useNavigate();
//   const { register, handleSubmit } = useForm();
//   const [load, setLoad] = useState(false);
//   const [passType, setPassType] = useState(true);

//   const login = (data) => {
//     let myData = {
//       email: data.email,
//       password: data.password,
//       userType: "admin",
//     };
//     setLoad(true);

//     let config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: "https://archita-motors.onrender.com/archita-motors-api/users/user-login",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: myData,
//     };

//     axios
//       .request(config)
//       .then((response) => {
//         localStorage.setItem("ArchitaMotors-authToken", "khjjkhfdskhkjhkd");
//         localStorage.setItem("Archita-Users-Data", JSON.stringify(response.data.user));

//         setLoad(false);
//         navigate("/admin/dashboard");
//         toast.success(response?.data?.message);
//       })
//       .catch((error) => {
//         setLoad(false);
//         toast.error(error?.response?.data?.error);
//       });
//   };

//   const d = new Date();
//   let year = d.getFullYear();

//   return (
//     <React.Fragment>
//       <section
//         style={{
//           backgroundImage: `url(${loginBg})`,
//         }}
//         className="flex h-screen items-center justify-center bg-cover bg-center bg-no-repeat"
//       >
//         {/* Sign-In Form Section */}
//         <div className="flex h-auto w-[90%] items-center justify-center rounded-xl bg-white px-6 pb-4 sm:h-[70%] sm:w-[80%] md:h-auto md:w-2/3 lg:w-1/2 xl:w-1/3">
//           <div className="h-auto w-full">
//             <div className="mt-4 flex items-center justify-center">
//               <img src={logo} alt="logo" className="h-16 w-auto sm:h-20" />
//             </div>
//             <h1 className="mt-4 text-lg font-bold leading-tight tracking-wide text-[#070619] sm:text-xl md:text-2xl">
//               Log In to the CRM
//             </h1>

//             {/* Form */}
//             <form
//               onSubmit={handleSubmit(login)}
//               className="mt-6 space-y-4"
//             >
//               <div>
//                 <label className="font-medium">Email</label> <br />
//                 <input
//                   type="tel"
//                   name="email"
//                   id="email"
//                   {...register("email")}
//                   placeholder="Enter Your Email"
//                   className="mt-2 w-full rounded-lg border bg-[#f5f2f2] px-4 py-3 focus:border-[#640708] focus:bg-white focus:outline-none"
//                   autoFocus
//                   autoComplete="email"
//                   required
//                 />
//               </div>

//               <div className="relative mt-4">
//                 <label className="font-medium">Password</label> <br />
//                 <input
//                   type={!passType ? "text" : "password"}
//                   name="password"
//                   id="password"
//                   {...register("password")}
//                   placeholder="Enter Your Password"
//                   className="mt-2 w-full rounded-lg border bg-[#f5f2f2] px-4 py-3 focus:border-[#640708] focus:bg-white focus:outline-none"
//                   required
//                 />
//                 {passType ? (
//                   <IoEyeOff
//                     onClick={() => {
//                       setPassType(false);
//                     }}
//                     size="20"
//                     className="absolute right-2 top-[2.8rem] cursor-pointer"
//                   />
//                 ) : (
//                   <IoEye
//                     onClick={() => {
//                       setPassType(true);
//                     }}
//                     size="20"
//                     className="absolute right-2 top-[2.8rem] cursor-pointer"
//                   />
//                 )}
//               </div>

//               <button
//                 type="submit"
//                 className="flex w-full items-center justify-center rounded-lg bg-[#640708] px-4 py-3 font-semibold tracking-wide text-white hover:bg-[#070619]"
//               >
//                 {load ? <div className="loaderButton"></div> : "Log In"}
//               </button>
//             </form>

//             <div className="my-4 text-center">
//                 <div
//                   onClick={() => {
//                     navigate("/auth/forgot-password");
//                   }}
//                   className="cursor-pointer text-sm font-semibold tracking-wide text-[#070619] hover:text-[#640708]"
//                 >
//                   Forgot Password?
//                 </div>
//               </div>

//             <p className="tracking-wider text-center text-sm text-gray-500">
//               &copy; {year} AM CRM - All Rights Reserved.
//             </p>
//           </div>
//         </div>
//       </section>
//     </React.Fragment>
//   );
// };

// export default SignIn;
