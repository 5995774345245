import React, { useRef } from "react";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import architaLogo from "../../assets/img/logos/logo.png";
import toast from "react-hot-toast";
const InShowRoomBikeDoc = ({ setLoadInvoice, loadInvoice, filterBikeData }) => {
  const bikes = [...filterBikeData];
  const pdfRef = useRef();

  const handleGeneratePDF = async () => {
    // Select the invoice element
    toast("Downloading Please wait...", { duration: 4000 });
    const invoiceElement = document.getElementById("invoice");

    // Temporarily remove scroll and size limitations to capture all content
    invoiceElement.style.maxHeight = "none";
    invoiceElement.style.overflow = "visible";

    const canvas = await html2canvas(invoiceElement, {
      scale: 2, // Higher scale for better resolution
      useCORS: true,
      allowTaint: true,
      scrollX: 0,
      scrollY: -window.scrollY, // To capture full content
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4", // A4 size for invoices
    });

    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // If the content exceeds one page, add page breaks
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pdf.internal.pageSize.getHeight();

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();
    }

    pdf.save("inshowroom-bikes-invoice.pdf");

    // Restore original styles
    invoiceElement.style.maxHeight = "25rem";
    invoiceElement.style.overflow = "auto";
  };
  const todayDate = new Date().toISOString().slice(0, 10);

  return (
    <div className="fixed inset-0 z-50 flex  items-center justify-center bg-[#00000075] ">
      <div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
              table { width: 100%; border-collapse: collapse;font-size:18px;}
              th, td { padding: 10px; border: 1px solid #ddd; text-align: left; }
              th { background-color: #f4f4f4; }
              tbody tr:nth-child(even) { background-color: #f9f9f9; }
              h1 { color: #4CAF50; font-family: Arial, sans-serif; }

              /* Add this CSS to handle page breaks for printing */
              @media print {
                table { page-break-inside: auto; }
                tr { page-break-inside: avoid; page-break-after: auto; }
                thead { display: table-header-group; }
                tfoot { display: table-footer-group; }
              }
            `,
          }}
        />
      </div>
      <div className=" mx-6   rounded-lg bg-white p-5">
        <div
          ref={pdfRef}
          id="invoice"
          className="max-h-[25rem] overflow-auto p-6"
        >
          <div className="flex justify-between">
            <div className=" text-lg tracking-wide">
              <img
                src={architaLogo}
                alt="No Image"
                className="h-[5rem] w-[6rem]"
              />
              <p>Archita Motors Pvt Ltd</p>
              <p>Plo No 523,Bank Of Baroda Side,</p>
              <p> Bhubaneswar, Samantarapur,</p>
              <p>751002</p>
            </div>
            <div className="text-end tracking-wide">
              <h1 className="text-3xl font-bold tracking-wide text-[black]">
                INVOICE
              </h1>
              <h1 className="text-sm text-[black]">Date:{todayDate}</h1>
            </div>
          </div>
          <h1 className="my-5 text-center text-2xl font-medium tracking-wide text-[black] ">
            Bikes available in showroom details
          </h1>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginBottom: "20px",
            }}
          >
            <thead>
              <tr className="bg-gray-500">
                <th className="">Model Name</th>
                <th className="">Year</th>
                <th className="">Color</th>
                <th className="">Fuel</th>
                <th className="">Emission</th>
                <th className="">Asking Price</th>
                <th className="">KMs</th>
                <th className="">Status</th>
              </tr>
            </thead>
            <tbody>
              {bikes.map((bike, index) => (
                <tr key={index}>
                  <td>{bike?.primaryDetails?.makeAndModel}</td>
                  <td>{bike?.primaryDetails?.yearOfManufacture}</td>
                  <td>{bike?.primaryDetails?.color}</td>
                  <td>{bike?.primaryDetails?.fuelType}</td>
                  <td>{bike?.primaryDetails?.emissionType}</td>
                  <td>{bike?.primaryDetails?.secondSellingPrice}</td>
                  <td>{bike?.primaryDetails?.odometerReading}</td>
                  <td>{bike?.primaryDetails?.bikeStatus}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="px-6">
          <button
            onClick={handleGeneratePDF}
            className="rounded-md bg-red-900 p-2 text-sm tracking-wide text-white "
          >
            Generate Invoice
          </button>
          <button
            onClick={() => {
              setLoadInvoice(!loadInvoice);
            }}
            className=" mx-3 rounded-md bg-red-500 p-2 text-sm tracking-wide text-white "
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default InShowRoomBikeDoc;
