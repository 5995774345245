import React, { useState } from "react";

const Branchdelete = ({ onClose, onDelete,buttonloader }) => {
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-[black] bg-opacity-50">
        <div className="relative w-[90%] rounded-lg bg-white p-6 shadow-lg md:w-[60%] lg:w-[45%] xl:w-[42%]">
          <h2 className="text-lg font-semibold text-center">Confirm Deletion</h2>
          <p className="mt-4 text-center">
            Are you sure you want to delete  
          </p>
          <div className="mt-6 flex justify-center gap-4">
            <button
              onClick={onDelete}
              className="px-6 py-2 bg-[#640708] font-medium text-white rounded-md"
            >
                {buttonloader?(
                    <div className="loaderButton "></div>
                ):" Yes, Delete"

                }
             
            </button>
            <button
              onClick={onClose}
              className="px-6 py-2 bg-gray-200 font-medium text-gray-700 rounded-md"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Branchdelete;
