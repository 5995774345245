import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  bikeStatusModal: false,
  currentBikedata: {},
};

const architaMotorsSilceOne = createSlice({
  name: "architaMotors",
  initialState,
  reducers: {
    updateBikeStatusModal: (state, action) => {
      state.bikeStatusModal = action.payload;
    },
    updateCurrentBikeData: (state, action) => {
      state.currentBikedata = action.payload;
    },
  },
});

export const { updateBikeStatusModal, updateCurrentBikeData } =
  architaMotorsSilceOne.actions;
export default architaMotorsSilceOne.reducer;
