import React, { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BikeStatusModal } from "Components/BikeStatusModal/BikeStatusModal";

const BikeList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [loadmodal, setLoadModal] = useState(false);
  const [currentBike, setCurrentBike] = useState("");
  const [refresh, setRefresh] = useState(true);

  const getBikeData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://archita-motors.onrender.com/archita-motors-api/bikes/get-all-bikes"
      );
      const showroomBikes = response.data.filter(
        (bike) => bike.primaryDetails.bikeStatus === "Unsold"
      );
      setData(showroomBikes);
    } catch (error) {
      console.error("Error fetching bike data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBikeData();
  }, [refresh]);

  useEffect(() => {
    const filtered = data.filter((bike) =>
      bike.primaryDetails.makeAndModel
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchQuery, data]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentItems = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const deleteData = async (id) => {
    if (window.confirm("Are you sure you want to delete this bike?")) {
      try {
        await axios.delete(
          `https://archita-motors.onrender.com/archita-motors-api/bikes/delete-bike/${id}`
        );
        getBikeData();
      } catch (error) {
        console.error("Error deleting bike:", error);
      }
    }
  };

  return (
    <div className="relative">
      <div className="mt-6 text-start text-xl font-extrabold tracking-wide text-[#640708] md:text-2xl">
        All Bikes List (Purchased from Owners)
      </div>

      <div className="relative my-4 w-full rounded-lg bg-white md:w-[40%]">
        <input
          type="search"
          placeholder="Search..."
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          className="w-full rounded-lg border border-gray-300 py-2 pl-10 pr-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <IoSearchOutline
          size={20}
          className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-500"
          aria-hidden="true"
        />
      </div>

      {loadmodal ? (
        <BikeStatusModal
          setLoadModal={setLoadModal}
          currentBike={currentBike}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : (
        ""
      )}

      <div
        id="bikelist"
        className="container mx-auto overflow-x-auto rounded-lg shadow-lg"
      >
        <div className="inline-block min-w-full rounded-lg">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                {[
                  "Model Name",
                  "Year",
                  "Color",
                  "Fuel",
                  "Emission",
                  "Purchased Price",
                  "KMs",
                  "Status",
                ].map((header) => (
                  <th
                    key={header}
                    className="border-b-2 border-[#640708] bg-[#640708] px-5 py-3 text-left text-xs font-bold uppercase tracking-wider text-white"
                    style={{
                      minWidth: header === "Model Name" ? "190px" : "auto",
                    }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            {!isLoading ? (
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((item) => (
                    <tr key={item._id} className="hover:bg-gray-100">
                      <td className="flex items-center border-b border-gray-200 bg-white py-5 text-sm">
                        <img
                          src={item.primaryDetails.bikeImages[0]}
                          onClick={() =>
                            navigate(
                              `/admin/purchased-bikes-from-owners/bike-details/${item._id}`
                            )
                          }
                          alt="No-Img"
                          className="m-2 h-12 w-12 cursor-pointer rounded-full border-[1px] border-gray-300"
                        />
                        <div>
                          <p className="text-md font-semibold text-gray-900">
                            {item.primaryDetails.makeAndModel}
                          </p>
                          <p className="mt-1 text-sm text-gray-500">
                            {item.primaryDetails.registrationNumber}
                          </p>
                        </div>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          {item.primaryDetails.yearOfManufacture}
                        </p>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          {item.primaryDetails.color}
                        </p>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          {item.primaryDetails.fuelType}
                        </p>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          {item.primaryDetails.emissionType}
                        </p>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          Rs.{item.primaryDetails.purchasedPrice}
                        </p>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          {item.primaryDetails.odometerReading} KM
                        </p>
                      </td>
                      <td
                        onClick={() => {
                          setLoadModal(true);
                          setCurrentBike(item);
                        }}
                        className="cursor-pointer border-b border-gray-200 bg-white px-5 py-5 text-sm"
                      >
                        <p className="rounded-md bg-[#eeeb2b] px-2 py-1 text-center font-semibold text-gray-900">
                          {item.primaryDetails.bikeStatus}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={8}
                      className="border-b border-gray-200 bg-white px-5 py-5 text-center text-sm text-gray-900"
                    >
                      No results found
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              Array.from({ length: 5 }).map((_, index) => (
                <tr key={index} className="animate-pulse">
                  <td className="w-1/12 whitespace-nowrap px-6 py-4">
                    <div className="flex items-center space-x-3">
                      <div className="h-12 w-12 rounded-full bg-gray-300" />
                      <div className="flex-1 space-y-4">
                        <div className="h-4 w-3/4 rounded bg-gray-300" />
                        <div className="h-4 w-3/4 rounded bg-gray-300" />
                      </div>
                    </div>
                  </td>
                  {Array.from({ length: 7 }).map((_, index) => (
                    <td
                      key={index}
                      className="w-1/12 whitespace-nowrap px-6 py-4"
                    >
                      <div className="h-6 w-3/4 rounded bg-gray-300" />
                    </td>
                  ))}
                </tr>
              ))
            )}
          </table>
        </div>
      </div>

      <div className="mt-6 flex justify-center md:justify-end">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`mx-1 rounded px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-200 ${
            currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          Previous
        </button>

        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`mx-1 rounded-md border px-3 py-2 text-sm ${
              currentPage === index + 1
                ? "bg-blue-500 text-white"
                : "text-gray-800 hover:bg-gray-200"
            }`}
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`mx-1 rounded px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-200 ${
            currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BikeList;
