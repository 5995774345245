import React from "react";
import bikeBanner from "../../assets/img/bike-banner.png";
const WelcomeAdmin = () => {
 
  return (
    <div
      style={{ backgroundImage: `url(${bikeBanner})` }}
      className="mb-4 mt-8 flex h-[15rem] flex-col items-center gap-2 rounded-2xl bg-gradient-to-r  from-[#ec2025] to-[#2d3583] bg-cover  bg-center bg-no-repeat px-2  py-5 pt-3 sm:h-[21rem] sm:px-12 sm:pt-8 "
    >
      <h1
        style={{ fontFamily: 'cursive' }}
        className=" text-center text-lg font-semibold tracking-wide text-white sm:text-3xl md:text-4xl">
        Hii.. Welcome Back.🏍️
      </h1>
      <div className=" mt-2 text-white  ">
        <p className="text-center text-md md:text-lg text-[#33eefc] tracking-wide ">
          Your great effort and positive attitude brighten our office every
          day.You are the unsung hero behind every successful team. Your
          organization and dedication keep the wheels turning smoothly."
        </p>
      </div>
    </div>
  );
};

export default WelcomeAdmin;
