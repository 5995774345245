import React, { useEffect, useState } from "react";
import axios from "axios";
import ProfileEdit from "./ProfileEdit";
import ProfileEditBranch from "./ProfileEditBranch";
import profileImg from "../../assets/img/avatar-design.png";
const UserProfile = () => {
  const localUserData = JSON.parse(localStorage.getItem("Archita-Users-Data"));
  const [uData, setUData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [editProgfileOpen, setEditProfileOpen] = useState(false);

  useEffect(() => {
    getUsersData();
  }, []);

  const getUsersData = () => {
    let resquestUrl;
    if (localUserData?.role == "admin" || localUserData?.role == "staff") {
      resquestUrl = `https://archita-motors.onrender.com/archita-motors-api/users/user-by-id/${localUserData?._id}`;
    } else {
      resquestUrl = `https://archita-motors.onrender.com/archita-motors-api/branch/get-branche-by-id/${localUserData?._id}`;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: resquestUrl,
      headers: {},
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        setUData(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  console.log("------uData------>", uData);

  return (
    <div className="mx-auto mt-6 rounded-lg bg-white p-6 shadow-lg">
      {isLoading ? (
        <div className="animate-pulse">
          <div className="flex flex-col items-center justify-between border-b border-gray-200 pb-6 md:flex-row">
            <div className="flex flex-col items-center  space-x-6 md:flex-row">
              <div className="h-24 w-24 rounded-full bg-gray-300"></div>
              <div>
                <div className="mb-2 h-8 w-48 rounded bg-gray-300"></div>
                <div className="mb-2 h-6 w-36  rounded bg-gray-300 text-center md:text-start"></div>
                <div className="h-6 w-24 rounded  bg-gray-300 text-center md:text-start"></div>
              </div>
            </div>
            <div className="mt-4 h-10 w-32 rounded bg-gray-300 md:mt-0"></div>
          </div>
          <div className="py-6">
            <div className="mb-4 h-6 w-32 rounded bg-gray-300"></div>
            <div className="mb-2 h-4 rounded bg-gray-300"></div>
            <div className="mb-2 h-4 rounded bg-gray-300"></div>
            <div className="mb-2 h-4 rounded bg-gray-300"></div>
            <div className="h-4 rounded bg-gray-300"></div>
          </div>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {Array(6)
              .fill()
              .map((_, i) => (
                <div key={i} className="rounded-lg bg-gray-100 p-4 shadow-sm">
                  <div className="mb-2 h-4 w-20 rounded bg-gray-300"></div>
                  <div className="h-4 w-36 rounded bg-gray-300"></div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center justify-between border-b border-gray-200 pb-6 md:flex-row">
            <div className="flex flex-col items-center   space-x-6 md:flex-row">
              <img
                src={uData?.profilePic || profileImg}
                alt="Profile"
                className="h-24 w-24 rounded-full border-4 border-[#640708] object-cover shadow-lg"
              />
              <div>
                <h2 className="text-3xl font-bold capitalize tracking-wider text-[#070619]">
                  {uData?.userName}
                </h2>
                <p className="text-md text-center tracking-wide text-gray-600 md:text-start">
                  {uData?.email}
                </p>
                <p className="mt-1 text-center capitalize tracking-wide text-[#640708] md:text-start">
                  - {uData?.role || "Branch Admin"} -
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                setEditProfileOpen(true);
              }}
              className="mt-4 rounded-lg bg-gradient-to-r from-[#da4548] to-[#640708] px-6 py-3 text-sm font-semibold tracking-wider text-white transition duration-300 ease-in-out md:mt-0"
            >
              Edit Profile
            </button>
          </div>

          <div className="py-6">
            <h3 className="text-xl font-semibold tracking-wide text-[#070619]">
              About
            </h3>
            <p className="mt-2 text-justify leading-relaxed tracking-wide text-gray-700">
              Help customers find their perfect pre-owned bike with your
              expertise and passion. You'll provide top-notch service, showcase
              bikes in their best light, and assist with sales and inventory. If
              you're enthusiastic about motorcycles and enjoy interacting with
              people, this role is for you!
            </p>
          </div>

          <div className="grid grid-cols-1 gap-6 tracking-wide sm:grid-cols-2">
            <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
              <h4 className="text-sm font-medium text-[#070619]">Email</h4>
              <p className="mt-1 text-gray-700">{uData?.email}</p>
            </div>
            <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
              <h4 className="text-sm font-medium text-[#070619]">Phone</h4>
              <p className="mt-1 text-gray-700">{uData?.phone}</p>
            </div>

            {localUserData?.role === "admin" && (
              <>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">Gender</h4>
                  <p className="mt-1 text-gray-700">{uData?.gender}</p>
                </div>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">
                    Date Of Birth
                  </h4>
                  <p className="mt-1 text-gray-700">
                    {uData?.dateOfBirth?.substring(0, 10)}
                  </p>
                </div>
              </>
            )}
            {localUserData?.role === "staff" && (
              <>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">Gender</h4>
                  <p className="mt-1 text-gray-700">{uData?.gender}</p>
                </div>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">
                    Date Of Birth
                  </h4>
                  <p className="mt-1 text-gray-700">
                    {uData?.dateOfBirth?.substring(0, 10)}
                  </p>
                </div>
              </>
            )}

            {localUserData.role == "admin" && (
              <>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">
                    Nationality
                  </h4>
                  <p className="mt-1 text-gray-700">{uData?.nationality}</p>
                </div>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">
                    Address
                  </h4>
                  <p className="mt-1 text-gray-700">{uData?.address}</p>
                </div>
              </>
            )}
            {localUserData.role == "staff" && (
              <>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">
                    Nationality
                  </h4>
                  <p className="mt-1 text-gray-700">{uData?.nationality}</p>
                </div>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">
                    Address
                  </h4>
                  <p className="mt-1 text-gray-700">{uData?.address}</p>
                </div>
              </>
            )}

            {localUserData.role === "admin" ||
            localUserData.role === "staff" ? (
              ""
            ) : (
              <>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">Branch</h4>
                  <p className="mt-1 text-gray-700">{uData?.name}</p>
                </div>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">City</h4>
                  <p className="mt-1 text-gray-700">{uData?.city}</p>
                </div>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">
                    Pincode
                  </h4>
                  <p className="mt-1 text-gray-700">{uData?.pin}</p>
                </div>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">State</h4>
                  <p className="mt-1 text-gray-700">{uData?.state}</p>
                </div>
                <div className="rounded-lg bg-[#f1e0e1] p-4 shadow-sm">
                  <h4 className="text-sm font-medium text-[#070619]">
                    Address
                  </h4>
                  <p className="mt-1 text-gray-700">{uData?.fullAddress}</p>
                </div>
              </>
            )}
          </div>
        </>
      )}
      {editProgfileOpen &&
        (localUserData.role === "admin" || localUserData.role === "staff" ? (
          <ProfileEdit
            id={uData._id}
            closemodal={() => setEditProfileOpen(false)}
            usersDetail={getUsersData}
          />
        ) : (
          <ProfileEditBranch
            id={uData._id}
            closemodal={() => setEditProfileOpen(false)}
            usersDetail={getUsersData}
          />
        ))}
    </div>
  );
};

export default UserProfile;
