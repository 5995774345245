import AuthCheck from "Components/Common/AuthCheck/AuthCheck";
import React, { useState } from "react";

export const InitialRenderPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <React.Fragment>
      {isLoading && (
        <AuthCheck setIsLoading={setIsLoading} layoutType="initialRender" />
      )}
    </React.Fragment>
  );
};
