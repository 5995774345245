import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import { useState } from "react";
import AuthCheck from "Components/Common/AuthCheck/AuthCheck";

const Auth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <AuthCheck setIsLoading={setIsLoading} layoutType="auth" />
      ) : (
        <div className="relative float-right h-full min-h-screen w-full !bg-white">
          <main className={`mx-auto min-h-screen`}>
            <div className="relative flex">
              <div className="  min-h-full w-full lg:h-screen xl:h-[100vh] ">
                <div className="">
                  <Routes>
                    {getRoutes(routes)}
                    <Route
                      path="/"
                      element={<Navigate to="/auth/sign-in" replace />}
                    />
                    <Route path="*" element={<Navigate to="/404" />} />
                  </Routes>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
};
export default Auth;
