import React, { useEffect, useState } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { RiEditFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import Branchdelete from "./branches/Branchdelete";
import toast from "react-hot-toast";
import EditStaff from "./EditStaff";
import { MdEditDocument } from "react-icons/md";

const StaffDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [usersData, setUsersData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [deletePopup, setDeletepopup] = useState(false);
  const [deletebuttonLoader, setDeleteBUttonLoader] = useState(false);
  const [editmodal, seteditmodal] = useState(false);

  useEffect(() => {
    usersDetail();
  }, []);
  const onclose = () => {
    setDeletepopup(false);
  };
  const usersDetail = async () => {
    try {
      const response = await axios.get(
        `https://archita-motors.onrender.com/archita-motors-api/users/user-by-id/${params?.id}`
      );
      setUsersData(response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const deleteUser = async () => {
    setDeleteBUttonLoader(true);
    try {
      const response = await axios.delete(
        `https://archita-motors.onrender.com/archita-motors-api/users/delete-user/${params?.id}`
      );
      toast.success("Staff delete succesfully");
      setDeleteBUttonLoader(false);
      navigate("/admin/all-staffs");
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteBUttonLoader(false);
    }
  };

  return (
    <React.Fragment>
      <div className="mb-4 mt-6 flex items-center px-4">
        <MdOutlineArrowBackIos
          onClick={() => navigate(-1)}
          className="cursor-pointer"
          size={22}
          color="#070619"
        />
        <div className="ml-2 text-xl font-extrabold tracking-wide text-[#640708] md:text-2xl">
          Staff Details
        </div>
      </div>

      {isLoading ? (
        <div className="w-full space-y-6 ">
          {/* Profile Skeleton */}
          <div className="flex items-center space-x-6 rounded-lg bg-white p-6 shadow-lg">
            <div className="h-28 w-28 animate-pulse rounded-full bg-gray-300"></div>
            <div className="ml-6 space-y-4">
              <div className="h-6 w-52 animate-pulse rounded bg-gray-300"></div>
              <div className="h-5 w-40 animate-pulse rounded bg-gray-300"></div>
            </div>
          </div>

          {/* Details Skeleton */}
          <div className="rounded-lg bg-white p-6 shadow-lg">
            <h2 className="mb-4 text-xl font-bold tracking-wide text-[#640708]">
              Personal Details
            </h2>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
            </div>
          </div>

          {/* Permissions Skeleton */}
          <div className="rounded-lg bg-white p-6 shadow-lg">
            <h2 className="mb-4 text-xl font-bold tracking-wide text-[#640708]">
              Permissions
            </h2>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
              <div className="h-6 w-full animate-pulse rounded bg-gray-300"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full space-y-6">
          {/* Profile Card */}
          <div className="flex items-center rounded-lg bg-white p-6 shadow-lg">
            <img
              className="md:h-28 md:w-28 w-20 h-20 rounded-full border-4 border-[#640708] object-cover"
              src={usersData?.profilePic}
              alt="img"
            />
            <div className="ml-6">
              <h1 className="text-xl font-bold tracking-wider capitalize  text-[#070619] md:text-[1.7rem]">
                {usersData?.userName}
              </h1>
              <p className="text-xl font-semibold capitalize tracking-wide text-gray-500">
                {usersData?.role}
              </p>
              <div className="flex gap-4">
                <MdEditDocument
                  onClick={() => { seteditmodal(true) }}
                  className="text-[1.6rem] text-green-600" />
                <MdDelete
                  onClick={() => {
                    setDeletepopup(true);
                  }}
                  className="text-[1.6rem] text-red-600"
                />
              </div>
            </div>
          </div>

          {/* Details Card */}
          <div className="rounded-lg bg-white p-6 shadow-lg">
            <h2 className="mb-4 text-xl font-bold tracking-wide text-[#640708]">
              Personal Details
            </h2>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="text-navy-600">
                <span className="font-semibold tracking-wide">Phone:</span>{" "}
                {usersData?.phone}
              </div>
              <div className="tracking-wide text-navy-600">
                <span className="font-semibold">Email:</span> {usersData?.email}
              </div>
              <div className="tracking-wide text-navy-600">
                <span className="font-semibold">Date of Birth:</span>{" "}
                {new Date(usersData?.dateOfBirth).toLocaleDateString()}
              </div>
              <div className="tracking-wide text-navy-600">
                <span className="font-semibold">Gender:</span>{" "}
                {usersData?.gender}
              </div>
              <div className="tracking-wide text-navy-600">
                <span className="font-semibold">Address:</span>{" "}
                {usersData?.address}
              </div>
              <div className="tracking-wide text-navy-600">
                <span className="font-semibold">Nationality:</span>{" "}
                {usersData?.nationality}
              </div>
            </div>
          </div>

          {/* Permissions Card */}
          <div className="rounded-lg bg-white p-6 shadow-lg">
            <h2 className="mb-4 text-xl font-bold tracking-wide text-[#640708]">
              Permissions
            </h2>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              {Object.keys(usersData?.permissions).map((permission, index) => (
                <div key={index} className="flex items-center">
                  {usersData?.permissions[permission] ? (
                    <FaCheckCircle className="mr-2 text-green-500" />
                  ) : (
                    <FaTimesCircle className="mr-2 text-red-500" />
                  )}
                  <span className="capitalize tracking-wide text-navy-600">
                    {permission.replace(/([A-Z])/g, " $1")}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {deletePopup && (
        <Branchdelete
          onClose={onclose}
          onDelete={deleteUser}
          buttonloader={deletebuttonLoader}
        />
      )}
      {editmodal && (
        <EditStaff
          id={params?.id}
          closemodal={() => seteditmodal(false)}
          usersDetail={usersDetail}
        />
      )

      }
    </React.Fragment>
  );
};

export default StaffDetails;
