import React, { useEffect, useState } from "react";
import showroom from "../../../assets/img/layout/showroom.png";
import { IoSearchOutline } from "react-icons/io5";
import Createbranch from "./createbranch";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Banch = () => {
  const [openmodal, setopenmodal] = useState(false);
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const openingmodal = () => {
    setopenmodal(true);
  };
  const closemodal = () => {
    setopenmodal(false);
  };

  const [branchData, setBranchData] = useState([]);

  const getBranchData = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://archita-motors.onrender.com/archita-motors-api/branch/all-branches",
      headers: {},
      data: data,
    };
    setloader(true);
    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setBranchData(response.data);
        setloader(false);
      })
      .catch((error) => {
        console.log(error);
        setloader(false);
      });
  };
  useEffect(() => {
    getBranchData();
  }, []);

  const [filterdata, setFilterdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handelsearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    setFilterdata(branchData);
  }, []);

  useEffect(() => {
    const filterData = branchData.filter((item) =>
      item?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilterdata(filterData);
  }, [searchQuery, branchData]);
  console.log(filterdata);

  return (
    <>
      <div className="mt-6 text-xl font-extrabold tracking-wide text-[#640708] md:text-2xl">
        Branch List
      </div>
      <div className="items-center  justify-between md:flex">
        <div className="relative my-4 w-full rounded-lg border border-none bg-white md:w-[40%]">
          <input
            type="search"
            placeholder="Search..."
            onChange={handelsearch}
            value={searchQuery}
            className="w-full rounded-lg border border-blue-400 py-2 pl-10 pr-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 md:py-2.5"
          />
          <IoSearchOutline
            size={20}
            className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-500"
            aria-hidden="true"
          />
        </div>
        <button
          onClick={openingmodal}
          className="rounded-xl bg-[#640708] px-5 py-2.5 font-semibold tracking-normal text-white"
        >
          {" "}
          Add Branch
        </button>
      </div>
      {filterdata.length <= 0 && branchData.length > 0 ? (
        <div className="mt-8 h-[50%] w-full md:mt-20">
          <h2 className="text-center text-[1.5rem] text-gray-700">
            There was no branch on this name
          </h2>
        </div>
      ) : (
        <div className="mt-2.5 grid w-full grid-cols-1 gap-5 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
          {loader
            ? Array(6)
                .fill()
                .map((_, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center rounded-lg bg-white p-4 shadow-md"
                  >
                    <div className="mb-4 h-32 w-32 animate-pulse bg-gray-200"></div>
                    <div className="mb-2 h-6 w-44 animate-pulse rounded-md bg-gray-200"></div>
                    <div className="h-6 w-24 animate-pulse rounded-md bg-gray-200"></div>
                  </div>
                ))
            : filterdata?.map((e, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center rounded-lg bg-white p-4 shadow-md"
                >
                  <div className="flex h-28 w-auto items-center justify-center overflow-hidden  ">
                    <img
                      src={showroom}
                      alt="Branch Icon"
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <h2 className="mt-4 max-w-full overflow-hidden text-ellipsis text-center text-[1.2rem] font-semibold capitalize tracking-normal text-gray-800">
                    {e?.name} Branch
                  </h2>
                  <button
                    onClick={() =>
                      navigate(`/admin/branches/branch-details/${e._id}`)
                    }
                    className="mt-2 cursor-pointer rounded-xl bg-[#640708] px-6 py-2 text-[.9rem] font-medium tracking-wide text-white"
                  >
                    Overview
                  </button>
                </div>
              ))}
        </div>
      )}
      <Createbranch
        open={openmodal}
        close={closemodal}
        getBranchData={getBranchData}
      />
    </>
  );
};

export default Banch;
