import React from "react";
import { IoCaretForwardOutline } from "react-icons/io5";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { IoMdAddCircle } from "react-icons/io";
import { useRef } from "react";
import toast from "react-hot-toast";
import { IoCloseSharp } from "react-icons/io5";

export const AddNewBike = () => {
  //Cloudinary Upload Presets
  const cloudName = "dnw1ttr02";
  const upload_preset = "wjs1zyo5";
  const myInputField = useRef();
  const myElement = myInputField.current;
  const { register, reset, handleSubmit } = useForm();

  const [load, setLoad] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [secondFormErrors, setSecondFormErrors] = useState({});
  const [thirdFormErrors, setThirdFormErrors] = useState({});
  const [fourthFormErrors, setFourthFormErrors] = useState({});
  const [bikeFiles, setBikeFiles] = useState([]);
  const [arrOfImages, setArrOfImages] = useState([]);

  const [PrimaryDetails, setPrimaryDetails] = useState({});
  const [condition, setCondition] = useState({});
  const [documnetation, setDocumentation] = useState({});
  // const [ownerDetails, setOwnerDetails] = useState({});

  console.log("--------PrimaryDetails-------->", PrimaryDetails);
  // console.log("--------condition--------->", condition);
  console.log("---------documnetation--------->", documnetation);
  // console.log("-----------ownerDetails--------->", ownerDetails);

  // console.log(bikeFiles);
  // console.log(arrOfImages);

  const [count, setCount] = useState(1);
  const [move, setMove] = useState(true);
  const [moveTwo, setMoveTwo] = useState(true);
  const [moveThree, setMoveThree] = useState(true);
  console.log(count);

  // const increaseCount = () => {
  //   if (count < 4) {
  //     console.log("I am running");
  //     setCount(count + 1);
  //   }
  // };
  console.log(arrOfImages);
  const handleFileChange = () => {
    // document.getElementById("bikeImg").Reset();
    // console.log(myElement);
    // myElement.reset();
    if (arrOfImages?.length < 4) {
      setArrOfImages([...arrOfImages, ...bikeFiles]);
      setBikeFiles([]);
    } else {
      toast.error("Maximum 4 images can be added .");
    }
  };

  const removeImg = (imgName, indexOfItem) => {
    const filterImg = arrOfImages.filter((eachItem, index) => {
      return index != indexOfItem;
    });

    setArrOfImages(filterImg);
  };

  //This block of code id for form 1 primery details
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    const formFields = [
      "makeAndModel",
      "yearOfManufacture",
      "bikeWeight",
      "bikeMilage",
      "bikeAge",
      "registrationNumber",
      "engineNumber",
      "engineDisplay",
      "chassisNumber",
      "odometerReading",
      "color",
      "fuelType",
      "transmissionType",
      "wheelsType",
      "groundClearance",
      "emissionType",
      "abs",
      "tyreType",
      "insuranceDetailsPolicyNumber",
      "insuranceDetailsValidity",
      "originalPurchaseDate",
      "bikeImages",
      // "secondSellingPrice",
      // "bikeStatus",
    ];

    formFields.forEach((field) => {
      const element = document.getElementsByName(field)[0];
      if (!element || !element.value) {
        formIsValid = false;
        errors[field] = "This field is required";
      }
    });

    setFormErrors(errors);
    return formIsValid;
  };

  const handleFirstFormSubmit = async (data) => {
    // console.log(data);
    // const myData = data.bikeImages;
    // console.log(myData[0]);
    setPrimaryDetails(data);
    // console.log(Number(PrimaryDetails?.engineDisplay));
    const insurance = {
      policyNumber: data.insuranceDetailsPolicyNumber,
      validity: data.insuranceDetailsValidity,
    };

    if (handleValidation()) {
      if (arrOfImages?.length != 4) {
        toast.error("Please select 4 images, in order to submit.");
        return;
      }

      const myData = data.bikeImages;
      // console.log(myData[0]);

      const newArr = [];
      arrOfImages?.forEach((eachfile) => {
        const file = eachfile;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", upload_preset);
        formData.append("public_id", `ArchitaMotors/${eachfile.name}`);
        axios
          .post(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            formData
          )
          .then((response) => {
            // console.log(response?.data?.secure_url);
            newArr.push(response?.data?.secure_url);
            console.log(newArr);
          })
          .catch((error) => {
            console.log(error);
          });
      });

      setPrimaryDetails((prev) => {
        return {
          ...prev,
          bikeImages: newArr,
          // engineDisplay:Number(PrimaryDetails?.engineDisplay),
          insuranceDetails: insurance,
        };
      });

      setMove(false);
      console.log("Form is valid, proceed further.");
    } else {
      console.log("Form has errors.");
    }
  };
  //This block of code is for form 1 primery details
  // ------------------------------------------------------------------------------------

  // This block of code is for form two

  const handleSecondValidation = () => {
    let errors = {};
    let formIsValid = true;

    const formFields = [
      "currentCondition",
      "accidentOrDamages",
      "ModificationsOrAccessories",
      "tyreCondition",
      "batteryConditions",
      "brakeCondition",
      "chainCondition",
    ];

    formFields.forEach((field) => {
      const element = document.getElementsByName(field)[0];
      if (!element || !element.value) {
        formIsValid = false;
        errors[field] = "This field is required";
      }
    });

    setSecondFormErrors(errors);
    return formIsValid;
  };

  const handleSecondFormSubmit = (data) => {
    if (handleSecondValidation()) {
      setCondition(data);
      setMoveTwo(false);
      console.log("Form is valid, proceed further.");
    } else {
      console.log("Form has errors.");
    }
  };
  // This block of code is for form two
  // --------------------------------------------------------------------------

  //This block of code is for form three
  const handleThirdValidation = () => {
    let errors = {};
    let formIsValid = true;

    const formFields = [
      "registrationCertificate",
      "insuranceCertificate",
      "pucCertificate",
      "serviceBook",
      "ownersManual",
    ];

    formFields.forEach((field) => {
      const element = document.getElementsByName(field)[0];
      if (!element || !element.value) {
        formIsValid = false;
        errors[field] = "This field is required";
      }
    });

    setThirdFormErrors(errors);
    return formIsValid;
  };

  const handleThirdFormSubmit = (data) => {
    if (handleThirdValidation()) {
      // Proceed with form submission or next steps
      // increaseCount();

      const docImgData = {
        registrationCertificate: data?.registrationCertificate,
        insuranceCertificate: data?.insuranceCertificate,
        pucCertificate: data?.pucCertificate,
        serviceBook: data?.serviceBook,
        ownersManual: data?.ownersManual,
      };
      // console.log(docImgData?.registrationCertificate[0]);

      const documentImgs = {};

      for (let key in docImgData) {
        const file = docImgData[key][0];
        console.log(file);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", upload_preset);
        formData.append(
          "public_id",
          `ArchitaMotors/${docImgData[key][0].name}`
        );
        axios
          .post(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            formData
          )
          .then((response) => {
            // newArr.push(response?.data?.secure_url);
            // console.log(newArr);
            documentImgs[key] = response?.data?.secure_url;
            setDocumentation(documentImgs);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      setMoveThree(false);
      console.log("Form is valid, proceed further.");
    } else {
      console.log("Form has errors.");
    }
  };
  //This block of code is for form three
  // ------------------------------------------------------------------------------------------

  // This block of code is for form 4

  const handleFouthValidation = () => {
    let errors = {};
    let formIsValid = true;

    const formFields = [
      "fullName",
      "postalPin",
      "address",
      "age",
      "gender",
      "dob",
      "ownerImage",
      "nationality",
      "contactNumber",
      "emailAddress",
      "idProofType",
      "idProofNumber",
    ];

    formFields.forEach((field) => {
      const element = document.getElementsByName(field)[0];
      if (!element || !element.value) {
        formIsValid = false;
        errors[field] = "This field is required";
      }
    });

    setFourthFormErrors(errors);
    return formIsValid;
  };

  const handleFouthFormSubmit = async (data) => {
    if (!handleFouthValidation()) {
      // setOwnerDetails(data);
      console.log("Form has errors.");
      return;
    }

    setLoad(true);

    let ownerImageUrl;
    const file = data.ownerImage[0];
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", upload_preset);
    formData.append("public_id", `ArchitaMotors/${file.name}`);
    await axios
      .post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formData
      )
      .then((response) => {
        // newArr.push(response?.data?.secure_url);
        // console.log(newArr);
        ownerImageUrl = response?.data?.secure_url;
        console.log(ownerImageUrl);
      })
      .catch((error) => {
        console.log(error);
      });

    console.log(ownerImageUrl);
    const wholeBikeData = {
      primaryDetails: {
        ...PrimaryDetails,
        engineDisplay: Number(PrimaryDetails?.engineDisplay),
        secondSellingPrice: 0,
        bikeStatus: "Unsold",
        maintainanceFee: 0,
      },
      condition: {
        currentCondition: condition?.currentCondition,
        anyAccidentsOrDamages:
          condition?.accidentOrDamages == "Yes" ? "true" : "false",
        modificationsOrAccessories: condition?.ModificationsOrAccessories,
        tyreCondition: condition?.tyreCondition,
        batteryCondition: condition?.batteryConditions,
        brakeCondition: condition?.brakeCondition,
        chainCondition: condition?.chainCondition,
      },
      documentation: {
        registrationCertificate: documnetation?.registrationCertificate,
        insurancePapers: documnetation?.insuranceCertificate,
        pucCertificate: documnetation?.pucCertificate,
        serviceBook: documnetation?.serviceBook,
        ownersManual: documnetation?.ownersManual,
      },
      ownerDetails: {
        fullName: data?.fullName,
        address: data?.address,
        postalPin: data?.postalPin,
        age: data?.age,
        gender: data?.gender,
        dob: data?.dob,
        ownerImage: ownerImageUrl,
        nationality: data?.nationality,
        contactNumber: data?.contactNumber,
        emailAddress: data?.emailAddress,
        idProofType: data?.idProofType,
        idProofNumber: data?.idProofNumber,
      },
    };

    console.log(wholeBikeData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://archita-motors.onrender.com/archita-motors-api/bikes/add-new-bike",
      headers: {},
      data: wholeBikeData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        toast.success("Bike added successfully");
        document.getElementById("ownerDetailsReset").reset();
        setLoad(false);
        // reset();
        setArrOfImages([]);

        setTimeout(() => {
          setMove(true);
          setMoveTwo(true);
          setMoveThree(true);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
        toast.error("Couldn't add bike data: Something went wrong !");
        setTimeout(() => {
          setMove(true);
          setMoveTwo(true);
          setMoveThree(true);
        }, 1000);
        // reset();
      });

    reset();
  };

  return (
    <>
      <h1 className="my-4 mt-6 text-xl font-extrabold tracking-wide text-[#640708] md:text-2xl">
        Add A New Bike
      </h1>
      <div className=" w-full rounded-md bg-white p-4">
        {/* <h1 className="mb-6 mt-4 text-center text-xl font-extrabold tracking-wide text-[#640708] md:text-2xl">
          Add A New Bike
        </h1> */}
        <div className="my-5 flex flex-wrap items-center gap-1">
          <h1
            className={`font-semibold tracking-wide ${
              move ? "text-[#640708] " : "text-[#640708]"
            }`}
          >
            PrimaryDetails
          </h1>
          <IoCaretForwardOutline className="text-gray-600" />
          <h1
            className={`font-semibold tracking-wide ${
              move ? "text-gray-600" : "text-[#640708]"
            }`}
          >
            Condition
          </h1>
          <IoCaretForwardOutline className="text-gray-600" />
          <h1
            className={`font-semibold tracking-wide ${
              moveTwo ? "text-gray-600" : "text-[#640708]"
            }`}
          >
            Documentation
          </h1>
          <IoCaretForwardOutline className="text-gray-600" />
          <h1
            className={`font-semibold tracking-wide ${
              moveThree ? "text-gray-600" : "text-[#640708]"
            }`}
          >
            OwnerDetails
          </h1>
        </div>

        {move ? (
          <div>
            <h1 className="mb-3 mt-2 text-lg font-bold tracking-wide text-gray-700">
              Primary Details
            </h1>
            {/* //form one */}
            <form
              onSubmit={handleSubmit(handleFirstFormSubmit)}
              className="flex flex-col space-y-6"
            >
              <div>
                {/* <h2 className="mb-4 text-lg font-semibold text-blue-600">
              Primary Details
            </h2> */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.makeAndModel
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Make And Model
                    </label>
                    <input
                      type="text"
                      placeholder="Make a modal"
                      name="makeAndModel"
                      {...register("makeAndModel")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.makeAndModel
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.yearOfManufacture
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Year of manufacture
                    </label>
                    <input
                      type="text"
                      placeholder="Year of manufacture"
                      name="yearOfManufacture"
                      pattern="[0-9]{4}"
                      {...register("yearOfManufacture")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500
                    
                      ${
                        formErrors.yearOfManufacture
                          ? "border-red-600"
                          : "border-blue-300"
                      }
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.bikeWeight ? "text-red-600" : "text-[black]"
                      }`}
                    >
                      Bike Weight(In Kg)
                    </label>
                    <input
                      type="text"
                      placeholder="Enter bike weight"
                      name="bikeWeight"
                      // pattern="[0-9]"
                      {...register("bikeWeight")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500
                    
                      ${
                        formErrors.bikeWeight
                          ? "border-red-600"
                          : "border-blue-300"
                      }
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.bikeMilage ? "text-red-600" : "text-[black]"
                      }`}
                    >
                      Bike Milage(Kms/Litre)
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Bike Milage"
                      name="bikeMilage"
                      // pattern="[0-9]"
                      {...register("bikeMilage")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500
                    
                      ${
                        formErrors.bikeMilage
                          ? "border-red-600"
                          : "border-blue-300"
                      }
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.bikeAge ? "text-red-600" : "text-[black]"
                      }`}
                    >
                      Bike age
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Bike age, 2 digit number"
                      name="bikeAge"
                      {...register("bikeAge")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500
                    
                        ${
                          formErrors.bikeAge
                            ? "border-red-600"
                            : "border-blue-300"
                        }
                    
                    
                    `}
                    />
                  </div>

                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.bikeAge ? "text-red-600" : "text-[black]"
                      }`}
                    >
                      Bike Purchsed Price
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Purchased Price Of Bike"
                      name="purchasedPrice"
                      {...register("purchasedPrice")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500
                    
                        ${
                          formErrors.bikeAge
                            ? "border-red-600"
                            : "border-blue-300"
                        }
                    
                    
                    `}
                    />
                  </div>

                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.registrationNumber
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Registration Number
                    </label>
                    <input
                      type="text"
                      placeholder="Registration Number"
                      name="registrationNumber"
                      {...register("registrationNumber")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500
                    
                        ${
                          formErrors.registrationNumber
                            ? "border-red-600"
                            : "border-blue-300"
                        }
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.engineNumber
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Engine Number
                    </label>
                    <input
                      type="text"
                      placeholder="Engine Number"
                      name="engineNumber"
                      {...register("engineNumber")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                     ${
                       formErrors.engineNumber
                         ? "border-red-600"
                         : "border-blue-300"
                     }
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold ${
                        formErrors.engineDisplay
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Engine display(In CC)
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Engine CC"
                      name="engineDisplay"
                      {...register("engineDisplay")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.engineDisplay
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold ${
                        formErrors.chassisNumber
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Chassis number
                    </label>
                    <input
                      type="text"
                      placeholder="Chassis number"
                      name="chassisNumber"
                      {...register("chassisNumber")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.chassisNumber
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold ${
                        formErrors.odometerReading
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Odometer reading
                    </label>
                    <input
                      type="text"
                      placeholder="Odm reading"
                      name="odometerReading"
                      {...register("odometerReading")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.odometerReading
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold ${
                        formErrors.color ? "text-red-600" : "text-[black]"
                      }`}
                    >
                      Color
                    </label>
                    <input
                      type="text"
                      placeholder="Color"
                      name="color"
                      {...register("color")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${formErrors.color ? "border-red-600" : "border-blue-300"}
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.fuelType ? "text-red-600" : "text-[black]"
                      }`}
                    >
                      Fuel type
                    </label>
                    <select
                      name="fuelType"
                      {...register("fuelType")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.fuelType ? "border-red-600" : "border-blue-300"
                    }
                    
                    `}
                    >
                      <option value="Petrol">Petrol</option>
                      <option value="Diesel">Diesel</option>
                      <option value="Electric">Electric</option>
                    </select>
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.transmissionType
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Transmission type
                    </label>
                    <select
                      name="transmissionType"
                      {...register("transmissionType")}
                      className={`mt-1 block w-full  rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.transmissionType
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    >
                      <option value="Manual">Manual</option>
                      <option value="Automatic">Automatic</option>
                    </select>
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.wheelsType ? "text-red-600" : "text-[black]"
                      }`}
                    >
                      Wheels type
                    </label>
                    {/* <input
                      type="text"
                      placeholder="Wheels type"
                      name="wheelsType"
                      {...register("wheelsType")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.wheelsType
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    /> */}
                    <select
                      name="wheelsType"
                      {...register("wheelsType")}
                      className={`mt-1 block w-full  rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.wheelsType
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    >
                      <option value="Spoke">Spoke</option>
                      <option value="Alloy">Alloy</option>
                      <option value="Carbon Fiber">Carbon Fiber</option>
                    </select>
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.groundClearance
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Ground clearance
                    </label>
                    <input
                      type="text"
                      placeholder="Ground clearance"
                      name="groundClearance"
                      {...register("groundClearance")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.groundClearance
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.emissionType
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Emmision type
                    </label>
                    {/* <input
                      type="text"
                      placeholder="Emmision type"
                      name="emissionType"
                      {...register("emissionType")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.emissionType
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    /> */}
                    <select
                      placeholder="Emmision type"
                      name="emissionType"
                      {...register("emissionType")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.emissionType
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    >
                      <option value="BS3">BS3</option>
                      <option value="BS4">BS4</option>
                      <option value="BS6">BS6</option>
                    </select>
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.abs ? "text-red-600" : "text-[black]"
                      }`}
                    >
                      ABS
                    </label>
                    <select
                      name="abs"
                      {...register("abs")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${formErrors.abs ? "border-red-600" : "border-blue-300"}
                    
                    `}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.tyreType ? "text-red-600" : "text-[black]"
                      }`}
                    >
                      Tyre type
                    </label>
                    {/* <input
                      type="text"
                      placeholder="Tyre type"
                      name="tyreType"
                      {...register("tyreType")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.tyreType ? "border-red-600" : "border-blue-300"
                    }
                    
                    `}
                    /> */}
                    <select
                      name="tyreType"
                      {...register("tyreType")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.tyreType ? "border-red-600" : "border-blue-300"
                    }
                    
                    `}
                    >
                      <option value="Tube">Tube</option>
                      <option value="Tubeless">Tubeless</option>
                      <option value="Radial">Radial</option>
                      <option value="Sports">Sports</option>
                    </select>
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.insuranceDetailsPolicyNumber
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Insurance Policy Number
                    </label>
                    <input
                      type="text"
                      placeholder="Policy Number"
                      name="insuranceDetailsPolicyNumber"
                      {...register("insuranceDetailsPolicyNumber")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.insuranceDetailsPolicyNumber
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide ${
                        formErrors.insuranceDetailsValidity
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Insurance Validity
                    </label>
                    <input
                      type="date"
                      placeholder="Validity"
                      name="insuranceDetailsValidity"
                      {...register("insuranceDetailsValidity")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.insuranceDetailsValidity
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    />
                  </div>
                  <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide${
                        formErrors.originalPurchaseDate
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Original Purchase Date
                    </label>
                    <input
                      type="date"
                      placeholder="Purchase Date"
                      name="originalPurchaseDate"
                      {...register("originalPurchaseDate")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.originalPurchaseDate
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    />
                  </div>
                  <div className="">
                    <div className="flex items-center justify-between gap-2">
                      <div className="w-[95%]">
                        <label
                          className={`text-md mb-2 block font-semibold tracking-wide ${
                            formErrors.bikeImages
                              ? "text-red-600"
                              : "text-[black]"
                          }`}
                        >
                          Bike Images
                        </label>
                        <input
                          id="bikeImg"
                          type="file"
                          // ref={myInputField}
                          placeholder="Image URL"
                          name="bikeImages"
                          filename={bikeFiles[0]?.name}
                          {...register("bikeImages")}
                          onChange={(e) => {
                            let selectedImage = e.target.files[0];
                            setBikeFiles([...bikeFiles, selectedImage]);
                          }}
                          className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.bikeImages
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                        />
                        <p className="mt-1 text-[.85rem] tracking-wider text-gray-700 md:text-[.9rem]">
                          Image format (jpg,jpeng,png,webp,Avif)
                        </p>
                      </div>
                      <IoMdAddCircle
                        onClick={handleFileChange}
                        color="#640708"
                        className="mt-7 cursor-pointer"
                        size={28}
                      />
                    </div>
                    <div className="mt-2 rounded-lg bg-gray-50 px-2 py-2 ">
                      <p className="my-1 font-medium">
                        {" "}
                        Add minimum and maximum of 4 latest bike images.
                      </p>
                      <ul className="flex flex-wrap items-center gap-1">
                        {arrOfImages?.map((each, index) => {
                          return (
                            <li className="flex items-center gap-1 rounded-full bg-[#640708] px-2 py-1 text-sm text-white">
                              {each?.name}
                              <IoCloseSharp
                                onClick={() => {
                                  removeImg(each?.name, index);
                                }}
                                size={20}
                                className="font-semibold"
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  {/* <div>
                    <label
                      className={`text-md mb-2 block font-semibold tracking-wide${
                        formErrors.secondSellingPrice
                          ? "text-red-600"
                          : "text-[black]"
                      }`}
                    >
                      Second Selling Price
                    </label>
                    <input
                      type="text"
                      placeholder="Second Selling Price"
                      name="secondSellingPrice"
                      {...register("secondSellingPrice")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.secondSellingPrice
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    />
                  </div> */}
                  {/* <div>
                    <label
                      className={`text-md mb-2 block font-semibold  tracking-wide ${
                        formErrors.bikeStatus ? "text-red-600" : "text-[black]"
                      }`}
                    >
                      Bike Status
                    </label>
                    <select
                      name="bikeStatus"
                      {...register("bikeStatus")}
                      className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      formErrors.bikeStatus
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                    >
                      <option value="Sold">Sold</option>
                      <option value="Unsold">Unsold</option>
                      <option value="InShowroom">InShowroom</option>
                      
                    </select>
                  </div> */}
                </div>
              </div>
              <div className="flex items-center justify-end">
                <button
                  // onClick={() => {
                  //   handleSubmit(handleFirstFormSubmit());
                  // }}
                  className=" mt-4 h-[2.5rem] w-[6rem] rounded-lg bg-[#640708] font-semibold tracking-wide text-white"
                >
                  Next{" "}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            {moveTwo ? (
              <div>
                <h1 className="mb-3 mt-2 text-lg font-bold tracking-wide text-gray-700">
                  Condition Of Bike
                </h1>
                {/* //form two */}
                <form
                  onSubmit={handleSubmit(handleSecondFormSubmit)}
                  className="space-y-4"
                >
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label
                        className={`text-md mb-2 block font-semibold tracking-wide ${
                          secondFormErrors.currentCondition
                            ? "text-red-600"
                            : "text-[black]"
                        }`}
                      >
                        Current Condition
                      </label>
                      {/* <input
                        type="text"
                        name="currentCondition"
                        {...register("currentCondition")}
                        placeholder="Enter Current Condition Of Bike"
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                          ${
                            secondFormErrors.currentCondition
                              ? "border-red-600"
                              : "border-blue-300"
                          }
                          
                          `}
                      /> */}

                      <select
                        name="currentCondition"
                        {...register("currentCondition")}
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      secondFormErrors.currentCondition
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                      >
                        <option value="Excellent">Excellent</option>
                        <option value="Good">Good</option>
                        <option value="Fair">Fair</option>
                        <option value="Poor">Poor</option>
                      </select>
                    </div>
                    <div>
                      <label
                        className={`text-md mb-2 block font-semibold tracking-wide ${
                          secondFormErrors.accidentOrDamages
                            ? "text-red-600"
                            : "text-[black]"
                        }`}
                      >
                        Any Accidental Damages
                      </label>
                      {/* <input
                        type="text"
                        name="accidentOrDamages"
                        {...register("accidentOrDamages")}
                        placeholder="Accidents Or Damages On the Bike"
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                          ${
                            secondFormErrors.accidentOrDamages
                              ? "border-red-600"
                              : "border-blue-300"
                          }
                          
                          `}
                      /> */}
                      <select
                        name="accidentOrDamages"
                        {...register("accidentOrDamages")}
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                    ${
                      secondFormErrors.accidentOrDamages
                        ? "border-red-600"
                        : "border-blue-300"
                    }
                    
                    `}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div>
                      <label
                        className={`text-md mb-2 block font-semibold tracking-wide${
                          secondFormErrors.ModificationsOrAccessories
                            ? "text-red-600"
                            : "text-[black]"
                        }`}
                      >
                        Modifications or Accessories
                      </label>
                      <input
                        type="text"
                        name="ModificationsOrAccessories"
                        {...register("ModificationsOrAccessories")}
                        placeholder="Modifications Or Accessories"
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                          ${
                            secondFormErrors.ModificationsOrAccessories
                              ? "border-red-600"
                              : "border-blue-300"
                          }
                          
                          `}
                      />
                    </div>
                    <div>
                      <label
                        className={`text-md mb-2 block font-semibold tracking-wide ${
                          secondFormErrors.tyreCondition
                            ? "text-red-600"
                            : "text-[black]"
                        }`}
                      >
                        Tyre Condition
                      </label>
                      {/* <input
                        type="text"
                        name="tyreCondition"
                        {...register("tyreCondition")}
                        placeholder="Tyre Condition"
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                          ${
                            secondFormErrors.tyreCondition
                              ? "border-red-600"
                              : "border-blue-300"
                          }
                          
                          `}
                      /> */}
                      <select
                        name="tyreCondition"
                        {...register("tyreCondition")}
                        id=""
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                          ${
                            secondFormErrors.tyreCondition
                              ? "border-red-600"
                              : "border-blue-300"
                          }
                          
                          `}
                      >
                        <option value="Excellent">Excellent</option>
                        <option value="Good">Good</option>
                        <option value="Fair">Fair</option>
                        <option value="Poor">Poor</option>
                      </select>
                    </div>
                    <div>
                      <label
                        className={`text-md mb-2 block font-semibold tracking-wide ${
                          secondFormErrors.batteryConditions
                            ? "text-red-600"
                            : "text-[black]"
                        }`}
                      >
                        Battery Condition
                      </label>
                      {/* <input
                        type="text"
                        name="batteryConditions"
                        {...register("batteryConditions")}
                        placeholder="Battery Condition of the bike"
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                          ${
                            secondFormErrors.batteryConditions
                              ? "border-red-600"
                              : "border-blue-300"
                          }
                          
                          `}
                      /> */}
                      <select
                        name="batteryConditions"
                        {...register("batteryConditions")}
                        id=""
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                          ${
                            secondFormErrors.batteryConditions
                              ? "border-red-600"
                              : "border-blue-300"
                          }
                          
                          `}
                      >
                        <option value="Excellent">Excellent</option>
                        <option value="Good">Good</option>
                        <option value="Fair">Fair</option>
                        <option value="Poor">Poor</option>
                      </select>
                    </div>
                    <div>
                      <label
                        className={`text-md mb-2 block font-semibold  tracking-wide${
                          secondFormErrors.brakeCondition
                            ? "text-red-600"
                            : "text-[black]"
                        }`}
                      >
                        Brake Condition
                      </label>
                      {/* <input
                        type="text"
                        name="brakeCondition"
                        {...register("brakeCondition")}
                        placeholder="Brake condition of the bike"
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                          ${
                            secondFormErrors.brakeCondition
                              ? "border-red-600"
                              : "border-blue-300"
                          }
                          
                          `}
                      /> */}
                      <select
                        name="brakeCondition"
                        id=""
                        {...register("brakeCondition")}
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                          ${
                            secondFormErrors.brakeCondition
                              ? "border-red-600"
                              : "border-blue-300"
                          }
                          
                          `}
                      >
                        <option value="Excellent">Excellent</option>
                        <option value="Good">Good</option>
                        <option value="Fair">Fair</option>
                        <option value="Poor">Poor</option>
                      </select>
                    </div>
                    <div>
                      <label
                        className={`text-md mb-2 block font-semibold tracking-wide ${
                          secondFormErrors.chainCondition
                            ? "text-red-600"
                            : "text-[black]"
                        }`}
                      >
                        Chain Condition
                      </label>
                      {/* <input
                        type="text"
                        name="chainCondition"
                        {...register("chainCondition")}
                        placeholder="Chain condition of the bike"
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                          ${
                            secondFormErrors.chainCondition
                              ? "border-red-600"
                              : "border-blue-300"
                          }
                          
                          `}
                      /> */}
                      <select
                        name="chainCondition"
                        {...register("chainCondition")}
                        id=""
                        className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                          ${
                            secondFormErrors.chainCondition
                              ? "border-red-600"
                              : "border-blue-300"
                          }
                          
                          `}
                      >
                        <option value="Excellent">Excellent</option>
                        <option value="Good">Good</option>
                        <option value="Fair">Fair</option>
                        <option value="Poor">Poor</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex items-center justify-end">
                    <div
                      onClick={(e) => {
                        setMove(true);
                      }}
                      className=" mr-3 mt-4 flex h-[2.5rem] w-[6rem] cursor-pointer items-center  justify-center rounded-lg bg-[#640708] font-semibold text-white"
                    >
                      Prev{" "}
                    </div>
                    <button
                      // onClick={(e) => {
                      //   handleSecondFormSubmit(e);
                      // }}
                      className=" mt-4 h-[2.5rem]  w-[6rem] rounded-lg bg-[#640708] font-semibold text-white"
                    >
                      Next{" "}
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div>
                {moveThree ? (
                  <div>
                    <h1 className="mb-3 mt-2 text-lg font-bold tracking-wide text-gray-700">
                      Documentation
                    </h1>
                    {/* //form three */}
                    <form
                      onSubmit={handleSubmit(handleThirdFormSubmit)}
                      class="flex flex-col space-y-6"
                    >
                      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide ${
                              thirdFormErrors.registrationCertificate
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Registration Certificate
                          </label>
                          <input
                            type="file"
                            placeholder="Registration Certificate"
                            name="registrationCertificate"
                            {...register("registrationCertificate")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                thirdFormErrors.registrationCertificate
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                          <p className="mt-1 text-[.85rem] tracking-wider text-gray-700 md:text-[.9rem]">
                            Image format (jpg,jpeng,png,webp,Avif)
                          </p>
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide ${
                              thirdFormErrors.insuranceCertificate
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Insurance Certificate
                          </label>
                          <input
                            type="file"
                            placeholder="Insurance Certificate"
                            name="insuranceCertificate"
                            {...register("insuranceCertificate")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                thirdFormErrors.insuranceCertificate
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                          <p className="mt-1 text-[.85rem] tracking-wider text-gray-700 md:text-[.9rem]">
                            Image format (jpg,jpeng,png,webp,Avif)
                          </p>
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide${
                              thirdFormErrors.pucCertificate
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            PUC Certificate
                          </label>
                          <input
                            type="file"
                            placeholder="PUC Certificate"
                            name="pucCertificate"
                            {...register("pucCertificate")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                thirdFormErrors.pucCertificate
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                          <p className="mt-1 text-[.85rem] tracking-wider text-gray-700 md:text-[.9rem]">
                            Image format (jpg,jpeng,png,webp,Avif)
                          </p>
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold ${
                              thirdFormErrors.serviceBook
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Service Book
                          </label>
                          <input
                            type="file"
                            placeholder="Service Book"
                            name="serviceBook"
                            {...register("serviceBook")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                thirdFormErrors.serviceBook
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                          <p className="mt-1 text-[.85rem] tracking-wider text-gray-700 md:text-[.9rem]">
                            Image format (jpg,jpeng,png,webp,Avif)
                          </p>
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide${
                              thirdFormErrors.ownersManual
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Owner's Manual
                          </label>
                          <input
                            type="file"
                            placeholder="Owner's Manual"
                            name="ownersManual"
                            {...register("ownersManual")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                thirdFormErrors.ownersManual
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                          <p className="mt-1 text-[.85rem] tracking-wider text-gray-700 md:text-[.9rem]">
                            Image format (jpg,jpeng,png,webp,Avif)
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-end">
                        <div
                          onClick={(e) => {
                            setMoveTwo(true);
                          }}
                          className=" mr-3 mt-4 flex h-[2.5rem] w-[6rem] cursor-pointer items-center  justify-center rounded-lg bg-[#640708] font-semibold text-white"
                        >
                          Prev{" "}
                        </div>
                        <button
                          // onClick={(e) => {
                          //   // setMoveThree(false);
                          //   handleThirdFormSubmit(e);
                          // }}
                          className=" mt-4 h-[2.5rem] w-[6rem] rounded-lg bg-[#640708] font-semibold text-white"
                        >
                          Next{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div>
                    <h1 className="mb-3 mt-2 text-lg font-bold tracking-wide  text-gray-700">
                      Owner Details
                    </h1>
                    {/* form four */}
                    <form
                      id="ownerDetailsReset"
                      onSubmit={handleSubmit(handleFouthFormSubmit)}
                      className="flex flex-col space-y-6"
                    >
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide ${
                              fourthFormErrors.fullName
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Full Name
                          </label>
                          <input
                            type="text"
                            placeholder="Full Name"
                            name="fullName"
                            {...register("fullName")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.fullName
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide ${
                              fourthFormErrors.address
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Address
                          </label>
                          <input
                            type="text"
                            placeholder="Address"
                            name="address"
                            {...register("address")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.address
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold  tracking-wide${
                              fourthFormErrors.postalPin
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Postal Pin
                          </label>
                          <input
                            type="text"
                            placeholder="Postal Pin"
                            name="postalPin"
                            {...register("postalPin")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.postalPin
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide${
                              fourthFormErrors.age
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Age
                          </label>
                          <input
                            type="text"
                            placeholder="Age"
                            name="age"
                            {...register("age")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.age
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide ${
                              fourthFormErrors.gender
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Gender
                          </label>
                          <select
                            name="gender"
                            {...register("gender")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.gender
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide${
                              fourthFormErrors.dob
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Date of Birth
                          </label>
                          <input
                            type="date"
                            name="dob"
                            {...register("dob")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.dob
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide ${
                              fourthFormErrors.ownerImage
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Owner Image
                          </label>
                          <input
                            type="file"
                            placeholder="Image URL"
                            name="ownerImage"
                            {...register("ownerImage")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.ownerImage
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                          <p className="mt-1 text-[.85rem] tracking-wider text-gray-700 md:text-[.9rem]">
                            Image format (jpg,jpeng,png,webp,Avif)
                          </p>
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold  tracking-wide${
                              fourthFormErrors.nationality
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Nationality
                          </label>
                          <input
                            type="text"
                            placeholder="Nationality"
                            name="nationality"
                            {...register("nationality")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.nationality
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold  tracking-wide${
                              fourthFormErrors.contactNumber
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Contact Number
                          </label>
                          <input
                            type="tel"
                            placeholder="Contact Number"
                            name="contactNumber"
                            pattern="[0-9]{10}"
                            {...register("contactNumber")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.contactNumber
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide${
                              fourthFormErrors.emailAddress
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            Email Address
                          </label>
                          <input
                            type="email"
                            placeholder="Email Address"
                            name="emailAddress"
                            {...register("emailAddress")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.emailAddress
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide ${
                              fourthFormErrors.idProofType
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            ID Proof Type
                          </label>
                          <select
                            name="idProofType"
                            {...register("idProofType")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.idProofType
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          >
                            <option value="">Select ID Proof Type</option>
                            <option value="Aadhar">Aadhar</option>
                            <option value="Passport">Passport</option>
                            <option value="voterid">Voter Card</option>
                            <option value="pan">Pan</option>
                          </select>
                        </div>

                        <div>
                          <label
                            className={`text-md mb-2 block font-semibold tracking-wide ${
                              fourthFormErrors.emailAddress
                                ? "text-red-600"
                                : "text-[black]"
                            }`}
                          >
                            ID Proof Number
                          </label>
                          <input
                            type="text"
                            placeholder="ID Proof Number"
                            name="idProofNumber"
                            {...register("idProofNumber")}
                            className={`mt-1 block w-full rounded-md border border-blue-300 p-2 tracking-wide shadow-sm focus:border-blue-500 focus:ring-blue-500 
                              ${
                                fourthFormErrors.idProofNumber
                                  ? "border-red-600"
                                  : "border-blue-300"
                              }
                              
                              `}
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-end">
                        <div
                          onClick={(e) => {
                            setMoveThree(true);
                          }}
                          className=" mr-3 mt-4 flex h-[2.5rem] w-[6rem] cursor-pointer items-center  justify-center rounded-lg bg-[#640708] font-semibold text-white"
                        >
                          Prev{" "}
                        </div>
                        <button
                          // onClick={(e) => {
                          //   handleFouthFormSubmit(e);
                          // }}
                          className=" mt-4 flex h-[2.5rem] w-[7rem] items-center justify-center rounded-lg bg-[#640708] font-semibold text-white"
                        >
                          {load ? (
                            <div className="loaderButton"></div>
                          ) : (
                            "Submit"
                          )}{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
