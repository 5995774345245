import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";


const CreateStaffModal = ({ open, close,getAllStaff }) => {
  const [errors, setErrors] = useState({});
  const [errorvalue,setErrorvalue]=useState('')
  const [buttonLoader,setButtonloader]=useState(false)
  const [formData, setFormData] = useState({
    userName: "",
    phone: "",
    email: "",
    dateOfBirth: "",
    gender: "",
    address: "",
    profilePic: "",
    nationality: "",
    role: "staff",
    password: ""
  });

  const validateForm = () => {
    const newErrors = {};
    if (!formData.userName) {
      newErrors.userName = true;
    }
    if (!formData.phone) {
      newErrors.phone = true;
    }
    if (!formData.email) {
      newErrors.email = true;
    }
    if (!formData.dateOfBirth) {
      newErrors.dateOfBirth = true;
    }
    if (!formData.gender) {
      newErrors.gender = true;
    }
    if (!formData.address) {
      newErrors.address = true;
    }

  
    if (!formData.nationality) {
      newErrors.nationality = true;
    }
    if (!formData.password) {
      newErrors.password = true;
    }
   
    // else if (formData.pin.length !== 6 || formData.pin <= 0) {
    //   newErrors.pin = true;
    //   alert("Please enter a valid 6-digit PIN.");
    //   return false;
    // }
  
    if (formData.phone && (formData.phone.length !== 10 || formData.phone <= 0)) {
      newErrors.phone = true;
      setErrorvalue("Invalid phone number")
      toast.error("Please enter a valid 10-digit phone number.");
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [imageFile, setImageFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      // setButtonLoading(false);
      return;
    }
    setButtonloader(true)
    let profilePicUrl = "";
    if (imageFile) {
      const cloudName = "dnw1ttr02";
      const upload_preset = "wjs1zyo5";
      const formData = new FormData();
      formData.append("file", imageFile);
      formData.append("upload_preset", upload_preset);
      formData.append("public_id", `ArchitaMotors/${imageFile.name}`);

      try {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          formData
        );
        profilePicUrl = response.data.secure_url;
      } catch (error) {
        console.log(error);
      }
    }

    let data = JSON.stringify({
      userName: formData.userName,
      phone: formData.phone,
      email: formData.email,
      dateOfBirth: formData.dateOfBirth,
      gender: formData.gender,
      address: formData.address,
      profilePic: profilePicUrl || "https://example.com/path/to/robert-profile-pic.jpg",
      nationality: formData.nationality,
      role: "staff",
      password: formData.password
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://archita-motors.onrender.com/archita-motors-api/users/add-user',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast.success("Staff created succesfully")
    setButtonloader(false)
    getAllStaff()
        close()
      })
      .catch((error) => {
        console.log(error);
        setButtonloader(false)
        toast.error("Something went wrong!")
      });
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setImageFile(files[0]);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setErrors({
      ...errors,
      [name]: false,
    });
    setErrorvalue('')
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-[black] bg-opacity-50">
      <div className="relative w-[90%] overflow-y-auto max-h-[90vh] rounded-lg bg-white p-6 shadow-lg md:w-[60%] lg:w-[45%] xl:w-[42%]">
        <RxCross2 className="absolute right-3 top-2 cursor-pointer" onClick={close} />
        <h2 className="mb-6 text-center text-2xl font-semibold tracking-normal text-gray-800">
          Create Staff
        </h2>

        <form onSubmit={handleSubmit}  className="grid grid-cols-1 gap-y-2 md:grid-cols-2 md:gap-x-6 md:gap-y-4">
          <div>
            <label  className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.userName ? "text-red-500" : "text-[#161616]"
              }`}>Name</label>
            <input
              type="text"
              name="userName"
              value={formData.userName}
              onChange={handleChange}
              placeholder="Enter your name"
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.userName ? "border-red-500" : ""
              }`}
            />
          </div>
          <div>
            <label className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.phone ? "text-red-500" : "text-[#161616]"
              }`}>Phone</label>
            <input
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your number"
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.phone ? "border-red-500" : ""
              }`}
            />
          </div>
          <div>
            <label className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.email ? "text-red-500" : "text-[#161616]"
              }`}>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your Email"
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.email ? "border-red-500" : ""
              }`}
            />
          </div>
          <div>
            <label className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.dateOfBirth ? "text-red-500" : "text-[#161616]"
              }`}>Date Of Birth</label>
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              placeholder="Enter your birth date"
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.dateOfBirth ? "border-red-500" : ""
              }`}
            />
          </div>
          <div>
            <label cclassName={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.gender ? "text-red-500" : "text-[#161616]"
              }`}>Gender</label>
            <div  className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.gender ? "border-red-500" : ""
              }`}>
              <input
                type="radio"
                name="gender"
                value="Male"
                onChange={handleChange}
                className="mr-2"
              />
              <label htmlFor="" className="text-gray-700">Male</label>
              <input
                type="radio"
                name="gender"
                value="Female"
                onChange={handleChange}
                className="ml-4 mr-2"
              />
              <label htmlFor="" className="text-gray-700">Female</label>
            </div>
          </div>
          <div>
            <label className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.nationality ? "text-red-500" : "text-[#161616]"
              }`}>Nationality</label>
            <input
              type="text"
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
              placeholder="Enter your nationality"
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.nationality ? "border-red-500" : ""
              }`}
            />
          </div>
          <div>
            <label className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.address ? "text-red-500" : "text-[#161616]"
              }`}>Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Enter your address"
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.address ? "border-red-500" : ""
              }`}
            />
          </div>
          <div>
            <label className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                errors.password ? "text-red-500" : "text-[#161616]"
              }`}>password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter password"
              className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.password ? "border-red-500" : ""
              }`}
            />
          </div>
          <div className="flex-col w-full justify-center md:col-span-2">
            <label className="mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 "
                >Profile Photo</label>
            <input
              type="file"
              name="profilePic"
              onChange={handleChange}
              className="w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 "
        
            />
          </div>
          <h2 className="text-[1rem] text-red-500 text-center md:col-span-2">{errorvalue}</h2>
          <div className="flex w-full justify-center md:col-span-2">
             
            <button
              type="submit"
              className="mt-2 rounded-lg w-[7rem] flex justify-center items-center bg-[#640708] px-8 py-2 text-center text-[.93rem] font-semibold tracking-wide text-white"
            >
           {buttonLoader ?(
            <div className="loaderButton"></div>
           ):"Submit"

           }
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateStaffModal;
