import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoSearchSharp } from "react-icons/io5";
import SoldBikeInvoice from "Components/Doc/SoldBikeInvoice";
import { IoMdDownload } from "react-icons/io";

const BranchSoldBikesList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bikeList, setBikeList] = useState([]);
  const [filteredBikeList, setFilteredBikeList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentBikeData, setCurrentBikeData] = useState({});
  const [loadSoldBike, setLoadSoldBike] = useState(false);
  const user = JSON.parse(localStorage.getItem("Archita-Users-Data"));
  const getBikesListFromBranch = () => {
    setIsLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://archita-motors.onrender.com/archita-motors-api/bikes/get-bikes-by-branch/${user._id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("i am branch bike list--------->", response.data);

        setBikeList(
          response.data.filter((each, index) => {
            return each.primaryDetails.bikeStatus === "Sold";
          })
        );
        setFilteredBikeList(
          response.data.filter((each, index) => {
            return each.primaryDetails.bikeStatus === "Sold";
          })
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredBikes = bikeList.filter((bike) => {
      const {
        makeAndModel,
        yearOfManufacture,
        color,
        fuelType,
        emissionType,
        secondSellingPrice,
        odometerReading,
        bikeStatus,
      } = bike.primaryDetails;

      return (
        makeAndModel.toLowerCase().includes(query) ||
        yearOfManufacture.toString().includes(query) ||
        color.toLowerCase().includes(query) ||
        fuelType.toLowerCase().includes(query) ||
        emissionType.toLowerCase().includes(query) ||
        secondSellingPrice.toString().includes(query) ||
        odometerReading.toString().includes(query) ||
        bikeStatus.toLowerCase().includes(query)
      );
    });

    setFilteredBikeList(filteredBikes);
  };

  //Pagination......
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(filteredBikeList.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentItems = filteredBikeList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    getBikesListFromBranch();
  }, []);

  return (
    <div className="mx-auto mt-8">
      {loadSoldBike ? (
        <SoldBikeInvoice
          currentSoldBike={currentBikeData}
          loadSoldBike={setLoadSoldBike}
          setLoadSoldBike={setLoadSoldBike}
        />
      ) : (
        ""
      )}
      <div className="relative mb-4 w-full max-w-xs">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          className="w-full rounded-lg border border-gray-300 py-2 pl-2  pr-10 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <div className="absolute inset-y-0 right-2 flex items-center pl-3">
          <i className="fas fa-search text-gray-500"></i>{" "}
          <IoSearchSharp size={25} color="#640708" />
        </div>
      </div>
      <div
        id="bikelist"
        className="container mx-auto mt-8 overflow-x-auto rounded-lg shadow-lg"
      >
        {/* Search Input */}

        <div className="inline-block min-w-full rounded-lg">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                {[
                  "Model Name",
                  "Year",
                  "Color",
                  "Fuel",
                  "Emission",
                  "Sold Price",
                  "KMs",
                  "Status",
                  "Invoice",
                ].map((header) => (
                  <th
                    key={header}
                    className="border-b-2 border-[#640708] bg-[#640708] px-5 py-3 text-left text-center text-xs font-bold uppercase tracking-wider text-white"
                    style={{
                      minWidth: header === "Model Name" ? "190px" : "auto",
                    }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            {!isLoading ? (
              <tbody>
                {currentItems?.length === 0 ? (
                  <tr>
                    <td
                      colSpan="8"
                      className="py-5 text-center font-medium tracking-wide"
                    >
                      No Data Available !
                    </td>
                  </tr>
                ) : (
                  currentItems?.map((each, index) => (
                    <tr key={each._id} className="hover:bg-gray-100">
                      <td className="flex items-center border-b border-gray-200 bg-white py-5 text-sm">
                        <img
                          src={each?.primaryDetails?.bikeImages[0]}
                          alt="No-Img"
                          className="m-2 h-12 w-12 rounded-full border-[1px] border-gray-300"
                        />
                        <div>
                          <p className="text-md font-semibold text-gray-900">
                            {each?.primaryDetails?.makeAndModel}
                          </p>
                        </div>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          {each?.primaryDetails?.yearOfManufacture}
                        </p>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          {each?.primaryDetails?.color}
                        </p>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          {each?.primaryDetails?.fuelType}
                        </p>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          {each?.primaryDetails?.emissionType}
                        </p>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          Rs. {each?.primaryDetails?.secondSellingPrice}
                        </p>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="text-gray-900">
                          {each?.primaryDetails?.odometerReading} KMs
                        </p>
                      </td>

                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <p className="mx-2 cursor-pointer rounded-full bg-green-200 px-2 py-1 text-center font-semibold text-green-900 hover:bg-green-300">
                          Sold
                        </p>
                      </td>
                      <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                        <button
                          onClick={() => {
                            setLoadSoldBike(true);
                            setCurrentBikeData(each);
                          }}
                          className="clear-right my-3 flex gap-2 rounded-md bg-white p-2 text-sm font-medium tracking-wide tracking-wide text-[black] shadow-lg "
                        >
                          <IoMdDownload
                            size={20}
                            color="green"
                            className="animate-pulse "
                          />
                          Invoice
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            ) : (
              Array.from({ length: 6 }).map((_, index) => (
                <tr key={index} className="animate-pulse">
                  <td className="w-1/12 whitespace-nowrap px-6 py-4">
                    <div className="flex items-center space-x-3">
                      <div className="h-12 w-12 rounded-full bg-gray-300" />
                      <div className="flex-1 space-y-4">
                        <div className="h-4 w-3/4 rounded bg-gray-300" />
                        <div className="h-4 w-3/4 rounded bg-gray-300" />
                      </div>
                    </div>
                  </td>
                  {Array.from({ length: 8 }).map((_, index) => (
                    <td
                      key={index}
                      className="w-1/12 whitespace-nowrap px-6 py-4"
                    >
                      <div className="h-6 w-3/4 rounded bg-gray-300" />
                    </td>
                  ))}
                </tr>
              ))
            )}
          </table>
        </div>
      </div>
      <div className="mt-6 flex justify-center md:justify-end">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`mx-1 rounded px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-200 ${
            currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          Previous
        </button>

        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`mx-1 rounded-md border px-3 py-2 text-sm ${
              currentPage === index + 1
                ? "bg-blue-500 text-white"
                : "text-gray-800 hover:bg-gray-200"
            }`}
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`mx-1 rounded px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-200 ${
            currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BranchSoldBikesList;
