import React, { useState } from "react";
import { HiX } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import routes from "../../routes";
import logo from "../../assets/img/logos/logo.png";
import "./Sidebar.css";

const AdminSidebar = ({ open, onClose }) => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const permissions = JSON.parse(
    localStorage.getItem("Archita-Users-Data")
  )?.permissions;

  const hasPermission = (routeName) => {
    switch (routeName) {
      case "Dashboard":
        return permissions.dashboard;
      case "Branches":
        return permissions.branch;
      case "All Staffs":
        return permissions.staff;
      case "Raw Bikes":
        return permissions.rawBikes;
      case "Bikes Inquiries":
        return permissions.inquiries;
      case "Bikes In Showroom":
        return permissions.bikesInShowroom;
      case "Sold Bikes":
        return permissions.soldBikes;
      case "Ledger":
        return permissions.ledger;
      default:
        return false;
    }
  };

  const filteredRoutes = routes.filter(
    (route) => route.showInSidebar && hasPermission(route.name)
  );

  const toggleDropdown = (name) => {
    setOpenDropdown((prev) => (prev === name ? null : name));
  };
  return (
    <div
      className={`fixed left-0 top-0 z-50 h-full w-72 transform rounded-r-xl bg-white py-4 pr-4 font-medium tracking-wide text-gray-900 shadow-lg transition-transform ${
        open ? "translate-x-0" : "-translate-x-full"
      } xl:translate-x-0`}
    >
      <div className="flex items-center justify-between gap-4 rounded-full bg-white px-4">
        <div className="flex w-full items-center justify-center">
          <img src={logo} alt="logo" />
        </div>
        <button
          className="text-gray-300 hover:text-white focus:outline-none xl:hidden"
          onClick={onClose}
        >
          <HiX className="h-6 w-6 text-navy-700" />
        </button>
      </div>

      <nav className="hide-scrollbar mt-4 flex h-[calc(100%-120px)] flex-col space-y-2 overflow-y-auto">
        {filteredRoutes.map((route, index) => (
          <div key={index} className="w-full">
            {!route.children ? (
              <NavLink
                to={`${route.layout}/${route.path}`}
                className={({ isActive }) =>
                  `flex items-center rounded-r-2xl px-6 py-3 text-lg tracking-wide transition-all duration-200 ${
                    isActive
                      ? "bg-[#640708] text-white"
                      : "text-[#640708] hover:bg-[#302f42] hover:text-white"
                  }`
                }
              >
                {route.icon}
                <span className="ml-3">{route.name}</span>
              </NavLink>
            ) : (
              <div>
                <button
                  onClick={() => toggleDropdown(route.name)}
                  className="flex w-full items-center justify-between rounded-r-2xl bg-white px-6 py-3 text-lg tracking-wide text-[#640708] transition-all duration-200 hover:bg-[#302f42] hover:text-white focus:outline-none"
                >
                  <div className="flex items-center">
                    {route.icon}
                    <span className="ml-3">{route.name}</span>
                  </div>
                  <svg
                    className={`h-4 w-4 transform transition-transform ${
                      openDropdown === route.name ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                <div
                  className="ml-6 mt-2 overflow-hidden"
                  style={{
                    maxHeight: openDropdown === route.name ? "200px" : "0",
                    transition: "max-height 0.5s ease",
                  }}
                >
                  {route.children.map((child, childIndex) => (
                    <NavLink
                      key={childIndex}
                      to={`${child.layout}/${child.path}`}
                      className={({ isActive }) =>
                        `mb-2 flex items-center rounded-r-2xl px-6 py-3 text-lg tracking-wide transition-all duration-200 ${
                          isActive
                            ? "bg-[#640708] text-white"
                            : "text-[#640708] hover:bg-[#302f42] hover:text-white"
                        }`
                      }
                    >
                      <span className="ml-3">{child.name}</span>
                    </NavLink>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </nav>

      <div className="flex-grow" />
    </div>
  );
};

export default AdminSidebar;
