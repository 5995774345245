import React from "react";
import Chart from "react-apexcharts";

const ChartData = () => {
  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Quaterly Bike Data",
      align: "left",
      style: {
        fontSize: "15px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
    xaxis: {
      categories: ["Quater 1", "Quater 2", "Quater 3", "Quater 4"],
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Bikes",
        style: {
          fontSize: "12px",
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    colors: ["#008FFB", "#00E396"],
  };

  const series = [
    {
      name: "All Bikes",
      data: [200, 180, 190, 210],
    },
    {
      name: "Sold Bikes",
      data: [90, 85, 95, 100],
    },
  ];

  return (
    <div className="p-4">
      <Chart
        options={options}
        series={series}
        type="line"
        height={350}
        className="tracking-wider"
      />
    </div>
  );
};

export default ChartData;
