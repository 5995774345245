import React, { useState, useEffect } from "react";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";

const Editbranch = ({ id, closeModal, getBikeByBranch }) => {
  const [errorvalue, setErrorvalue] = useState("");
  const [skeleLoader, setSkeleloader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
    state: "",
    pin: "",
    fullAddress: "",
  });
  const [errors, setErrors] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setSkeleloader(true);

      try {
        const response = await axios.get(
          `https://archita-motors.onrender.com/archita-motors-api/branch/get-branche-by-id/${id}`
        );
        setFormData({
          name: response.data.name,
          phone: response.data.phone,
          email: response.data.email,
          city: response.data.city,
          state: response.data.state,
          pin: response.data.pin,
          fullAddress: response.data.fullAddress,
        });
      } catch (error) {
        console.error("Error fetching branch data:", error);
      }
      setSkeleloader(false);
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = true;
    }
    if (!formData.phone) {
      newErrors.phone = true;
    }
    if (!formData.email) {
      newErrors.email = true;
    }

    if (!formData.city) {
      newErrors.city = true;
    }
    if (!formData.state) {
      newErrors.state = true;
    }
    if (!formData.pin) {
      newErrors.pin = true;
    }
    // else if (formData.pin.length !== 6 || formData.pin <= 0) {
    //   newErrors.pin = true;
    //   alert("Please enter a valid 6-digit PIN.");
    //   return false;
    // }
    if (!formData.fullAddress) {
      newErrors.fullAddress = true;
    }
    if (
      formData.phone &&
      (formData.phone.length !== 10 || formData.phone <= 0)
    ) {
      newErrors.phone = true;
      setErrorvalue("Invalid phone number");
      toast.error("Please enter a valid 10-digit phone number.");
    }
    if (formData.pin && (formData.pin.length !== 6 || formData.phone <= 0)) {
      newErrors.pin = true;
      setErrorvalue("Invalid pin number");
      toast.error("Please enter a valid 6-digit pin number.");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setButtonLoading(true);

    try {
      await axios.put(
        `https://archita-motors.onrender.com/archita-motors-api/branch/edit-branch/${id}`,
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      toast.success("Branch updated successfully");
      navigate(`/admin/branches/branch-details/${id}`);
      getBikeByBranch();
      closeModal();
    } catch (error) {
      console.error("Error updating branch:", error);
      toast.error("An error occurred, please try again!");
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-[black] bg-opacity-50">
        <div className="relative w-[90%] rounded-lg bg-white p-6 shadow-lg md:w-[60%] lg:w-[45%] xl:w-[42%]">
          <RxCross2
            className="absolute right-3 top-2 cursor-pointer"
            onClick={closeModal}
          />
          <h2 className="mb-6 text-center text-2xl font-semibold tracking-normal text-gray-800">
            Edit Branch
          </h2>
          {!skeleLoader ? (
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-1 gap-y-2 md:grid-cols-2 md:gap-x-6 md:gap-y-4"
            >
              <div>
                <label
                  className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                    errors.name ? "text-red-500" : "text-[#161616]"
                  }`}
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  placeholder="Enter branch name"
                  onChange={handleChange}
                  className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    errors.name ? "border-red-500" : ""
                  }`}
                />
              </div>

              <div>
                <label
                  className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                    errors.phone ? "text-red-500" : "text-[#161616]"
                  }`}
                >
                  Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  placeholder="Enter phone number"
                  onChange={handleChange}
                  className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    errors.phone ? "border-red-500" : ""
                  }`}
                />
              </div>
              <div>
                <label
                  className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                    errors.email ? "text-red-500" : "text-[#161616]"
                  }`}
                >
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  placeholder="Enter email Id"
                  onChange={handleChange}
                  className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    errors.email ? "border-red-500" : ""
                  }`}
                />
              </div>

              <div>
                <label
                  className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                    errors.city ? "text-red-500" : "text-[#161616]"
                  }`}
                >
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  placeholder="Enter city"
                  onChange={handleChange}
                  className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    errors.city ? "border-red-500" : ""
                  }`}
                />
              </div>

              <div>
                <label
                  className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                    errors.state ? "text-red-500" : "text-[#161616]"
                  }`}
                >
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  placeholder="Enter state"
                  onChange={handleChange}
                  className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    errors.state ? "border-red-500" : ""
                  }`}
                />
              </div>

              <div>
                <label
                  className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                    errors.pin ? "text-red-500" : "text-[#161616]"
                  }`}
                >
                  PIN
                </label>
                <input
                  type="text"
                  name="pin"
                  value={formData.pin}
                  placeholder="Enter PIN code"
                  onChange={handleChange}
                  className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    errors.pin ? "border-red-500" : ""
                  }`}
                />
              </div>

              <div>
                <label
                  className={`mb-1 block text-[.9rem] font-semibold tracking-wide md:mb-2 ${
                    errors.fullAddress ? "text-red-500" : "text-[#161616]"
                  }`}
                >
                  Full Address
                </label>
                <input
                  name="fullAddress"
                  type="text"
                  value={formData.fullAddress}
                  placeholder="Enter full address"
                  onChange={handleChange}
                  className={`w-full rounded-lg border px-4 py-2 text-[.9rem] tracking-wide text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    errors.fullAddress ? "border-red-500" : ""
                  }`}
                />
              </div>

              <div className="flex w-full flex-col justify-center md:col-span-2">
                <div className="flex w-full justify-center md:col-span-2">
                  <h2 className="text-center text-[.9rem] text-red-600">
                    {errorvalue}
                  </h2>
                  <h2 className="text-center text-[.9rem] text-red-600">
                    {errors.errorvalue}
                  </h2>
                </div>
                <div className="flex w-full justify-center md:col-span-2">
                  <button
                    type="submit"
                    className="mt-2 flex w-[7rem] items-center justify-center rounded-lg bg-[#640708] px-8 py-2 text-center text-[.93rem] font-semibold tracking-wide text-white"
                    disabled={buttonLoading}
                  >
                    {buttonLoading ? (
                      <div className="loaderButton"></div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <div className="grid animate-pulse grid-cols-1 gap-y-2 md:grid-cols-2 md:gap-x-6 md:gap-y-4 ">
              <div className="mb-4 h-10 w-full rounded bg-gray-300"></div>
              <div className="mb-4 h-10 w-full rounded bg-gray-300"></div>
              <div className="mb-4 h-10 w-full rounded bg-gray-300"></div>
              <div className="mb-4 h-10 w-full rounded bg-gray-300"></div>
              <div className="mb-4 h-10 w-full rounded bg-gray-300"></div>
              <div className="mb-4s h-10 w-full rounded bg-gray-300"></div>
              <div className="flex w-full justify-center md:col-span-2">
                <button className="h-10 w-[6rem] rounded bg-gray-300 text-center "></button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default Editbranch;
