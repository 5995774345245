import React, { useEffect, useState } from "react";

import { MdInsertDriveFile } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { IoIosCloseCircle } from "react-icons/io";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import Branchdelete from "../branches/Branchdelete";
import InqueryPopUp from "Components/Inquary/InqueryPopUp";

const Inquiry = () => {
  const [inquirydata, setinquirydata] = useState(null);

  const getallinquiry = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://archita-motors.onrender.com/archita-motors-api/inquiry/get-all-inquiries",
      headers: {},
      data: data,
    };
    setskeletonloader(true);

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        const filteredInquiry = response?.data?.filter((item) => {
          return item?.inquiryStatus === "open";
        });
        setinquirydata(filteredInquiry);
        setskeletonloader(false);
      })
      .catch((error) => {
        console.log(error);
        setskeletonloader(false);
      });
  };
  useEffect(() => {
    getallinquiry();
  }, []);
  const [open, setOpen] = useState(false);
  const handleOpen = (id) => {
    setopenmodalid(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setopenmodalid("");
    setidselecteddata(null);
  };
  const [openmodalid, setopenmodalid] = useState("");
  const [idselecteddata, setidselecteddata] = useState(null);
  const [skeletonloader, setskeletonloader] = useState(false);
  const [modalskeletonloader, setmodalskeletonloader] = useState(false);

  //Delte Inquiry State
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteButtonLOader, setDeleteButtonLoader] = useState(false);

  //Close Inquiry State
  const [openCloseMOdal, setOpenCloseModal] = useState(false);
  //Resolve Inquiry State
  const [openResolveModal, setOpenResolveModal] = useState(false);

  useEffect(() => {
    if (openmodalid) {
      const getInquiryById = () => {
        setmodalskeletonloader(true);
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `https://archita-motors.onrender.com/archita-motors-api/inquiry/get-inquiry-by-id/${openmodalid}`,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            setidselecteddata(response.data);
            setmodalskeletonloader(false);
          })
          .catch((error) => {
            console.log(error);
            setmodalskeletonloader(false);
          });
      };

      getInquiryById();
    }
  }, [openmodalid]);

  //Delete inquiry function

  const deleteInquiry = () => {
    let data = "";
    setDeleteButtonLoader(true);
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://archita-motors.onrender.com/archita-motors-api/inquiry/delete-inquiry/${openmodalid}`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setDeleteButtonLoader(false);
        setOpenDeleteModal(false);
        toast.success("Inquiry delete succesfully");
        getallinquiry();
      })
      .catch((error) => {
        console.log(error);
        setDeleteButtonLoader(false);
        toast.error("Something went wrong");
        setOpenDeleteModal(false);
      });
  };

  const resolveInquiry = () => {
    let data = JSON.stringify({
      inquiryStatus: "resolve",
    });
    setDeleteButtonLoader(true);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `https://archita-motors.onrender.com/archita-motors-api/inquiry/edit-inquiry/${openmodalid}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        
        setDeleteButtonLoader(false);
        setOpenResolveModal(false)
        getallinquiry();
      })
      .catch((error) => {
        console.log(error);
        setDeleteButtonLoader(false);
        setOpenResolveModal(false)
      });
  };

  const ChangeTOClose = () => {
    let data = JSON.stringify({
      inquiryStatus: "close",
    });
    setDeleteButtonLoader(true);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `https://archita-motors.onrender.com/archita-motors-api/inquiry/edit-inquiry/${openmodalid}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setDeleteButtonLoader(false);
        setOpenCloseModal(false)
        getallinquiry();
      })
      .catch((error) => {
        console.log(error);
        setDeleteButtonLoader(false);
        setOpenCloseModal(false)
      });
  };

  return (
    <>
      <div className="mt-6 text-xl font-extrabold tracking-wide text-[#640708] md:text-2xl">
        All Inquiries
      </div>
      {!skeletonloader ? (
        <div className="mt-4 grid w-full grid-cols-1 gap-7 lg:grid-cols-2">
          {inquirydata?.map((e, index) => (
            <div
              key={index}
              className="flex items-start gap-4 rounded-lg bg-white p-4 duration-150 ease-linear hover:scale-95 hover:bg-gray-50 md:gap-6"
            >
              <div className="flex-shrink-0">
                <img
                  src="https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjkzNy1hZXctMTExXzMuanBn.jpg"
                  alt="Profile"
                  className="h-12 w-12 cursor-pointer rounded-full object-cover md:h-16 md:w-16"
                  onClick={() => {
                    handleOpen(e._id);
                  }}
                />
              </div>
              <div className="flex-grow">
                <h2 className="text-lg font-bold capitalize tracking-wider text-[#070619] md:text-xl">
                  {e?.name}
                </h2>
                <h3 className="text-sm text-gray-600 md:text-base">
                  {e?.createdAt.split("T")[0]}
                </h3>
                <h3 className="text-sm text-gray-600 md:text-base">
                  {e?.message}
                </h3>
              </div>
              <div className="relative flex flex-col space-y-2 ">
                <MdInsertDriveFile
                  onClick={() => {
                    setOpenResolveModal(true);
                    setopenmodalid(e._id);
                  }}
                  className="h-6 w-6 rounded-full bg-green-500 p-1  text-white md:h-6 md:w-6 "
                />
                <IoIosCloseCircle
                  onClick={() => {
                    setOpenCloseModal(true);
                    setopenmodalid(e._id);
                  }}
                  className="h-6 w-6 rounded-full bg-[#070649] p-1 text-white md:h-6 md:w-6"
                />
                <AiFillDelete
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setopenmodalid(e._id);
                  }}
                  className="h-6 w-6 rounded-full bg-[#640708] p-1 text-white md:h-6 md:w-6"
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-4 grid w-full grid-cols-1 gap-7 lg:grid-cols-2">
          {Array.from({ length: 10 }).map((_, index) => (
            <div
              key={index}
              className="flex animate-pulse items-start gap-4 rounded-lg bg-white p-4 duration-150 ease-linear hover:scale-95 hover:bg-gray-50 md:gap-6"
            >
              <div className="flex-shrink-0">
                <div className="h-12 w-12 rounded-full bg-gray-300 md:h-16 md:w-16" />
              </div>
              <div className="flex-grow">
                <div className="mb-2 h-6 w-32 rounded bg-gray-300 md:w-40" />
                <div className="mb-2 h-6 w-32 rounded bg-gray-300 md:w-40" />
                <div className="h-4 w-full rounded bg-gray-300" />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="h-6 w-6 rounded-full bg-gray-300 md:h-7 md:w-7" />
                <div className="h-6 w-6 rounded-full bg-gray-300 md:h-7 md:w-7" />
                <div className="h-6 w-6 rounded-full bg-gray-300 md:h-7 md:w-7" />
              </div>
            </div>
          ))}
        </div>
      )}

      {!modalskeletonloader
        ? open && (
            <div className="fixed left-0 top-0 z-50 flex h-[100vh] w-[100vw] items-center justify-center bg-[black] bg-opacity-75">
              <div className="relative min-h-[15rem] w-[90%] max-w-2xl rounded-lg bg-white p-6 md:w-[50%] lg:w-[40%] xl:w-[30%]">
                <div className="absolute right-3 top-3">
                  <button
                    onClick={handleClose}
                    className="text-gray-600 hover:text-gray-800 "
                  >
                    <RxCross2 size={20} />
                  </button>
                </div>
                <div className="mt-3 flex items-center gap-4">
                  <div className="flex-shrink-0">
                    <img
                      src="https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjkzNy1hZXctMTExXzMuanBn.jpg"
                      alt="Profile"
                      className="h-20 w-20 rounded-full object-cover md:h-24 md:w-24"
                    />
                  </div>
                  <div className="flex-grow ">
                    <h2 className="text-lg font-bold capitalize tracking-wider text-[#0c0b25] md:text-[1.4rem]">
                      {idselecteddata?.name}
                    </h2>
                    <h3 className="mt-1 text-sm font-medium text-gray-600 md:text-[.94rem]">
                      +91 {idselecteddata?.phone}
                    </h3>
                    <h3 className="mt-1 text-sm  font-medium text-gray-600 md:text-[.94rem]">
                      {idselecteddata?.email}
                    </h3>
                  </div>
                  <div></div>
                </div>
                <h3 className="mt-4 text-sm text-gray-600 md:text-base">
                  {idselecteddata?.message}
                </h3>
              </div>
            </div>
          )
        : open && (
            <div className="fixed left-0 top-0 z-50 flex h-[100vh] w-[100vw] items-center justify-center bg-[black] bg-opacity-75">
              <div className="relative min-h-[15rem] w-[90%] max-w-2xl rounded-lg bg-white p-6 md:w-[50%] lg:w-[40%] xl:w-[30%]">
                <div className="absolute right-3 top-3">
                  <button
                    onClick={handleClose}
                    className="text-gray-600 hover:text-gray-800 "
                  >
                    <RxCross2 size={20} />
                  </button>
                </div>
                <div className="mt-3 flex items-center gap-4">
                  <div className="flex-shrink-0">
                    <div className="h-20 w-20 rounded-full animate-pulse bg-gray-300 md:h-24 md:w-24" />
                  </div>
                  <div className="flex-grow">
                    <div className="mb-2 h-6 w-3/4 rounded animate-pulse bg-gray-300 md:w-3/5" />
                    <div className="mb-2 h-4 w-1/2 rounded animate-pulse bg-gray-300 md:w-1/2" />
                    <div className="h-4 w-1/3 rounded animate-pulse bg-gray-300 md:w-1/3" />
                  </div>
                </div>
                <div className="mt-4 h-4 w-full animate-pulse rounded bg-gray-300" />
                <div className="mt-4 h-4 w-full animate-pulse rounded bg-gray-300" />
                <div className="mt-4 h-4 w-full animate-pulse rounded bg-gray-300" />
              </div>
            </div>
          )}
      {openDeleteModal && (
        <Branchdelete
          onClose={() => setOpenDeleteModal(false)}
          onDelete={deleteInquiry}
          buttonloader={deleteButtonLOader}
        />
      )}
      {openCloseMOdal && (
        <InqueryPopUp
          close={() => setOpenCloseModal(false)}
          onchanged={ChangeTOClose}
          buttonLoader={deleteButtonLOader}
          names={"Close"}
        />
      )}
      {
        openResolveModal &&(
          <InqueryPopUp
          close={() => setOpenResolveModal(false)}
          onchanged={resolveInquiry}
          buttonLoader={deleteButtonLOader}
          names={"Resolve"}
        />
        )
      }
    </>
  );
};

export default Inquiry;
