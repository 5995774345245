import React, { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";

const SoldBikeStatusModal = ({
  id,
  open,
  close,
  getBikeData,
  currentbike,
  setCurrentSoldBike,
  loadSoldBike,
  setLoadSoldBike,
}) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [formdata, setformdata] = useState({
    soldPrice: "",
    financeType: "Installment",
    downPayment: "",
    paymentType: "Card",
    purchaserDetails: {
      name: "",
      phone: "",
      email: "",
      gender: "Male",
      adharNo: "",
      address: "",
      pin: "",
    },
  });

  const formValidation = () => {
    const newErrors = {};
    if (!formdata.soldPrice) {
      newErrors.soldPrice = true;
    }
    if (!formdata.financeType) {
      newErrors.financeType = true;
    }
    if (!formdata.downPayment) {
      newErrors.downPayment = true;
    }
    if (!formdata.paymentType) {
      newErrors.paymentType = true;
    }
    if (!formdata.purchaserDetails.name) {
      newErrors.name = true;
    }
    if (!formdata.purchaserDetails.phone) {
      newErrors.phone = true;
    }
    if (!formdata.purchaserDetails.email) {
      newErrors.email = true;
    }
    if (!formdata.purchaserDetails.gender) {
      newErrors.gender = true;
    }
    if (!formdata.purchaserDetails.adharNo) {
      newErrors.adharNo = true;
    }
    if (!formdata.purchaserDetails.address) {
      newErrors.address = true;
    }
    if (!formdata.purchaserDetails.pin) {
      newErrors.pin = true;
    }
    if (formdata.soldPrice && formdata.soldPrice <= 0) {
      newErrors.soldPrice = true;
      toast.error("Price must be greater than 0");
    }
    if (
      formdata.purchaserDetails.phone &&
      (formdata.purchaserDetails.phone.length !== 10 ||
        formdata.purchaserDetails.phone <= 0)
    ) {
      newErrors.phone = true;
      toast.error("Please enter a valid 10-digit phone number.");
    }
    if (
      formdata.purchaserDetails.adharNo &&
      (formdata.purchaserDetails.adharNo.length !== 12 ||
        formdata.purchaserDetails.adharNo <= 0)
    ) {
      newErrors.adharNo = true;
      toast.error("Please enter a valid Adhar number.");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes("purchaserDetails")) {
      const detailKey = name.split(".")[1];
      setformdata((prev) => ({
        ...prev,
        purchaserDetails: {
          ...prev.purchaserDetails,
          [detailKey]: value,
        },
      }));
      setErrors((error) => ({
        ...error,
        [detailKey]: false,
      }));
    } else {
      setformdata((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors({
        ...errors,
        [name]: false,
      });
    }
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    if (!formValidation()) {
      return;
    }
    let data = JSON.stringify(formdata);
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `https://archita-motors.onrender.com/archita-motors-api/bikes/sell-bike/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    setButtonLoader(true);
    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setCurrentSoldBike(response.data);
        setLoadSoldBike(!loadSoldBike);
        toast.success("Successfully updated to sold");
        getBikeData();
        setButtonLoader(false);
        close();
      })
      .catch((error) => {
        console.log(error);
        setButtonLoader(false);
        toast.error("Something went wrong?");
      });
  };

  if (!open) return null;
  return (
    <>
      <div className="fixed inset-0 z-50 flex h-screen w-[100%] items-center justify-center bg-[#00000073] ">
        <div
          id="form2"
          className="relative mt-4  h-[85vh] w-[30rem] overflow-y-auto rounded-lg bg-white p-4 md:h-[70vh]"
        >
          <IoMdCloseCircle
            onClick={close}
            className="absolute right-1 top-1 cursor-pointer"
            size={25}
          />

          <h1 className="mt-4 text-center text-lg font-bold tracking-wide">
            Update Bike Info
          </h1>
          <form onSubmit={handelSubmit}>
            <div className="mt-6 rounded-lg bg-gray-100 p-3">
              <div className="mb-3">
                <h1 className="mb-2 flex justify-between font-semibold tracking-wide">
                  <span>Hello There...</span>
                  <span>{currentbike?.primaryDetails?.makeAndModel}</span>
                </h1>

                <div className="flex items-center gap-3">
                  <p
                    value="Unsold"
                    className={`rounded-full  bg-orange-200 px-5 py-1 font-semibold text-orange-900 hover:bg-orange-300 
               
                `}
                  ></p>
                  <p
                    value="Unsold"
                    className={`rounded-full  bg-green-200 px-5 py-1 font-semibold text-green-900 hover:bg-green-300 
               
                `}
                  ></p>
                </div>
              </div>

              <div className="mt-3">
                <label
                  htmlFor="soldPrice"
                  className={`font-semibold tracking-wide text-[black] ${
                    errors.soldPrice ? "text-red-500" : ""
                  }`}
                >
                  Sold Price:
                </label>
                <br />
                <input
                  type="number"
                  name="soldPrice"
                  value={formdata.soldPrice}
                  onChange={handleInputChange}
                  className={`mt-2 h-[3rem] w-full rounded-md border p-2 tracking-wide ${
                    errors.soldPrice ? "border-red-500" : "border-blue-300"
                  }`}
                />
              </div>

              <div className=" my-3 flex w-full flex-col">
                <label
                  htmlFor="financeType"
                  className={`mb-2 font-semibold tracking-wide ${
                    errors.financeType ? "text-red-500" : ""
                  }`}
                >
                  Finance Type
                </label>
                <select
                  name="financeType"
                  value={formdata.financeType}
                  onChange={handleInputChange}
                  className={`block h-[3rem] w-full rounded-md border px-4 py-2 text-sm tracking-wide shadow-sm ${
                    errors.financeType ? "border-red-500" : "border-blue-300"
                  } focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                >
                  <option value="Installment">Installment</option>
                  <option value="Full Paid">Full Paid</option>
                </select>
              </div>

              <div className=" my-3 flex w-full flex-col">
                <label
                  htmlFor="paymentType"
                  className={`mb-2 font-semibold tracking-wide ${
                    errors.paymentType ? "text-red-500" : ""
                  }`}
                >
                  Payment Type
                </label>
                <select
                  name="paymentType"
                  value={formdata.paymentType}
                  onChange={handleInputChange}
                  className={`block h-[3rem] w-full rounded-md border px-4 py-2 text-sm tracking-wide shadow-sm ${
                    errors.paymentType ? "border-red-500" : "border-blue-300"
                  } focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                >
                  <option value="Card">Card</option>
                  <option value="Cash">Cash</option>
                  <option value="UPI">Upi</option>
                </select>
              </div>

              <div className="mt-3">
                <label
                  htmlFor="downPayment"
                  className={`font-semibold tracking-wide text-[black] ${
                    errors.downPayment ? "text-red-500" : ""
                  }`}
                >
                  Down Payment:
                </label>
                <br />
                <input
                  type="number"
                  name="downPayment"
                  value={formdata.downPayment}
                  onChange={handleInputChange}
                  className={`mt-2 h-[3rem] w-full rounded-md border p-2 tracking-wide ${
                    errors.downPayment ? "border-red-500" : "border-blue-300"
                  }`}
                />
              </div>

              <div className="mt-3">
                <label
                  htmlFor="purchaserDetails.name"
                  className={`font-semibold tracking-wide text-[black] ${
                    errors.name ? "text-red-500" : ""
                  }`}
                >
                  Buyer's Name:
                </label>
                <br />
                <input
                  type="text"
                  name="purchaserDetails.name"
                  value={formdata.purchaserDetails.name}
                  onChange={handleInputChange}
                  className={`mt-2 h-[3rem] w-full rounded-md border p-2 tracking-wide ${
                    errors.name ? "border-red-500" : "border-blue-300"
                  }`}
                />
              </div>

              <div className="mt-3">
                <label
                  htmlFor="purchaserDetails.phone"
                  className={`font-semibold tracking-wide text-[black] ${
                    errors.phone ? "text-red-500" : ""
                  }`}
                >
                  Buyer's Phone:
                </label>
                <br />
                <input
                  type="text"
                  name="purchaserDetails.phone"
                  value={formdata.purchaserDetails.phone}
                  onChange={handleInputChange}
                  className={`mt-2 h-[3rem] w-full rounded-md border p-2 tracking-wide ${
                    errors.phone ? "border-red-500" : "border-blue-300"
                  }`}
                />
              </div>

              <div className="mt-3">
                <label
                  htmlFor="purchaserDetails.email"
                  className={`font-semibold tracking-wide text-[black] ${
                    errors.email ? "text-red-500" : ""
                  }`}
                >
                  Buyer's Email:
                </label>
                <br />
                <input
                  type="email"
                  name="purchaserDetails.email"
                  value={formdata.purchaserDetails.email}
                  onChange={handleInputChange}
                  className={`mt-2 h-[3rem] w-full rounded-md border p-2 tracking-wide ${
                    errors.email ? "border-red-500" : "border-blue-300"
                  }`}
                />
              </div>

              <div className="mt-3">
                <label
                  htmlFor="purchaserDetails.gender"
                  className={`font-semibold tracking-wide text-[black] ${
                    errors.gender ? "text-red-500" : ""
                  }`}
                >
                  Buyer's Gender:
                </label>
                <br />
                <select
                  name="purchaserDetails.gender"
                  value={formdata.purchaserDetails.gender}
                  onChange={handleInputChange}
                  className={`block h-[3rem] w-full rounded-md border px-4 py-2 text-sm tracking-wide shadow-sm ${
                    errors.paymentType ? "border-red-500" : "border-blue-300"
                  } focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {/* <input
                  type="text"
                  name="purchaserDetails.gender"
                  value={formdata.purchaserDetails.gender}
                  onChange={handleInputChange}
                  className={`mt-2 h-[3rem] w-full rounded-md border p-2 tracking-wide ${
                    errors.gender ? "border-red-500" : "border-blue-300"
                  }`}
                /> */}
              </div>

              <div className="mt-3">
                <label
                  htmlFor="purchaserDetails.adharNo"
                  className={`font-semibold tracking-wide text-[black] ${
                    errors.adharNo ? "text-red-500" : ""
                  }`}
                >
                  Buyer's Adhar Number:
                </label>
                <br />
                <input
                  type="text"
                  name="purchaserDetails.adharNo"
                  value={formdata.purchaserDetails.adharNo}
                  onChange={handleInputChange}
                  className={`mt-2 h-[3rem] w-full rounded-md border p-2 tracking-wide ${
                    errors.adharNo ? "border-red-500" : "border-blue-300"
                  }`}
                />
              </div>

              <div className="mt-3">
                <label
                  htmlFor="purchaserDetails.address"
                  className={`font-semibold tracking-wide text-[black] ${
                    errors.address ? "text-red-500" : ""
                  }`}
                >
                  Buyer's Address:
                </label>
                <br />
                <input
                  type="text"
                  name="purchaserDetails.address"
                  value={formdata.purchaserDetails.address}
                  onChange={handleInputChange}
                  className={`mt-2 h-[3rem] w-full rounded-md border p-2 tracking-wide ${
                    errors.address ? "border-red-500" : "border-blue-300"
                  }`}
                />
              </div>

              <div className="mt-3">
                <label
                  htmlFor="purchaserDetails.pin"
                  className={`font-semibold tracking-wide text-[black] ${
                    errors.pin ? "text-red-500" : ""
                  }`}
                >
                  Pin Code:
                </label>
                <br />
                <input
                  type="text"
                  name="purchaserDetails.pin"
                  value={formdata.purchaserDetails.pin}
                  onChange={handleInputChange}
                  className={`mt-2 h-[3rem] w-full rounded-md border p-2 tracking-wide ${
                    errors.pin ? "border-red-500" : "border-blue-300"
                  }`}
                />
              </div>
              <div className="my-4 mb-1">
                <h1 className="  font-semibold tracking-wide">
                  {" "}
                  Updating Bike Status To :
                  <span
                    // onClick={(e) => {
                    //   updateBikeStatus(e.target.value, currentBikeData._id);
                    // }}
                    // value="Unsold"
                    className={`mx-2 rounded-full bg-green-200 px-5 py-1 font-semibold text-green-900 hover:bg-green-300 
               
                `}
                  >
                    Sold
                  </span>
                </h1>

                <div className="mt-1 flex items-center gap-[0.2rem]">
                  {/* <input
                onClick={(e) => {
                  if (check == false) {
                    setCheck(true);
                  } else {
                    setCheck(false);
                  }
                }}
                type="checkbox"
                name="InShowroom"
                className="h-[15px] w-[15px] cursor-pointer"
                id=""
              /> */}

                  {/* <label
                htmlFor="InShowroom"
                className="font-semibold text-[black]"
              >
                {" "}
                InShowroom
              </label> */}
                </div>
              </div>

              <div className="flex justify-center">
                <button
                  type="submit"
                  className="mt-4 flex w-[30%] justify-center rounded-md bg-[#640708] py-2 text-white"
                >
                  {buttonLoader ? (
                    <div className="loaderButton"></div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default SoldBikeStatusModal;
