import React from "react";
import Dropdown from "./Dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowBarUp } from "react-icons/bs";
import navBike from "../../assets/img/layout/navbike3.png";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  // const [darkmode, setDarkmode] = React.useState(false);
  let navigate = useNavigate();
  const avatar =
    "https://cdn.pixabay.com/photo/2023/09/22/12/18/profile-8268938_1280.png";

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white px-2 mx-2 dark:bg-[#0b14374d]">
       <div className="ml-4 hidden xl:block">
          <img className="h-12 w-auto" src={navBike} alt="navBike" />
        </div>
      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-between xl:justify-end gap-2 bg-white px-2 py-2 dark:!bg-navy-800 dark:shadow-none md:w-full md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-8 w-8" />
        </span>

        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src="https://cdn.dribbble.com/users/1684249/screenshots/15431837/media/b8793da764afaf229379b316181bf8eb.gif"
              alt="avatar"
            />
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, There..
                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-4">
                <div
                  onClick={()=>navigate('/admin/user-profile')}
                  className="text-sm text-gray-800 tracking-wide text-center cursor-pointer dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </div>
                
                <button
                  onClick={() => {
                    localStorage.removeItem("ArchitaMotors-authToken");
                    localStorage.removeItem("Admin-Data");
                    navigate("/auth/sign-in");
                  }}
                  className="mt-3 text-sm font-medium text-red-500 transition duration-150 ease-out hover:text-red-500 hover:ease-in"
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
